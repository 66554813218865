import { AxiosResponse } from "axios";
import { QueryClient } from "react-query";
import AbstractApi from "./AbstractApi";
import { AIDesignAssetType } from "./AIDesignAssetsApi";

export interface AssetFolderType {
  id: number;
  name: string;
  teamId: number;
  createdat: string;
  updatedat: string;
}

export interface CreateAssetFolderPayload {
  name: string;
  teamId: number;
}

export interface AssetFolderPayload {
  id: number;
  name?: string;
  teamId: number;
}

export interface AddAssetFolderPayload {
  assetFolderId: number;
  teamId?: number;
  assetIds: number[];
}

export default class AssetFoldersApi extends AbstractApi {
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
  }

  async getAssetFolders(
    teamId: number
  ): Promise<AxiosResponse<AssetFolderType[]>> {
    return await this.client.get("/asset-folders/my", {
      params: {
        teamId,
      },
    });
  }

  async getAssetFolder(
    payload: AssetFolderPayload
  ): Promise<AxiosResponse<AssetFolderType[]>> {
    return await this.client.get(`/asset-folders/my/${payload.id}`, {
      params: {
        teamId: payload.teamId,
      },
    });
  }

  async createAssetFolder(
    payload: CreateAssetFolderPayload
  ): Promise<AssetFolderType> {
    return await this.client.post(`/asset-folders/my`, payload);
  }

  async deleteAssetFolder(
    payload: AssetFolderPayload
  ): Promise<AxiosResponse<AssetFolderType>> {
    return await this.client.delete(`/asset-folders/my/${payload.id}`, {
      params: {
        teamId: payload.teamId,
      },
    });
  }

  async editAssetFolder(payload: AssetFolderPayload): Promise<AssetFolderType> {
    return await this.client.patch(`/asset-folders/my/${payload.id}`, {
      name: payload.name,
      teamId: payload.teamId,
    });
  }

  async getFolderAssets(
    payload: AssetFolderPayload
  ): Promise<{ data: AIDesignAssetType[] }> {
    return await this.client.get(`/asset-folders/my/${payload.id}/assets`, {
      params: {
        teamId: payload.teamId,
      },
    });
  }

  async addFolderAsset(
    payload: AddAssetFolderPayload
  ): Promise<AssetFolderType> {
    return await this.client.post(
      `/asset-folders/my/${payload.assetFolderId}/add-assets`,
      {
        assetIds: payload.assetIds,
      }
    );
  }

  async removeFolderAsset(
    payload: AddAssetFolderPayload
  ): Promise<AssetFolderType> {
    return await this.client.post(
      `/asset-folders/my/${payload.assetFolderId}/remove-assets`,
      {
        assetIds: payload.assetIds,
      }
    );
  }
}
