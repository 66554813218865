export const openUrl = (
  url: string,
  target?: "_self" | "_blank" | "_parent" | "_top"
) => window.open(url, target || "_blank");

export const getImage = async (imageUrl: string) => {
  const response = await fetch("/api/image", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ imageUrl }),
  });
  const blob = await response.blob();

  const url = URL.createObjectURL(blob);
  const img = document.createElement("img");
  img.src = url;

  return img;
};

export const downloadImage = async (imageUrl: string) => {
  fetch("/api/image", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ imageUrl }),
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      const imageSplitArray = imageUrl.split("/");
      a.download = imageSplitArray[imageSplitArray.length - 1];
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getImageDimensions = (
  url: string
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = reject;
    img.src = url;
  });
};
