import React from "react";
import cn from "classnames";
import { theme } from "tailwind.config";
import CheckboxIcon from "public/icons/checkbox.svg";
import Icon from "./Icon";
import BigSpinner from "./BigSpinner";

export interface CheckBoxProps {
  className?: string;
  labelClassName?: string;
  containerClassName?: string;
  checkboxClassName?: string;
  label?: string | React.ReactNode;
  icon?: React.ReactElement;
  errorMessage?: string | false;
  loading?: boolean;
  width?: number;
  height?: number;
  errorClassName?: string;
}

export type CheckBoxPropsType = CheckBoxProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;

const CheckBox = ({
  className,
  labelClassName,
  containerClassName = "",
  checkboxClassName = "",
  label,
  errorMessage,
  checked,
  color,
  icon: IconComponent,
  loading,
  width = 20,
  height = 20,
  onClick,
  errorClassName,
  ...rest
}: CheckBoxPropsType) => {
  return (
    <div
      className={cn("flex flex-row justify-start relative", containerClassName)}
    >
      <label
        className={cn(
          "text-gray-900 flex items-center cursor-pointer group gap-2",
          rest.disabled && "cursor-auto text-gray-400",
          labelClassName
        )}
      >
        <input type="checkbox" checked={checked} {...rest} />

        {loading ? (
          <BigSpinner className={cn("w-5 h-5", checkboxClassName)} />
        ) : (
          <div
            className={cn(
              !rest.disabled && "hover:scale-110 hover:shadow",
              checkboxClassName
            )}
            aria-hidden="true"
            onClick={onClick}
          >
            {checked ? (
              <Icon
                component={CheckboxIcon}
                className={cn(!rest.disabled && "group-hover:fill-gray-950")}
                width={width}
                height={height}
                viewBox="0 0 20 20"
                fill={rest.disabled ? theme.extend.colors.gray[200] : color}
              />
            ) : (
              <div
                className={cn(
                  "group-hover:border-gray-950 rounded-sm border border-gray-600",
                  rest.disabled && "border-gray-400 group-hover:border-gray-400"
                )}
                style={{
                  width,
                  height,
                }}
              />
            )}
          </div>
        )}

        <div className="flex">
          {IconComponent ? (
            <div className="flex items-center justify-center mr-2">
              {IconComponent}
            </div>
          ) : null}
          <span
            className={cn(
              "group-hover:text-gray-950",
              rest.disabled && "group-hover:text-gray-400"
            )}
          >
            {label}
          </span>
        </div>
      </label>

      {errorMessage && (
        <div className={cn("error-100 absolute bottom-0", errorClassName)}>
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default CheckBox;
