export const useLocalStorage = () => {
  const setItemToLocalStorage = (name: string, value: any) => {
    if (typeof window === "undefined" || !name) return;
    localStorage.setItem(name, JSON.stringify(value));
  };

  const getItemFromLocalStorage = (name: string, initial?: any) => {
    if (typeof window === "undefined" || !name) return;
    const item = localStorage.getItem(name);
    if (item) {
      return JSON.parse(item);
    }

    return initial || null;
  };

  const removeItemFromLocalStorage = (name: string) => {
    if (typeof window === "undefined" || !name) return;
    localStorage.removeItem(name);
  };

  return {
    setItemToLocalStorage,
    getItemFromLocalStorage,
    removeItemFromLocalStorage,
  };
};
