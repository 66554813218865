export const compareString = (a: string, b: string) => {
  if (a > b) {
    return 1;
  } else if (a < b) {
    return -1;
  } else {
    return 0;
  }
};

export const compareDeepStrings = (str1?: string, str2?: string): boolean => {
  if (str1 && str2 && str2 !== "") {
    const words1 = str1.toLowerCase().split(/[^\w']+|_/);
    const words2 = str2.toLowerCase().split(/[^\w']+|_/);

    for (const word1 of words1) {
      for (const word2 of words2) {
        if (word1 === word2) {
          return true; // Shared word found
        }
      }
    }
    return false; // Same string
  }
  return true;
};

export const isLetter = (char: string) => {
  return /^[a-zA-Z]$/.test(char);
};
