import { useCallback, useEffect, useMemo, useState } from "react";
import cn from "classnames";
import useTranslation from "next-translate/useTranslation";
// components
import Icon from "components/atoms/Icon";
import ColorTile from "components/atoms/ColorTile";
import CustomButton from "components/atoms/CustomButton";
import DropdownText from "components/atoms/Benchmark/DropdownText";
import TrendSearchBar from "components/atoms/ExploreTrends/TrendSearchBar";
import DropdownGroupList from "components/atoms/ExploreTrends/DropdownGroupList";
import CategoryFilterButton from "components/atoms/ExploreTrends/CategoryFilterButton";
// context
import { useTeam } from "contexts/TeamContext";
// types
import { SelectOption } from "types";
// utils
import { isDev } from "utils/checkEnv";
import { compareString } from "utils/compareString";
import { beautifyStyleName } from "utils/normalizeString";
// icons
import FilterIcon from "public/icons/filter_16.svg";
import GlobalFlagIcon from "public/icons/global_flag.svg";
import ChevronLeft from "public/icons/chevron-left_16.svg";
import ArrowDownIcon from "public/icons/chevron_down_16.svg";
import ChevronRight from "public/icons/chevron-right_16.svg";
import AlphaSortAscIcon from "public/icons/alpha-asc-sort_16.svg";
import AlphaSortDescIcon from "public/icons/alpha-desc-sort_16.svg";
// constants
import {
  Category,
  Colors,
  ExploreTrendsGender,
  ExploreTrendsSortByOptions,
  FILTER_OPTIONS,
  TrendTypes,
  styleEnumiration,
} from "utils/constants";
// lodush
import { capitalize, debounce, isEmpty, pickBy, uniq } from "lodash";

export const GenderOptions = Object.entries(ExploreTrendsGender).map(
  ([key, value]: string[]) => ({
    name: value,
    value: key,
  })
);

export const SeasonOptions = [
  { name: "Select season", value: "N/A" },
  ...TrendTypes.map((item) => ({
    name: item.name,
    value: item.value,
  })),
];

export const SortByOptions = [
  {
    name: "Growth: high to low",
    value: ExploreTrendsSortByOptions.GHL,
  },
  {
    name: "Growth: low to high",
    value: ExploreTrendsSortByOptions.GLH,
  },
  {
    name: "Decline: high to low",
    value: ExploreTrendsSortByOptions.DHL,
  },
  {
    name: "Decline: low to high",
    value: ExploreTrendsSortByOptions.DLH,
  },
];

export const RegionOptions = [
  {
    name: "Global",
    icon: (
      <Icon
        component={GlobalFlagIcon}
        viewBox="0 0 21 14"
        className="w-[21px] h-[14px]"
      />
    ),
    value: "global",
  },
  {
    name: "United States",
    icon: (
      <img
        src={`https://flagsapi.com/US/shiny/64.png`}
        className="h-4 object-contain"
      />
    ),
    value: "us",
  },
  // {
  //   name: "Korea",
  //   icon: (
  //     <img
  //       src={`https://flagsapi.com/KR/shiny/64.png`}
  //       width={21}
  //       height={14}
  //       className="rounded-sm"
  //     />
  //   ),
  //   value: "KOREAN",
  // },
];

const categoryOptions: { [key: string]: string[] } = {
  FSS: [
    "bottoms",
    "coats_jackets",
    "denim",
    "dresses  ",
    "nightwear",
    "tops",
    "underwear",
  ],
  FFW: [
    "bottoms",
    "coats_jackets",
    "denim",
    "dresses  ",
    "knitwear",
    "skirts",
    "sweatshirts_hoodies",
    "tops",
  ],
  MSS: [
    "activewear",
    "bottoms",
    "coats_jackets",
    "sweatshirts_hoodies",
    "swimwear",
    "tops",
  ],
  MFW: [
    "bottoms",
    "coats_jackets",
    "denim",
    "knitwear",
    "sweatshirts_hoodies",
    "underwear",
  ],
  Mseasonless: [
    "activewear",
    "bottoms",
    "coats_jackets",
    "denim",
    "dresses  ",
    "jumpsuits",
    "knitwear",
    "nightwear",
    "outdoor",
    "sets",
    "shirts",
    "skirts",
    "sweatshirts_hoodies",
    "swimwear",
    "tops",
    "underwear",
  ],
  Fseasonless: [
    "activewear",
    "bottoms",
    "coats_jackets",
    "denim",
    "dresses  ",
    "jumpsuits",
    "knitwear",
    "nightwear",
    "outdoor",
    "sets",
    "shirts",
    "skirts",
    "sweatshirts_hoodies",
    "swimwear",
    "tops",
    "underwear",
  ],
};

export const ReachOptions = [
  {
    name: "Major",
    value: "major",
  },
  {
    name: "Medium",
    value: "medium",
  },
  {
    name: "Minor",
    value: "minor",
  },
  // {
  //   name: "Zero",
  //   value: "zero",
  // },
];

export const TrendOptions = [
  {
    name: "Style",
    value: "style",
  },
  {
    name: "Sub category",
    value: "subcat",
  },
];

type ExploreTrendFiltertype = {
  gender?: SelectOption;
  season?: SelectOption;
  category?: SelectOption;
  region?: SelectOption;
  style?: SelectOption;
  color?: SelectOption;
  reach?: SelectOption[];
  trend?: SelectOption;
};

interface ExploreTrendsFiltersProps {
  className?: string;
  activeFilterGroup?: string;
  setActiveFilerGroup?: Function;
  sortBy?: SelectOption;
  setSortBy?: Function;
  date?: SelectOption;
  setDate?: Function;
  gender?: SelectOption;
  setGender?: Function;
  season?: SelectOption;
  setSeason?: Function;
  category?: SelectOption;
  setCategory?: Function;
  region?: SelectOption;
  setRegoin?: Function;
  style?: SelectOption;
  setStyle?: Function;
  searchKeyword?: string;
  setSearchkeyword?: Function;
  color?: SelectOption;
  setColor?: Function;
  material?: SelectOption;
  setMaterial?: Function;
  setSearchData?: Function;
  brand?: string;
  setBrand?: Function;
  reach?: SelectOption[];
  setReach?: Function;
  trend?: SelectOption;
  params?: any;
  setFilterKeyword?: Function;
}

export default function ExploreTrendsFilters({
  className,
  sortBy,
  setSortBy,
  date,
  setDate,
  activeFilterGroup,
  setActiveFilerGroup,
  category,
  setCategory,
  gender,
  setGender,
  season,
  setSeason,
  region,
  setRegoin,
  style,
  setStyle,
  setSearchkeyword,
  color,
  setColor,
  material,
  setMaterial,
  setSearchData,
  brand,
  setBrand,
  reach,
  setReach,
  trend,
  params,
  setFilterKeyword,
}: ExploreTrendsFiltersProps) {
  const { t } = useTranslation("common");
  const { pushWithState } = useTeam();
  const [isDisplayFilter, setIsDisplayFilter] = useState<boolean>(true);
  const [filterOption, setFilterOption] = useState<SelectOption>();
  const [keyword, setKeyword] = useState<string>("");
  const [ctrIndex, setCtrIndex] = useState<number>(0);
  const [filters, setFilters] = useState<ExploreTrendFiltertype | null>(null);
  const [isDiaplayNavBtn, setIsDisplayNavBtn] = useState<boolean>(true);

  useEffect(() => {
    const init = debounce(() => {
      setFilters({
        gender: gender,
        season: season,
        category: category,
        region: region,
        style: style,
        color: color,
        reach: reach,
        trend: trend,
      });
    }, 300);

    init();

    return () => {
      init.cancel();
    };
  }, [gender, season, category, region, style, color, reach, trend]);

  useEffect(() => {
    const handleUpdateKeyword = debounce((value) => {
      setSearchkeyword && setSearchkeyword(value);
      if (value === "" && setFilterKeyword) {
        setFilterKeyword("");
      }
    }, 500);

    handleUpdateKeyword(keyword);

    return () => {
      handleUpdateKeyword.cancel();
    };
  }, [keyword]);

  useEffect(() => {
    setKeyword(brand || "");
  }, [brand]);

  useEffect(() => {
    if (window) {
      const target = document.getElementById(`filter-button-${ctrIndex}`);
      target?.scrollIntoView({ behavior: "smooth" });
    }
    return;
  }, [ctrIndex]);

  useEffect(() => {
    switch (activeFilterGroup) {
      case FILTER_OPTIONS.Category:
        setFilterOption(category);
        break;
      case FILTER_OPTIONS.Material:
        setFilterOption(material);
        break;
      case FILTER_OPTIONS.Color:
        setFilterOption(color);
        break;
      default:
        break;
    }
  }, [activeFilterGroup, category, color, material]);

  const checkFilterScroll = () => {
    if (window) {
      const targetWrapper = document.getElementById(`filter-button-scrollbar`);
      const targetBody = document.getElementById(
        "filter-button-scrollbar-body"
      );
      if (targetWrapper?.clientWidth && targetBody?.clientWidth) {
        setIsDisplayNavBtn(
          targetWrapper?.clientWidth < targetBody?.clientWidth
        );
      }
    }
  };

  useEffect(() => {
    checkFilterScroll();

    if (window) {
      window.addEventListener("resize", checkFilterScroll);
    }

    return () => {
      window.removeEventListener("resize", checkFilterScroll);
    };
  }, []);

  const styleOptions = useMemo(() => {
    const entriesData = Object.entries(styleEnumiration);
    return uniq(
      entriesData
        .reduce(
          (prev: any, currentValue: any) => [...prev, currentValue[0]],
          []
        )
        .sort((a: any, b: any) => compareString(a, b))
    ).map((item: any) => ({
      name: capitalize(beautifyStyleName(item)),
      value: item,
      source: "style",
    }));
  }, [t]);

  const filtersOptions = useMemo(() => {
    if (activeFilterGroup === FILTER_OPTIONS.Category) {
      return (
        (brand
          ? Category
          : categoryOptions[`${gender?.value}${season?.value}`] || Category
        )?.map((item: string) => ({
          name: capitalize(t(item)),
          value: item,
          source: "category",
        })) || []
      );
    } else if (activeFilterGroup === FILTER_OPTIONS.Color) {
      return Object.keys(Colors).map((item) => ({
        name: capitalize(t(item)),
        value: item,
        source: "category",
      }));
    } else if (activeFilterGroup === FILTER_OPTIONS.Material) {
      return [];
    }
    return [];
  }, [activeFilterGroup, gender?.value, season?.value, brand]);

  const renderButtonComponent = useCallback(
    (value) => {
      if (
        activeFilterGroup === FILTER_OPTIONS.Category ||
        activeFilterGroup === FILTER_OPTIONS.Material
      ) {
        return capitalize(t(value));
      } else if (activeFilterGroup === FILTER_OPTIONS.Color) {
        return (
          <ColorTile
            color={value}
            className="!w-3 !h-3 !rounded-sm border-none"
          />
        );
      }
    },
    [activeFilterGroup, capitalize, t]
  );

  const handleSelectOption = (option: SelectOption) => {
    switch (activeFilterGroup) {
      case FILTER_OPTIONS.Category:
        const pathParams = {
          basecat: option.value,
          style: params.style,
          gender: params.gender,
          season: params.season,
          region: params.region,
          brand: params.brand,
          sortby: params.sortby,
        };
        pushWithState(
          "/forecast",
          pickBy(pathParams, (value) => !isEmpty(value))
        );
        break;
      case FILTER_OPTIONS.Material:
        setMaterial && setMaterial(option);
        break;
      case FILTER_OPTIONS.Color:
        setColor && setColor(option);
        break;
      default:
        break;
    }
  };

  const handleOnSelectKeyword = useCallback(
    (name: string, value: string, source: string) => {
      let pathParams = {
        basecat: params.basecat,
        style: params.style,
        gender: params.gender,
        season: params.season,
        region: params.region,
        brand: params.brand,
        sortby: params.sortby,
        reach: params.reach,
        trend: params.trend,
      };

      if (source === "category") {
        pathParams = {
          ...pathParams,
          basecat: value,
        };
      } else if (source === "style") {
        pathParams = {
          ...pathParams,
          style: value,
        };
      } else if (source === "brand") {
        pathParams = {
          ...pathParams,
          brand: value,
          ...(!!value ? { sortby: SortByOptions[0].value } : {}),
        };
        setFilterKeyword && setFilterKeyword("");
      } else if (source === "region") {
        pathParams = {
          ...pathParams,
          region: value,
        };
      } else if (source === "gender") {
        pathParams = {
          ...pathParams,
          gender: value,
        };
      } else if (source === "sortby") {
        pathParams = {
          ...pathParams,
          sortby: value,
        };
      } else if (source === "season") {
        pathParams = {
          ...pathParams,
          season: value,
        };
      } else if (source === "reach") {
        pathParams = {
          ...pathParams,
          reach: value,
        };
      } else if (source === "trend") {
        pathParams = {
          ...pathParams,
          trend: value,
        };
      } else if (source === "clear_search") {
        setKeyword("");
      } else if (source === "Search for trend's name") {
        pathParams = {
          ...pathParams,
          brand: "",
        };
        setKeyword(value);
        setFilterKeyword && setFilterKeyword(value);
      }

      if (["brand", "style", "category"].includes(source)) {
        setKeyword(name);
      }

      pushWithState(
        "/forecast",
        pickBy(pathParams, (value) => !isEmpty(value))
      );
    },
    [params]
  );

  const handleOnSelectMobileFilter = useCallback(
    (name: string, value: string, source: string) => {
      const data = {
        name,
        value,
      };

      let pathParams = {
        basecat: params.basecat,
        style: params.style,
        gender: params.gender,
        season: params.season,
        region: params.region,
        brand: params.brand,
        sortby: params.sortby,
        reach: params.reach,
        trend: params.trend,
      };

      if (source === "category") {
        pathParams = {
          ...pathParams,
          basecat: data.value,
        };
      } else if (source === "style") {
        pathParams = {
          ...pathParams,
          style: data.value,
        };
      } else if (source === "gender") {
        pathParams = {
          ...pathParams,
          gender: data.value,
        };
      } else if (source === "season") {
        pathParams = {
          ...pathParams,
          season: data.value,
        };
      } else if (source === "region") {
        pathParams = {
          ...pathParams,
          region: data.value,
        };
      } else if (source === "trend") {
        pathParams = {
          ...pathParams,
          trend: data.value,
        };
      } else if (source === "reach") {
        const reachValues = params?.reach?.split("/") || [];
        const filteredValue = reachValues.filter(
          (item: string) => item !== value
        );

        if (filteredValue.length === reachValues.length) {
          pathParams = {
            ...pathParams,
            reach: [...filteredValue, value].join("/"),
          };
        } else if (filteredValue.length > 0) {
          pathParams = {
            ...pathParams,
            reach: filteredValue.join("/"),
          };
        }
      }
      pushWithState(
        "/forecast",
        pickBy(pathParams, (value) => !isEmpty(value))
      );
    },
    [params]
  );

  const onSelectFilters = useCallback(
    (name: string, value: string, source: string) => {
      const data = {
        name,
        value,
      };

      if (source === "reach") {
        const reachValues = filters?.reach || [];
        const filteredValue = reachValues.filter(
          (item) => item.value !== value
        );

        if (filteredValue.length === reachValues.length) {
          setFilters({
            ...filters,
            reach: [...filteredValue, data],
          });
        } else if (filteredValue.length > 0) {
          setFilters({
            ...filters,
            reach: filteredValue,
          });
        }
      } else if (
        (filters?.[source as keyof ExploreTrendFiltertype] as SelectOption)
          ?.value === value
      ) {
        setFilters({
          ...filters,
          [source as keyof ExploreTrendFiltertype]: null,
        });
      } else {
        setFilters({
          ...filters,
          [source as keyof ExploreTrendFiltertype]: data,
        });
      }
    },
    [setFilters, filters]
  );

  const handleFilterDone = useCallback(() => {
    let pathParams = {
      brand: params.brand,
      sortby: params.sortby,
      basecat: filters?.category?.value,
      gender: filters?.gender?.value,
      season: filters?.season?.value,
      region: filters?.region?.value,
      reach: filters?.reach?.map((item) => item.value).join("/"),
      trend: filters?.trend?.value,
      style: filters?.style?.value,
    };

    pushWithState(
      "/forecast",
      pickBy(pathParams, (value) => !isEmpty(value))
    );
  }, [params, filters, pushWithState]);

  const handleFilterClear = useCallback(() => setFilters(null), []);

  const handleCancelFilter = useCallback(
    (source) => {
      let pathParams = {
        brand: params.brand,
        sortby: params.sortby,
        basecat: filters?.category?.value,
        gender: filters?.gender?.value,
        season: filters?.season?.value,
        region: filters?.region?.value,
        reach: filters?.reach?.map((item) => item.value).join("/"),
        trend: filters?.trend?.value,
        style: filters?.style?.value,
        [source]: null,
      };

      pushWithState(
        "/forecast",
        pickBy(pathParams, (value) => !isEmpty(value))
      );
    },
    [params, filters]
  );

  const filterDropdownOption = [
    {
      id: "gender",
      title: t("simple_gender"),
      values: filters?.gender ? filters?.gender.value : "",
      isCheckBox: true,
      isSame: true,
      options: GenderOptions,
    },

    {
      id: "season",
      title: t("season"),
      values: filters?.season ? filters?.season.value : "",
      isCheckBox: true,
      isSame: true,
      options: SeasonOptions,
    },
    ...(isDev()
      ? [
          {
            id: "trend",
            title: capitalize(t("trend")),
            values: filters?.trend ? filters?.trend.value : "",
            isCheckBox: true,
            isSame: true,
            options: TrendOptions,
          },
        ]
      : []),
    {
      id: "reach",
      title: t("reach"),
      values: filters?.reach
        ? filters?.reach?.map((item) => item.value)
        : ["all"],
      isCheckBox: true,
      isMultiSelect: true,
      options: [...ReachOptions],
    },
    {
      id: "style",
      title: t("style"),
      values: filters?.style ? filters?.style.value : "",
      isCheckBox: true,
      isSame: true,
      options: styleOptions,
    },
  ];

  const filterCount = useMemo(() => {
    let count = 0;
    if (!!gender?.value) count++;
    if (!!category?.value) count++;
    if (season?.value && season?.value !== "N/A") count++;
    if (trend?.value) count++;
    if (reach && reach.length > 0) count++;
    if (region?.value) count++;
    if (style?.value) count++;

    return count;
  }, [gender, category, season, trend, reach, region, style]);

  return (
    <div className={cn("flex flex-col justify-start gap-3 w-full", className)}>
      <div className="flex items-center sm:justify-between gap-4 flex-wrap justify-start w-full">
        <div className="flex items-center justify-start gap-2 flex-wrap sm:flex-none sm:w-auto w-full">
          <div className="flex items-center justify-between w-full order-2 sm:order-1 sm:w-auto">
            <DropdownGroupList
              trigger={
                <CustomButton
                  icon={FilterIcon}
                  iconClassName="w-4 h-4"
                  endIcon={
                    <Icon
                      component={ArrowDownIcon}
                      viewBox="0 0 16 16"
                      width={16}
                      height={16}
                      className={cn(
                        "fill-gray-950",
                        !isDisplayFilter && "rotate-180"
                      )}
                    />
                  }
                  variant="secondary"
                  label={`${t("filters")}${
                    filterCount > 0 ? ` | ${filterCount}` : ""
                  }`}
                  buttonTextClassName="!font-normal !text-xs"
                />
              }
              isTooltip
              onSelect={onSelectFilters}
              onDone={handleFilterDone}
              onClear={handleFilterClear}
              options={filterDropdownOption}
            />

            <DropdownText
              displayValue={
                sortBy?.value === ""
                  ? "Sort by"
                  : sortBy?.value === ExploreTrendsSortByOptions.GHL ||
                    sortBy?.value === ExploreTrendsSortByOptions.GLH
                  ? t("Growth")
                  : t("decline")
              }
              labelIcon={
                <Icon
                  component={
                    sortBy?.value === ExploreTrendsSortByOptions.GLH ||
                    sortBy?.value === ExploreTrendsSortByOptions.DLH
                      ? AlphaSortAscIcon
                      : AlphaSortDescIcon
                  }
                  viewBox="0 0 16 16"
                  width={16}
                  height={16}
                />
              }
              items={!!brand ? SortByOptions.slice(0, 2) : SortByOptions}
              selectedItems={sortBy}
              onChange={(selectedOption: any) =>
                handleOnSelectKeyword(
                  selectedOption.name,
                  selectedOption.value,
                  "sortby"
                )
              }
              className="!block sm:!hidden"
              menuSubTitle="SORT BY"
              checkIcon={true}
              labelClassName="!text-xs"
            />
          </div>
          <div className="lg:!w-[400px] sm:!w-[220px] w-full order-1 sm:order-2">
            <TrendSearchBar
              value={keyword}
              onChange={setKeyword}
              onSelectKeyword={handleOnSelectKeyword}
              placeholder="Enter a keyword to see the relevant trends"
              filterSource={[
                {
                  name: keyword,
                  value: keyword,
                  source: "Search for trend's name",
                },
                ...styleOptions,
                ...filtersOptions,
              ]}
            />
          </div>
        </div>
        <DropdownText
          displayValue={
            sortBy?.value === ""
              ? "Sort by"
              : sortBy?.value === ExploreTrendsSortByOptions.GHL ||
                sortBy?.value === ExploreTrendsSortByOptions.GLH
              ? t("Growth")
              : t("decline")
          }
          labelIcon={
            <Icon
              component={
                sortBy?.value === ExploreTrendsSortByOptions.GLH ||
                sortBy?.value === ExploreTrendsSortByOptions.DLH
                  ? AlphaSortAscIcon
                  : AlphaSortDescIcon
              }
              viewBox="0 0 16 16"
              width={16}
              height={16}
            />
          }
          items={!!brand ? SortByOptions.slice(0, 2) : SortByOptions}
          selectedItems={sortBy}
          onChange={(selectedOption: any) =>
            handleOnSelectKeyword(
              selectedOption.name,
              selectedOption.value,
              "sortby"
            )
          }
          className="sm:block hidden"
          menuSubTitle="SORT BY"
          checkIcon={true}
          labelClassName="!text-xs"
        />
      </div>
      <div className="relative md:flex items-start justify-between hidden">
        {isDiaplayNavBtn && ctrIndex === filtersOptions.length && (
          <div
            className="absolute left-0 z-[9] w-16 rounded-r-[180px] flex items-center justify-start"
            style={{
              background:
                "linear-gradient(90deg, white 47.66%, rgba(255, 255, 255, 0.10) 135.76%)",
            }}
          >
            <button
              className={cn(
                "px-2 py-1 text-center border-[0.5px] border-gray-40 bg-white rounded-md text-xs first-letter:uppercase whitespace-nowrap",
                "hover:bg-gray-30 hover:text-typography-title active:text-typography-title active:bg-gray-10 rounded-[100px] h-[30px]",
                "flex items-center justify-center"
              )}
              onClick={() => setCtrIndex(0)}
            >
              <Icon
                component={ChevronLeft}
                viewBox="0 0 16 16"
                width={12}
                height={12}
                className="fill-gray-950"
              />
            </button>
          </div>
        )}
        <div
          id="filter-button-scrollbar"
          className={cn(
            "relative w-full md:flex hidden overflow-x-scroll hidden-scrollbar pb-0.5 justify-between items-ceter gap-2"
          )}
        >
          <div
            id="filter-button-scrollbar-body"
            className="relative flex items-end justify-start flex-nowrap gap-2"
          >
            <CategoryFilterButton
              id="filter-button-0"
              label={t("All")}
              onClick={() => handleSelectOption({ name: t("All"), value: "" })}
              active={!filterOption || filterOption.value === ""}
            />
            {[...filtersOptions].map((item: SelectOption, idx: any) => (
              <CategoryFilterButton
                key={`filter-button-${idx + 1}`}
                id={`filter-button-${idx + 1}`}
                label={renderButtonComponent(item.value)}
                onClick={() => handleSelectOption(item)}
                active={filterOption?.value === item.value}
              />
            ))}
          </div>
          {isDiaplayNavBtn && ctrIndex === 0 && (
            <div
              className="absolute right-0 z-[9] w-16 rounded-l-[180px] flex items-center justify-end"
              style={{
                background:
                  " linear-gradient(270deg, white 19.95%, rgba(255, 255, 255, 0.10) 134.24%)",
              }}
            >
              <button
                className={cn(
                  "px-2 py-1 text-center border-[0.5px] border-gray-40 bg-white rounded-md text-xs first-letter:uppercase whitespace-nowrap",
                  "hover:bg-gray-30 hover:text-typography-title active:text-typography-title active:bg-gray-10 rounded-[100px] h-[30px] w-[30px]",
                  "flex items-center justify-center"
                )}
                onClick={() => setCtrIndex(filtersOptions.length)}
              >
                <Icon
                  component={ChevronRight}
                  viewBox="0 0 16 16"
                  width={12}
                  height={12}
                  className="fill-gray-950"
                />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
