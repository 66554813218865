import { IIpLocation } from "types";
import { CHINA } from "./constants";

export const getIsChinaLocation = (location?: IIpLocation) => {
  if (!location) {
    return false;
  }

  const { bigDataCloudCountryISO, geoapifyCountryISO, ipStackCountryISO } =
    location;

  const countryCodes = [
    bigDataCloudCountryISO,
    geoapifyCountryISO,
    ipStackCountryISO,
  ];

  return countryCodes.some((el) => el === CHINA);
};
