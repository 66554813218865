import { QueryClient } from "react-query";
import AbstractApi from "./AbstractApi";

export type BenchmarkProjectType = {
  createdat?: string | number | Date;
  id?: number;
  name?: string;
  mainBrand?: string;
  region?: string;
  market?: string;
  retailer?: string;
  category?: string;
  gender?: string;
  color?: string;
  material?: string;
  brandCompetitors?: [string];
  updatedat?: string;
};
export default class NotificationsApi extends AbstractApi {
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
  }

  getAllNotifications = async (): Promise<BenchmarkProjectType> =>
    this.client.get(`/notifications/my`);

  removeNotification = async ({ notificationId }: { notificationId: number }) =>
    await this.client.delete(`/notifications/my/${notificationId}`);

  readAllNotifications = async () =>
    await this.client.post(`/notifications/my/read-all`);

  readNotification = async ({ notificationId }: { notificationId: number }) =>
    await this.client.post(`/notifications/my/${notificationId}/read`);

  getUnreadNotificationCount = async (): Promise<BenchmarkProjectType> =>
    this.client.get(`/notifications/my/unread-count`);
}
