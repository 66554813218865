import { isLetter } from "./compareString";

export const normalizeThemeName = (name?: string) => {
  if (name) {
    const pattern = /(.+) with (.+) theme/;
    const matches = name.match(pattern);
    if (matches && matches.length === 3) {
      const category = matches[1].trim();
      const themeName = matches[2].trim();
      return beautifyStyleName(`${themeName} ${category}`);
    } else {
      // If the name doesn't match the expected pattern, return it as is
      return beautifyStyleName(normalizeProductType(name));
    }
  } else {
    return "";
  }
};

export const normalizeProductType = (pattern?: string): string => {
  if (!pattern) return "";

  const regex = /\bwith\s+(.+?)\s+specific\s+product\s+type\b/i;
  const match = pattern.match(regex);
  return match ? match[1] : pattern;
};

export const beautifyStyleName = (name: string) => {
  return name
    .replace(";", " ")
    .split(/[_-]/)
    .join(" ")
    .replace("front front", "front")
    .replace("back back", "back")
    .replace("merino wool merino wool", "merino wool")
    .replace("supra styles", "")
    .replace(" hem", "");
};

export const groupOptionsByFirstLetter = (
  values: { name: string; value: string }[]
) => {
  const groups: { [key: string]: any[] } = {};

  values.forEach((item) => {
    let firstLetter = item.value.charAt(0).toUpperCase(); // Ensure consistent case
    if (!isLetter(firstLetter)) {
      firstLetter = "-";
    }
    if (!groups[firstLetter]) {
      groups[firstLetter] = [];
    }
    groups[firstLetter].push(item);
  });

  return groups;
};
