import Stripe from "stripe";

export const isPlan = (product: Stripe.Product) => {
  if (product) {
    const topLevelMetadataIsPlan = product.metadata.isPlan === "true";

    if (!topLevelMetadataIsPlan) {
      return (product as any)?.price?.product?.metadata?.isPlan === "true";
    }

    return topLevelMetadataIsPlan;
  }

  return false;
};
