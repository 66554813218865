import { useArticles } from "contexts/ArticlesContext";
import React, { useState } from "react";
import { useEffect } from "react";
import Drawer from "react-modern-drawer";
import CloseButton from "public/icons/close_16.svg";
import CustomButton from "components/atoms/CustomButton";
import useTranslation from "next-translate/useTranslation";
import CaretLeftIcon from "public/icons/caret-left_16.svg";
import useTawk from "hooks/useTawk";
import TutorialGrid from "./TutorialGird";
import TutorialView from "./TutorialView";

interface TutorialDrawerProps {
  open: boolean;
  onClose: () => void;
}

const TutorialDrawer: React.FC<TutorialDrawerProps> = ({ open, onClose }) => {
  const { t } = useTranslation("common");
  const [articleId, setArticleId] = useState<number | null>(null);
  const { articlePageAnchor, resetArticlePageAnchor } = useArticles();
  const { showLiveSupport, shutdownLiveSupport } = useTawk();

  useEffect(() => {
    if (open) {
      shutdownLiveSupport();
    }
  }, [open]);

  const handleClose = () => {
    if (articlePageAnchor) {
      resetArticlePageAnchor();
    } else {
      onClose();
      showLiveSupport();
    }
  };

  const handleBack = () => {
    resetArticlePageAnchor();
    setArticleId(null);
  };

  return open ? (
    <Drawer
      style={{
        backgroundColor: "white",
        overflow: "hidden",
      }}
      className="xl:!w-[50vw] md:!w-[45vw] !w-[70vw] !h-[100dvh] max-w-[430px] !bg-transparent !shadow-none"
      overlayOpacity={0}
      direction="right"
      open={open}
      onClose={handleClose}
      zIndex={49}
    >
      <div className="w-full h-full p-6 pt-16" onClick={handleClose}>
        <div
          className="relative bg-white rounded-2xl h-full overflow-hidden"
          style={{
            boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.16)",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="w-full flex items-center justify-between px-4 py-3 h-12">
            <div className="w-[34px] h-[34px]">
              {(articlePageAnchor || articleId) && (
                <CustomButton
                  variant="tertiary"
                  icon={CaretLeftIcon}
                  viewBox="0 0 16 16"
                  className="!p-2"
                  onClick={handleBack}
                  size="small"
                />
              )}
            </div>
            <h4 className="text-typography-headline text-lg leading-tight font-bold">
              {t("tutorial_short")}
            </h4>
            <CustomButton
              variant="tertiary"
              icon={CloseButton}
              viewBox="0 0 16 16"
              className="!p-2"
              onClick={() => {
                onClose();
                showLiveSupport();
                resetArticlePageAnchor();
              }}
              size="small"
            />
          </div>
          {articlePageAnchor || articleId ? (
            <TutorialView articleId={articleId || 0} />
          ) : (
            <TutorialGrid onSelectTutorial={setArticleId} />
          )}
        </div>
      </div>
    </Drawer>
  ) : null;
};

export default TutorialDrawer;
