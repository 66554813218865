import React, { useContext, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { IBrowserFilters, ProductBrowserOpenFunction } from "types";

interface IBrowserProps {
  title?: string;
  filters?: IBrowserFilters;
  channel?: string;
}

interface IMultipleProductBrowserContext {
  isBrowserOpened: boolean;
  browserProps: IBrowserProps;
  hideBrowser: () => void;
  setBrowserProps: ProductBrowserOpenFunction;
}

const initialValues = {
  isBrowserOpened: false,
  browserProps: {},
  hideBrowser: () => {},
  setBrowserProps: () => {},
};

const MultipleProductBrowserContext =
  React.createContext<IMultipleProductBrowserContext>(initialValues);

export const useMultipleProductBrowser = () =>
  useContext(MultipleProductBrowserContext);

const MultipleProductBrowserProvider: React.FC = ({ children }) => {
  const [browserProps, setBrowserProps] = useState<IBrowserProps>({});

  const hideBrowser = () => {
    setBrowserProps({});
  };

  return (
    <MultipleProductBrowserContext.Provider
      value={{
        isBrowserOpened: !isEmpty(browserProps),
        hideBrowser,
        browserProps,
        setBrowserProps,
      }}
    >
      {children}
    </MultipleProductBrowserContext.Provider>
  );
};

export default MultipleProductBrowserProvider;
