import AbstractApi from "./AbstractApi";
import getRandomInt from "utils/getRandomInt";
import BusinessComponent from "../models/BusinessComponent";
import { QueryClient } from "react-query";

export default class BusinessComponentsApi extends AbstractApi {
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
  }

  async getBrandOverviewData(body: any) {
    await new Promise((ok) => setTimeout(ok, 1000)); // tmp delay for demo purposes

    return Promise.resolve({
      data: {
        highlightCards: [
          {
            title: "Top category",
            subtitle: "Shirts",
            percent: getRandomInt(0, 100),
          },
          {
            title: "Top subcategory",
            subtitle: "Shirt",
            percent: getRandomInt(0, 100),
          },
          {
            title: "Top theme",
            subtitle: "Casual",
            percent: getRandomInt(0, 100),
          },
          {
            title: "Top retailer",
            subtitle: "SR",
            percent: getRandomInt(0, 100),
          },
          {
            title: "Markdowns",
            subtitle: "515",
            percent: getRandomInt(0, 100),
          },
        ],
        texts: [
          `TED BAKER currently has <b>658</b> products tracked by the jellistudio
          platform, across <b>14</b> categories, from
          <b>2 different retailers</b>. TED BAKER has the most number of
          products in the category of 'shirts', where 'shirts' comprises
          roughly 22.2% of all TED BAKER products tracked by jellistudio.`,
          `TED BAKER has good product diversification given the number of product categories compared to other brands. Overall TED BAKER is in the top 4.2% of all brands ranked by number of items tracked by jellistudio.`,
          "Over the last week, jellistudio has tracked the removal of -515 products for TED BAKER, with no change to the number of product categories, and with no change to the number of tracked retailers. ",
          "TED BAKER is ranked #4126 out of 4269 brands by additional products added over the last week, or in the bottom 100.0% of all brands ranked by products added in the week.",
          "Looking at price, TED BAKER offers mostly affordable items compared to competitors products in the same category, where 100.0% of products from TED BAKER are priced at a discount below 10% of the average price per category, and no products are priced a premium above 10% of the average in the same category. ",
          "TED BAKER's highest priced item is 'Carve oversized leather biker jacket' (IMAGE) currently at $753.0 via SR. TED BAKER's lowest priced item is 'Lustia metallic-woven socks' (IMAGE) currently at $9.0 via SR.",
          "Concerning Social Media impact, TED BAKER has 232 Instagram followers, with an increase of 5 followers (+2.2%) over the last week. ",
          "This represents a change in ranking of -0.1%, as the fashion brand is now ranked in the bottom 86.09% of all Instagram fashion brands tracked by jellistudio.",
        ],
      },
    } as any);

    // return this.client.get("/todos/1");
  }

  async getBCData(): Promise<BusinessComponent[]> {
    return this.client.get("business-components");
  }
}
