import { PaginatedResponse } from "types";

export const getNextPageParamDefault = (lastPage: PaginatedResponse<any>) => {
  if (
    lastPage?.meta?.currentPage === lastPage?.meta?.totalPages ||
    lastPage?.meta?.currentPage > lastPage?.meta?.totalPages
  ) {
    return;
  }

  return lastPage?.meta?.currentPage + 1;
};
