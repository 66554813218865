import * as yup from "yup";

export const validationRegexps = {
  lowercaseRegexp: /(?=.*[a-z]).*/,
  uppercaseRegexp: /(?=.*[A-Z]).*/,
  numberRegexp: /(?=.*\d).*/,
  specialCharRegexp: /(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).*/,
};

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

yup.addMethod(yup.string, "password", function (errorMessage) {
  return this.test(`test-password`, errorMessage, function (value) {
    if (!value) {
      return true;
    }
    const lowercaseMatch = value.match(validationRegexps.lowercaseRegexp);
    const uppercaseMatch = value.match(validationRegexps.uppercaseRegexp);
    const specialCharMatch = value.match(validationRegexps.specialCharRegexp);
    const numberMatch = value.match(validationRegexps.numberRegexp);
    const length = value.length >= 8;
    // const { path, createError } = this;
    // return (
    //   getCardType(value).length > 0 ||
    //   createError({ path, message: errorMessage })
    // );
    return (
      lowercaseMatch &&
      uppercaseMatch &&
      specialCharMatch &&
      numberMatch &&
      length
    );
  });
});

yup.addMethod(yup.string, "multipleEmails", function (errorMessage) {
  return this.test("multipleEmails", errorMessage, function (value = "") {
    const { path, createError } = this;
    let result = true;

    const emailsList = value.replace(/\s/g, "").split(",");
    emailsList
      .filter((e) => e)
      .forEach((email) => {
        if (!emailRegex.test(String(email).toLowerCase())) {
          result = false;
        }
      });

    return result || createError({ path, message: errorMessage });
  });
});
