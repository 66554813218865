import React from "react";
import cn from "classnames";

const Spinner = ({ className = "" }: any) => {
  const commonClasses = cn(
    "animate-[bouncedelay_1.4s_ease-in-out_infinite] w-3 h-3 bg-white inline-block mr-1 rounded-full",
    className
  );

  return (
    <div className="text-center flex">
      <div className={cn(commonClasses)}></div>
      <div className={cn(commonClasses, `animation-delay-100`)}></div>
      <div className={cn(commonClasses, `animation-delay-200 mr-0`)}></div>
    </div>
  );
};

export default Spinner;
