import AbstractApi from "./AbstractApi";
import { Comment } from "models/Comment";
import { IPaginateParams, PaginatedResponse } from "types";
import { CommentPage, CommentSubject } from "utils/constants";
import { QueryClient } from "react-query";
export interface ICreateCommentPayload {
  text: string;
  pageName: CommentPage;
  resourcePath: string;
  tvGarmentId?: number;
  tvGarmentRevisionId?: number;
  tvChannelId?: number;
  tvProjectId?: number;
  resourceData: Record<string, any>;
  mentionedUserIds?: number[];
  repliedToId?: number;
  subject: CommentSubject;
}

export interface ICommentsViewStatus {
  unreadComments: number;
  unreadMentions: number;
}

export default class CommentsApi extends AbstractApi {
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
  }

  async getComments(
    params: IPaginateParams
  ): Promise<PaginatedResponse<Comment>> {
    return await this.client.get("/comments/my", { params });
  }

  async getCommentById(id: number): Promise<Comment> {
    return await this.client.get(`/comments/my/${id}`);
  }

  async createComment(payload: ICreateCommentPayload): Promise<Comment> {
    return await this.client.post("/comments/my", payload);
  }

  async editComment({
    id,
    payload,
  }: {
    id: number;
    payload: Partial<ICreateCommentPayload>;
  }): Promise<Comment> {
    return await this.client.patch(`/comments/my/${id}`, payload);
  }

  async deleteComment(id: number) {
    return await this.client.delete(`/comments/my/${id}`);
  }

  async getCommentsViewStatus(
    params: Record<string, any>
  ): Promise<ICommentsViewStatus> {
    return await this.client.get("/comments/my/view-status", { params });
  }

  async setCommentViewStatus(id: number) {
    return await this.client.post(`/comments/my/${id}/view`);
  }

  async setCommentStatus({ id, status }: { id: number; status: string }) {
    return await this.client.post(`/comments/my/${id}/set-status`, { status });
  }
}
