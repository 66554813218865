import Button from "components/atoms/Button";
import Team from "models/Team";
import useTranslation from "next-translate/useTranslation";
import React from "react";
import { useMemo } from "react";
import { TeamRoleEnum } from "types";
import Modal from "./Modal";

interface IForbiddenModalProps {
  team?: Team;
  error: string;
  onClose: () => void;
}

const ForbiddenModal: React.FC<IForbiddenModalProps> = ({
  team,
  error,
  onClose,
}) => {
  const { t } = useTranslation("common");

  const isMember = useMemo(() => {
    return team?.teamRole !== TeamRoleEnum.owner;
  }, [team]);

  return (
    <Modal isOpen={!!error && isMember}>
      <div className="flex flex-col items-center">
        <div className="mb-4 text-center">
          <p className="text-3xl">{t("limited_access")}</p>
          <p className="text-3xl font-bold">{team?.name}</p>
        </div>
        <p className="text-sm mb-4 text-center max-w-[273px]">{error}</p>
        <Button onClick={onClose} variant="secondary">
          {t("got_it")}
        </Button>
      </div>
    </Modal>
  );
};

export default ForbiddenModal;
