import React, { useCallback, useState } from "react";
import { Popover } from "@headlessui/react";
import cn from "classnames";
import useTranslation from "next-translate/useTranslation";
import CustomButton from "../CustomButton";
import { capitalize } from "lodash";
import DropdownList from "./DropdownList";

export interface IDropdownGroupListProps {
  trigger: any;
  dropdownClassname?: string;
  menuClassName?: string;
  className?: string;
  options?: any;
  onSelect?: Function;
  onClear?: Function;
  onDone?: Function;
  isTooltip?: boolean;
}

const DropdownGroupList = ({
  trigger,
  dropdownClassname,
  className,
  menuClassName,
  onSelect,
  options,
  onClear,
  onDone,
  isTooltip = false,
}: IDropdownGroupListProps) => {
  const { t } = useTranslation("common");
  const [openTab, setOpenTab] = useState<string | null>("region");

  const handleOpenOption = useCallback(
    (item: any) => {
      if (item?.id === openTab) setOpenTab(null);
      else setOpenTab(item.id || "");
    },
    [openTab, setOpenTab]
  );

  return (
    <div className={className}>
      <Popover
        as="div"
        className={cn("relative inline-block text-left", menuClassName)}
      >
        {({ close }) => (
          <>
            <Popover.Button
              onClick={(e: any) => e.stopPropagation()}
              className="inline-flex justify-center w-full text-sm font-medium rounded-xl bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            >
              {trigger}
            </Popover.Button>

            <Popover.Panel>
              <div
                className={cn(
                  "z-10 rounded-md overflow-hidden m-h-44 overflow-y-auto absolute top-11 bg-white divide-gray-100 shadow-lg ring-1 ring-gray-900 ring-opacity-5 focus:outline-none py-2 min-w-[294px]",
                  dropdownClassname || "left-0"
                )}
              >
                {isTooltip && (
                  <div className="w-full flex items-center justify-between h-10 px-2">
                    <div>
                      {onClear && (
                        <CustomButton
                          label={capitalize(t("clear"))}
                          variant="tertiary"
                          className="!p-2"
                          onClick={() => onClear()}
                        />
                      )}
                    </div>
                    {onDone && (
                      <CustomButton
                        label={t("done")}
                        className="!p-2"
                        onClick={() => {
                          onDone();
                          close();
                        }}
                      />
                    )}
                  </div>
                )}
                <div className="divide-y">
                  {options?.map((item: any) => (
                    <DropdownList
                      onSelectItem={onSelect}
                      open={item?.id === openTab}
                      key={`${item.id}-${item.title}`}
                      onOpen={() => handleOpenOption(item)}
                      {...item}
                    />
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>
    </div>
  );
};

export default DropdownGroupList;
