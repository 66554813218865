import { toast, ToastOptions } from "react-toastify";
import InfoIcon from "public/icons/info.svg";
import AlertTriangleIcon from "public/icons/alert-triangle.svg";
import cn from "classnames";

const Message = ({
  message,
  title,
  onClick,
  contentClassName,
}: {
  message: string;
  title?: string;
  onClick?: (val?: any) => void;
  contentClassName?: string;
}) => (
  <div onClick={onClick}>
    {!!title && <h4 className="headline-400 mb-1 w-full">{title}</h4>}
    <p
      className={cn(
        "w-full body-100 font-normal line-clamp-2",
        contentClassName
      )}
    >
      {message}
    </p>
  </div>
);

export const success = (
  content: {
    message: string;
    title?: string;
    onClick?: (val?: any) => void;
    contentClassName?: string;
  },
  opts: ToastOptions<{}> | undefined = {},
  customComponent?: React.ReactNode
) =>
  toast.success(customComponent || Message(content), {
    ...opts,
    icon: InfoIcon,
  });

export const error = (
  content: {
    message: string;
    title?: string;
    onClick?: (val?: any) => void;
    contentClassName?: string;
  },
  opts: ToastOptions<{}> | undefined = {},
  customComponent?: React.ReactNode
) =>
  toast.error(customComponent || Message(content), {
    ...opts,
    icon: AlertTriangleIcon,
  });

export const warning = (
  content: {
    message: string;
    title?: string;
    onClick?: (val?: any) => void;
    contentClassName?: string;
  },
  opts: ToastOptions<{}> | undefined = {},
  customComponent?: React.ReactNode
) =>
  toast.warning(customComponent || Message(content), {
    ...opts,
    icon: AlertTriangleIcon,
  });

export const info = (
  content: {
    message: string;
    title?: string;
    onClick?: (val?: any) => void;
    contentClassName?: string;
  },
  opts: ToastOptions<{}> | undefined = {},
  customComponent?: React.ReactNode
) =>
  toast.info(customComponent || Message(content), {
    ...opts,
    icon: InfoIcon,
  });
