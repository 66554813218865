export const getParsedMentions = (value: string) => {
  const mentionRegex = /__MENTION\["(.*?)",\s*"(.*?)"\]MENTION__/g;
  const mentionIds: string[] = [];
  const mentionNames: string[] = [];

  const proceedMentions = (_: any, name: string, id: string) => {
    mentionIds.push(id);
    mentionNames.push(name);

    return name;
  };

  const parsedString = value.replace(mentionRegex, proceedMentions);

  return {
    mentionIds: mentionIds.map((el) => +el),
    parsedString,
    mentionNames,
  };
};
