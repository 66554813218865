import { createContext, useContext, useEffect, useState } from "react";
import { useUser } from "./UserContext";
import { useTeam } from "./TeamContext";
import ForbiddenModal from "components/molecules/ForbiddenModal";

interface IForbiddenContext {
  error: string;
  setError: (val: string) => void;
}

const initialValues: IForbiddenContext = {
  error: "",
  setError: () => {},
};

const ForbiddenContext = createContext(initialValues);

export const useForbidden = () => useContext(ForbiddenContext);

const ForbiddenContextProvider: React.FC = ({ children }) => {
  const [error, setError] = useState<string>("");
  const { currentTeam, push } = useTeam();

  useEffect(() => {
    setError("");
  }, [currentTeam]);

  const onClose = () => {
    setError("");
    push("/team");
  };

  return (
    <ForbiddenContext.Provider value={{ error, setError }}>
      {children}
      <ForbiddenModal onClose={onClose} error={error} team={currentTeam} />
    </ForbiddenContext.Provider>
  );
};

export default ForbiddenContextProvider;
