import FullScreenSpinner from "components/organisms/FullScreenSpinner/FullScreenSpinner";
import Team from "models/Team";
import { createContext, useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { useRouter } from "next/router";
import { useUser } from "./UserContext";
import axios from "axios";
import { isAuthorizedRoutes } from "utils/isAuthorizedRoutes";
import { useMemo } from "react";
import { SubscriptionStatuses, TeamMember } from "types";
import { usePortal } from "./PortalContext";
import { isOwnerUser } from "utils/isOwnerUser";
import { error, warning } from "utils/toast";
import useTranslation from "next-translate/useTranslation";
import { refreshToken } from "utils/refreshToken";
import { useQueryClient } from "react-query";
import { DEFAULT_PORTAL_NAME, HOME_ROUT } from "utils/constants";

interface TeamContext {
  currentTeam?: Team;
  teamId: number;
  push: (path: string) => void;
  replace: (path: string) => void;
  path: (path: string) => string;
  pushWithState: (path: string, state: object) => void;
  refetchMembers: Function;
  refetchTeam: Function;
  unitedMembers: TeamMember[];
  teamMembers?: { members: TeamMember[]; guests: TeamMember[] };
  isFetched: boolean;
  isMaintainer?: boolean;
}
interface TeamProviderProps {
  children: any;
}

const getTeamMembers = async (
  userToken: string,
  teamId: number,
  portalId?: number
): Promise<{ members: TeamMember[]; guests: TeamMember[] }> => {
  try {
    const headers: any = {
      Authorization: `Bearer ${userToken}`,
      teamId,
    };

    if (portalId) {
      headers.portalId = portalId;
    }

    return await axios
      .request({
        headers,
        method: "GET",
        url: `${process.env.NEXT_PUBLIC_API_HOST}/teams/members`,
      })
      .then(
        ({ data }) => data as { members: TeamMember[]; guests: TeamMember[] }
      );
  } catch (error) {
    console.log(error);
    return error;
  }
};

const getCurrentTeam = async (userToken: string, portalId?: number) => {
  const headers: any = { Authorization: `Bearer ${userToken}` };

  if (portalId) {
    headers.portalId = portalId;
  }

  try {
    return await axios
      .request({
        headers,
        method: "GET",
        url: `${process.env.NEXT_PUBLIC_API_HOST}/teams/my/current`,
      })
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

const TeamContext = createContext<TeamContext | undefined>(undefined);

export const TeamProvider = ({ children }: TeamProviderProps) => {
  const { user, userToken, signOut, auth, subscription } = useUser();
  const { t } = useTranslation("common");

  const router = useRouter();

  const queryClient = useQueryClient();

  const { teamId } = router.query;

  const { userPortal, isPortal, portalName } = usePortal();

  const isTeamRoute = router.pathname.includes("[teamId]");

  const {
    data: currentTeam,
    isLoading,
    isFetched,
    refetch,
  } = useQuery<Team>(
    ["currentTeam", user?.id, userPortal?.id],
    () => getCurrentTeam(userToken as string, userPortal?.id),
    {
      keepPreviousData: true,
      enabled:
        (isPortal ? !!userPortal?.id : true) &&
        !!user &&
        !!userToken &&
        user.emailVerified &&
        isAuthorizedRoutes(router),
      async onError(err: any) {
        if (err?.response?.status === 401) {
          return await refreshToken({
            userToken,
            portalId: userPortal?.id,
            queryClient,
            auth,
            error: err,
            onSuccess() {
              refetch();
            },
          });
        }
      },
    }
  );

  const {
    data: teammates = { members: [], guests: [] },
    refetch: refetchMembers,
  } = useQuery<{
    members: TeamMember[];
    guests: TeamMember[];
  }>(
    ["teamMembers", currentTeam?.id, userPortal?.id],
    () =>
      getTeamMembers(
        userToken as string,
        currentTeam?.id as number,
        userPortal?.id as number
      ),
    {
      keepPreviousData: true,
      enabled:
        !!currentTeam &&
        !!userToken &&
        !!user &&
        isAuthorizedRoutes(router) &&
        (isPortal ? !!userPortal?.id : true) &&
        isFetched &&
        currentTeam?.limits?.subscriptionStatus !== SubscriptionStatuses.UNPAID,
      async onError(err: any) {
        if (err?.response?.status === 401) {
          return await refreshToken({
            userToken,
            portalId: userPortal?.id,
            queryClient,
            auth,
            error: err,
            onSuccess() {
              refetchMembers();
            },
          });
        }
      },
    }
  );

  const preparedMembers = useMemo(() => {
    if (
      teammates &&
      user &&
      (teammates?.members?.length || teammates?.guests?.length)
    ) {
      return (teammates.members || [])
        .filter((el) => el.id !== user?.id)
        .concat((teammates.guests || []).filter((el) => el.id !== user?.id));
    }

    return [];
  }, [teammates]);

  // in case user was support at portal team and team owner restrics access to the team
  useEffect(() => {
    if (isPortal && !currentTeam && isFetched) {
      router.push(`/${DEFAULT_PORTAL_NAME}/`);
    }
  }, [isPortal, currentTeam, isFetched]);

  // team not found by id
  useEffect(() => {
    if (
      currentTeam &&
      (isTeamRoute || router.asPath === `/${portalName}`) &&
      (!teamId || currentTeam.id !== +teamId)
    ) {
      router.push(
        `/${portalName}/${isPortal ? "" : currentTeam.id + HOME_ROUT}`
      );
      return;
    }
  }, [teamId, currentTeam, portalName, isPortal, isTeamRoute, router.asPath]);

  // warning about unpaid subscription
  useEffect(() => {
    if (
      currentTeam &&
      currentTeam.limits?.subscriptionStatus ===
        SubscriptionStatuses.PAST_DUE &&
      isOwnerUser(currentTeam.teamRole) &&
      (isPortal ? !!currentTeam?.supplierId : true) &&
      !router.pathname.includes("/subscription-expired")
    ) {
      warning(
        {
          message: t("subscription_expired_desc"),
          onClick() {
            router.push(`/${portalName}/profile/subscription`);
          },
        },
        {
          autoClose: false,
        }
      );
    }
  }, [currentTeam, portalName, subscription, isPortal]);

  useEffect(() => {
    if (
      isPortal &&
      currentTeam &&
      currentTeam?.limits?.subscriptionStatus === SubscriptionStatuses.UNPAID &&
      currentTeam.portalId === userPortal?.id &&
      !currentTeam?.supplierId
    ) {
      error({ message: t("portal_owner_subscription_expired") });
      signOut();
    }
  }, [currentTeam, isPortal, userPortal]);

  const push = (path: string) => {
    router.push(`/${portalName}/${currentTeam?.id}${path}`);
  };
  const replace = (path: string) => {
    router.replace(`/${portalName}/${currentTeam?.id}${path}`);
  };
  const path = (path: string) => {
    const newPath = `/${portalName}/${currentTeam?.id}${path}`;
    return newPath;
  };

  const pushWithState = (path: string, data: any) => {
    if (currentTeam) {
      router.push({
        pathname: `/${portalName}/${currentTeam?.id}${path}`,
        query: data,
      });
    }
  };

  const context = {
    currentTeam,
    teamId: currentTeam?.id!,
    unitedMembers: preparedMembers,
    teamMembers: teammates,
    isMaintainer: !!currentTeam?.supplierId,
    isFetched,
    push,
    refetchMembers,
    path,
    replace,
    refetchTeam: refetch,
    pushWithState,
  };

  if (!!isLoading && !router.pathname.includes("/auth")) {
    return <FullScreenSpinner text="Loading your team" />;
  }

  return (
    <TeamContext.Provider value={context}>{children}</TeamContext.Provider>
  );
};

export const useTeam = () => {
  const context = useContext(TeamContext);

  if (!context) {
    throw new Error("no api context provided");
  }
  return context;
};
