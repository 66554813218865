import { IUserInfo } from "contexts/UserContext";
import { User } from "firebase/auth";
import BillingHistory from "models/BillingHistory";
import Team from "models/Team";
import { QueryClient } from "react-query";
import Stripe from "stripe";
import {
  IBillingHistoryInvoiceResponse,
  IPaginateParams,
  IUserDebts,
  IUserOrdersStatistic,
  IUserUpcomingInvoice,
  PaginatedResponse,
} from "types";
import AbstractApi from "./AbstractApi";
import { UserStatistics } from "./AdminApi";
import {
  IBillingPaginatedParams,
  IPaginatedBillingResponse,
} from "./BillingApi";

export interface IAssignSubscriptionPayload {
  userId: number;
  planName: string;
  freeTrial?: {
    trialEndDate: string;
  } | null;
  coupon?: {
    description: string;
    durationInMonths: string | number;
    percentOff?: string | number;
    amountOff?: string | number;
  } | null;
}

export default class UsersApi extends AbstractApi {
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
  }

  registerUser = (userToken: string, lang: string) => {
    return this.client.post(
      "/users/registerUser",
      { locale: lang },
      {
        headers: { Authorization: "Bearer " + userToken },
      }
    );
  };

  me = () => {
    return this.client.get("/users/me");
  };

  updateMe = (part: any) => {
    return this.client.patch("/users/me", part);
  };

  deleteMe = (_: any) => {
    return this.client.delete(`/users`);
  };

  async getAllUsers(
    params?: IPaginateParams | null,
    sortString?: string,
    withMeta?: boolean
  ): Promise<Array<User & IUserInfo>> {
    let query = "";
    params &&
      Object.entries(params).forEach(([key, value], index) => {
        if (index) {
          query += `&${key}=${value}`;
        } else {
          query += `${key}=${value}`;
        }
      });

    query += sortString;

    if (query) {
      return await this.client
        .get(`/users?${query}`)
        .then((res) => (withMeta ? res : res.data));
    }

    return await this.client
      .get("/users?sortBy=email:ASC")
      .then((res) => res.data);
  }

  async getUserTeamsById({
    userId,
    ...params
  }: any): Promise<PaginatedResponse<Team>> {
    return await this.client.get(`/users/${userId}/teams`, { params });
  }

  async updateUserById(payload: Partial<IUserInfo>) {
    const { id, ...rest } = payload;
    return await this.client.patch(`/users/${id}`, rest);
  }

  async getUserOrdersStatistic(id: number): Promise<IUserOrdersStatistic> {
    return await this.client.get(`/users/${id}/orders-statistic`);
  }

  async getUserInfoById(userId: number): Promise<IUserInfo> {
    return await this.client.get(`/users/${userId}`);
  }

  async getUserSubscriptionById(userId: number): Promise<Stripe.Subscription> {
    return await this.client.get(`/users/${userId}/subscription`);
  }

  async assignSubscriptionToUser(payload: IAssignSubscriptionPayload) {
    const { userId, ...rest } = payload;
    return await this.client.post(`/users/${userId}/subscription`, rest);
  }

  async cancelUserTrial({
    subscriptionId,
    userId,
  }: {
    subscriptionId: string;
    userId: number;
  }) {
    return await this.client.delete(
      `/users/${userId}/subscription/${subscriptionId}/trial`
    );
  }

  async setUserTrialEndDate({
    subscriptionId,
    userId,
    trialEndDate,
  }: {
    subscriptionId: string;
    userId: number;
    trialEndDate: string;
  }) {
    return await this.client.put(
      `/users/${userId}/subscription/${subscriptionId}/trial`,
      { trialEndDate }
    );
  }

  async getUserProjectsStats(userId: number) {
    return await this.client.get(`/users/${userId}/projects-statistic`);
  }

  async getUserTeamsStats(userId: number) {
    return await this.client.get(`/users/${userId}/statistics/teams`);
  }

  async getUserUpcomingInvoice(userId: number): Promise<IUserUpcomingInvoice> {
    return await this.client.get(`/users/${userId}/upcoming-invoice`);
  }

  async getUserPortalStatistic(userId: number): Promise<UserStatistics> {
    return await this.client.get(`/users/${userId}/portal-statistic`);
  }

  async getUserInvoices(
    userId: number,
    params: IBillingPaginatedParams
  ): Promise<IPaginatedBillingResponse<BillingHistory>> {
    return await this.client.get(`/users/${userId}/invoices`, { params });
  }

  async getUserInvoice(
    userId: number,
    invoiceId: string
  ): Promise<IBillingHistoryInvoiceResponse> {
    return await this.client.get(`/users/${userId}/invoices/${invoiceId}`);
  }

  async getUserDebts(userId: number): Promise<IUserDebts> {
    return await this.client.get(`/users/${userId}/subscription/debts`);
  }
}
