import { QueryClient } from "react-query";
import { IFile, IPaginateParams, RoomType } from "types";
import AbstractApi from "./AbstractApi";
import { number } from "joi";

export interface ICreateRoomPayload {
  title?: string;
  type: RoomType;
  userIds: number[];
  orderId?: number;
  orderRequestId?: number;
  proposalId?: number;
  productOrderProposalId?: number;
}

export default class RoomsApi extends AbstractApi {
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
  }

  async getUsersForRoom(params: IPaginateParams) {
    return await this.client.get("/users/my/teammates", { params });
  }

  async createRoom(payload: ICreateRoomPayload) {
    return await this.client.post("/rooms/my", payload);
  }

  async updateRoom(roomId: number, payload: { title: string }) {
    return await this.client.patch(`/rooms/my/${roomId}`, payload);
  }

  async deleteRoom(roomId: number) {
    return await this.client.delete(`/rooms/my/${roomId}`);
  }

  async leaveRoom(roomId: number) {
    return await this.client.post(`/rooms/my/${roomId}/users/leave-room`);
  }

  async getRooms(params: IPaginateParams) {
    let query = "";
    Object.entries(params).forEach(([key, value], index) => {
      if (index) {
        query += `&${key}=${value}`;
      } else {
        query += `${key}=${value}`;
      }
    });

    query += "&sortBy=isFavoriteRoom:DESC&sortBy=lastMessage.createdat:DESC";
    return await this.client.get(`/rooms/my?${query}`);
  }

  async getRoomById(roomId: number) {
    return await this.client.get(`/rooms/my/${roomId}`);
  }

  async getRoomUsers(roomId: number, params: IPaginateParams) {
    return await this.client.get(`/rooms/my/${roomId}/users`, { params });
  }

  async updateRoomUsers({ id, userIds }: { id: number; userIds: number[] }) {
    return await this.client.post(`/rooms/my/${id}/users/replace-users`, {
      userIds,
    });
  }

  async createMessage({
    id,
    text,
    files,
    repliedToId,
    productOrderId,
  }: {
    id: number;
    text: string;
    files?: IFile[];
    productOrderId?: number;
    repliedToId?: number;
  }) {
    return await this.client.post(`/rooms/my/${id}/messages`, {
      text,
      files,
      repliedToId,
      productOrderId,
    });
  }

  async editMessage({
    messageId,
    text,
    roomId,
    files,
  }: {
    messageId: number;
    text: string;
    roomId: number;
    files?: IFile[];
  }) {
    return await this.client.patch(
      `/rooms/my/${roomId}/messages/${messageId}`,
      { text, files }
    );
  }

  async removeMessage({
    messageId,
    roomId,
  }: {
    messageId: number;
    roomId: number;
  }) {
    return await this.client.delete(
      `/rooms/my/${roomId}/messages/${messageId}`
    );
  }

  async getMessages(id: number, params: IPaginateParams) {
    return await this.client.get(`/rooms/my/${id}/messages`, { params });
  }

  async addRoomToFavorites(id: number) {
    return await this.client.post(`/rooms/my/${id}/add-to-favorites`);
  }

  async removeRoomFromFavorites(id: number) {
    return await this.client.post(`/rooms/my/${id}/remove-from-favorites`);
  }

  async viewMessages(id: number) {
    return await this.client.post(`rooms/my/${id}/messages/view`);
  }

  async getMessagesViewStatus() {
    return await this.client.get("/rooms/my/unviewed-messages-exists");
  }
}
