import { ICommentsViewStatus, ICreateCommentPayload } from "api/CommentsApi";
import { useApi } from "contexts/ApiContext";
import { Comment, CommentStatusesEnum } from "models/Comment";
import {
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "react-query";
import { PaginatedResponse } from "types";
import { getNextPageParamDefault } from "utils/getNextPageParamDefault";

export const GLOBAL_COMMENT = "globally-selected-comment";

export const useCreateComment = (
  config?: UseMutationOptions<any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: ICreateCommentPayload) => api.comments.createComment(payload),
    config
  );
};

export const useGetComments = (
  params: Record<string, any>,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useInfiniteQuery(
    ["comments", params],
    ({ pageParam = 1 }) =>
      api.comments.getComments({
        page: pageParam,
        ...params,
      }),
    {
      getNextPageParam: getNextPageParamDefault,
      ...config,
    }
  );
};

export const useGetCommentById = (
  id: number,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<Comment>(
    ["comment", id],
    () => api.comments.getCommentById(id),
    config
  );
};

export const useEditComment = (config?: UseMutationOptions<any, any, any>) => {
  const api = useApi();
  return useMutation(
    (payload: { id: number; payload: Partial<ICreateCommentPayload> }) =>
      api.comments.editComment(payload),
    config
  );
};

export const useDeleteComment = (
  config?: UseMutationOptions<any, any, any>
) => {
  const api = useApi();
  return useMutation((id: number) => api.comments.deleteComment(id), config);
};

export const useGetCommentsViewStatus = (
  params: Record<string, any>,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<ICommentsViewStatus>(
    ["comments-view-status", params],
    () => api.comments.getCommentsViewStatus(params),
    config
  );
};

export const useSetCommentViewStatus = (
  config?: UseMutationOptions<any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (id: number) => api.comments.setCommentViewStatus(id),
    config
  );
};

export const useSetCommentStatus = (
  config?: UseMutationOptions<any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: { id: number; status: CommentStatusesEnum }) =>
      api.comments.setCommentStatus(payload),
    config
  );
};
