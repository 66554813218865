import { useApi } from "contexts/ApiContext";
import { useQuery, UseQueryOptions } from "react-query";
import { parseParams } from "utils/parseParams";

export interface ITrendRankingData {
  name: string;
  group_id: number;
  gender: string;
  catgroup: string;
  basecat: string;
  style_key: string;
  style_value: string;
  season_name: string;
  trend_type: string;
  year_delta: number;
  pct_change: number;
  normalized_style_key: string;
  imgUrl: string;
  reach_label: string;
  jbids: string;
  ai_design_metadata: {
    [key: string]: {
      colormain: string | null;
      fabric: string | null;
      gender: string | null;
      material: string | null;
      pgroup: string;
      ptype: string | null;
      style: string | null;
      subcat: string | null;
    };
  };
}
[];

export const useGetTrendData = (
  rank_type: string,
  params: any,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<ITrendRankingData[]>(
    ["trend-data", params, rank_type],
    () =>
      api.global.query(
        `/trends/func/factory/async_generate_style_ranking`,
        parseParams({
          rank_type,
          ...params,
        })
      ),
    config
  );
};

export const useGetAIDesignInfo = (
  params: any,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<any>(
    ["async_generate_ai_design_info", params],
    () =>
      api.global.query(
        `/trends/func/factory/async_generate_ai_design_info`,
        parseParams({
          ...params,
        })
      ),
    config
  );
};

export interface ITrendPreviousPrice {
  basecat: string;
  catgroup: string;
  gender: string;
  group_id: string;
  name: string;
  normalized_style_key: string;
  product_pct: number;
  style_key: string;
  style_value: string;
  trend_type: string;
}

export const useGetTrendPreviousPricingData = (
  params: any,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<(ITrendPreviousPrice[] | string)[]>(
    ["trend-previous-data", params],
    () =>
      api.global
        .query(
          `/trends/func/factory/async_generate_previous_trend_info`,
          parseParams(params)
        )
        .then((res) => res.data),
    config
  );
};

export interface ITrendPriceRange {
  group_id: string;
  bucket100_price_usd: number;
  retailer_count: number;
  brand_count: number;
  color_count: number;
  jbid_count: number;
  all_bucket_price: number;
  ratio: number;
}

export const useGetTrendPriceRangeData = (
  params: any,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<any>(
    ["trend-price-range-data", params],
    () =>
      api.global
        .query(
          `/trends/func/factory/async_generate_style_trend_insight`,
          parseParams({
            insight_type: "bucket100_price_usd",
            ...params,
          })
        )
        .then((res) => res.data),
    { ...config, enabled: !!params.group_id }
  );
};

export interface ITrendInsightDataTypes {
  sub_material?: string;
  colormain?: string;
  brand?: string;
  brand_count: number;
  all_brand: number;
  ratio: number;
  retailer?: string;
  retailer_count?: number;
  bucket100_price_usd?: number;
  weight?: number;
  color_count?: number;
}

export const useGetTrendInsightData = (
  keyword: string,
  params: any,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<[ITrendInsightDataTypes[], any]>(
    ["insight-brand-ranking", params, keyword],
    () =>
      api.global.query(
        `/trends/func/factory/async_generate_style_trend_insight`,
        parseParams({
          insight_type: keyword,
          ...params,
        })
      ),
    config
  );
};

export interface IInsightDistributionData {
  sub_material?: string;
  colormain?: string;
  brand?: string;
  fs_period_yw: string;
  count_per_fs_period: number;
  total_count_per_fs_period: number;
  ratio_per_fs_period: number;
}

export const useGetTrendDistributionData = (
  keyword: string,
  params: any,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<[any, IInsightDistributionData]>(
    ["insight-brand-ranking", params],
    () =>
      api.global.query(
        `/trends/func/factory/async_generate_distribution`,
        parseParams({
          insight_type: keyword,
          ...params,
        })
      ),
    config
  );
};

export interface ITrendByBrandType extends ITrendRankingData {
  style_brand_id: string;
}

export const useGetTrendsByBrand = (
  params: any,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<ITrendByBrandType[]>(
    ["insight-brand-ranking", params],
    () =>
      api.global.query(
        `/trends/func/factory/async_get_style_cnt_by_brand`,
        parseParams(params)
      ),
    config
  );
};

export const useGetNewDropsData = (
  params: any,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<any[]>(
    ["trends-newdrops-daily-latest", params],
    () => api.global.query(`/trends/newdrops/daily/latest`, { ...params }),
    config
  );
};

export const useGetMarkdownTop10Data = (
  params: any,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<any[]>(
    ["trends-top10-markdown-daily-latest", params],
    () =>
      api.global.query(`/trends/func/factory/async_get_top10_markdown`, {
        ...parseParams(params),
      }),
    config
  );
};

export const useGetRestockTop10Data = (
  params: any,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<any[]>(
    ["trends-top10-restock-daily-latest", params],
    () =>
      api.global.query(`/trends/func/factory/async_get_top10_restock`, {
        ...parseParams(params),
      }),
    config
  );
};

export const useGetRollupCntStyle = (
  params: any,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<ITrendRankingData[]>(
    ["trend-data", params],
    () =>
      api.global.query(
        `/trends/func/factory/async_rollup_cnt_style_ranking`,
        parseParams(params)
      ),
    config
  );
};
