import ArticleView from "components/organisms/Articles/ArticleView";
import { useArticles } from "contexts/ArticlesContext";
import {
  useGetPublishedArticleById,
  useGetPublishedArticles,
} from "hooks/articles";
import { usePrepareInfiniteData } from "hooks/usePrepareInfiniteData";
import Article from "models/Article";
import React, { useEffect, useMemo } from "react";
import ExpandIcon from "public/icons/expand_16.svg";
import CustomButton from "components/atoms/CustomButton";
import BigSpinner from "components/atoms/BigSpinner";
import { useRouter } from "next/router";
import { usePortal } from "contexts/PortalContext";
import Tooltip from "components/atoms/Tooltip";
import useTranslation from "next-translate/useTranslation";

interface TutorialViewProps {
  articleId?: number;
}

const TutorialView: React.FC<TutorialViewProps> = ({ articleId }) => {
  const { articlePageAnchor } = useArticles();
  const { portalName } = usePortal();
  const { push } = useRouter();
  const { t } = useTranslation("common");

  const { data, isLoading, hasNextPage, fetchNextPage } =
    useGetPublishedArticles(
      {
        "filter.pageAnchor": articlePageAnchor,
      },
      {
        enabled: !!articlePageAnchor,
      }
    );

  const articles: Article[] = usePrepareInfiniteData(data);

  const { data: articleById, isLoading: isLoadingDataById } =
    useGetPublishedArticleById(articleId || 0, {
      enabled: !!articleId,
    });

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage]);

  const handleMoveToTutorial = (id?: number) => {
    id && push(`/${portalName}/tutorial/${id}`);
  };

  const article = useMemo(() => {
    if (articlePageAnchor) {
      return articles[0];
    } else {
      return articleById;
    }
  }, [articleById, articles, articlePageAnchor]);

  return (
    <div className="w-full h-[calc(100%-52px)] p-4 overflow-y-auto">
      {isLoading || isLoadingDataById ? (
        <div className="w-full h-full flex items-center justify-center">
          <BigSpinner className="h-20 w-20" />
        </div>
      ) : (
        <div key={article?.id} className="article-drawer-editor">
          <div className="w-full flex items-center justify-between mb-8">
            <h2 className="headline-200 !text-base">{article?.title}</h2>
            <Tooltip
              content={t("view_tutorial_in_full_screen")}
              className="!bg-gray-90 !px-2 !py-0.5 !rounded-md !w-fit"
              arrowHidden
            >
              <CustomButton
                variant="tertiary"
                icon={ExpandIcon}
                viewBox="0 0 16 16"
                className="!p-2"
                onClick={() => handleMoveToTutorial(article?.id)}
              />
            </Tooltip>
          </div>
          <ArticleView article={article?.body} readOnly />
        </div>
      )}
    </div>
  );
};

export default TutorialView;
