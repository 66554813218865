import { QueryClient } from "react-query";
import {
  IOrderFabric,
  IOrderFile,
  IPaginateParams,
  IProductOrderDeliveryData,
  IProductOrderGroupedStatistics,
  IProductOrderProposalSize,
  IProductOrderStatistics,
  IProposalPaymentPlan,
  ISize,
  OrderStatuses,
  PaginatedResponse,
} from "types";
import AbstractApi from "./AbstractApi";
import ProductOrder from "../models/ProductOrder";
import {
  getComplexFilters,
  getQueryStrFormParams,
} from "utils/getComplexFilters";
import Team from "models/Team";

export interface ICreateProductOrderProposalPayload {
  positions: IProductOrderProposalSize[];
  totalPrice: null | number;
  productOrderId: number;
  paymentPlan: IProposalPaymentPlan[];
}

export interface IRejectProductOrderProposalPayload {
  productOrderId: number;
  proposalId: number;
  statusReason: string;
}

export interface ICreateProductOrder {
  title: string;
  sku: string;
  currency: string;
  expectedPricePerUnit: string | number;
  gender: string;
  catgroup: string;
  basecat: string;
  subcat: string;
  deliveryAddress: string;
  deliveryContact: string;
  preferredLocation?: string[];
  sizes?: ISize[];
  sizesFiles?: IOrderFile[];
  productFiles: IOrderFile[];
  techPackFiles: IOrderFile[];
  fabrics?: IOrderFabric[];
  deliveryAddress2?: string;
  deliveryCity: string;
  deliveryState?: string;
  deliveryZipCode: string;
  deliveryCountry?: string;
  deliveryContactName: string;
  season?: string;
  color?: string;
  teamId?: number;
}

export default class ProductOrdersApi extends AbstractApi {
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
  }

  // orders
  async getAdminProductOrders(
    params: Record<string, any>
  ): Promise<PaginatedResponse<ProductOrder>> {
    const { proposalsFilters, statusFilters, ...rest } = params;

    let query = getQueryStrFormParams(rest);

    query +=
      getComplexFilters(proposalsFilters, "filter.proposals.status", true) +
      getComplexFilters(statusFilters, "filter.status", true);

    return await this.client.get(`/product-orders/${query}`);
  }

  async getAdminProductOrderById(id: number): Promise<ProductOrder> {
    return await this.client.get(`/product-orders/${id}`);
  }

  async getProductOrders(
    params: Record<string, any>
  ): Promise<PaginatedResponse<ProductOrder>> {
    const { proposalsFilters, statusFilters, periodCompletedFilters, ...rest } =
      params;

    let query = getQueryStrFormParams(rest);

    query +=
      getComplexFilters(proposalsFilters, "filter.proposals.status", true) +
      getComplexFilters(statusFilters, "filter.status", true) +
      getComplexFilters(periodCompletedFilters, "filter.completedAt", true);

    return await this.client.get(`/product-orders/my${query}`);
  }

  async getProductOrderById(id: number): Promise<ProductOrder> {
    return await this.client.get(`/product-orders/my/${id}`);
  }

  async getProductOrdersTeams(
    params: Record<string, any>
  ): Promise<PaginatedResponse<Team>> {
    return await this.client.get("/product-orders/teams", params);
  }

  async createProductOrder(
    payload: ICreateProductOrder
  ): Promise<ProductOrder> {
    return await this.client.post("/product-orders/my", payload);
  }

  async createProductOrderBySupplier(
    payload: ICreateProductOrder
  ): Promise<ProductOrder> {
    return await this.client.post("/product-orders", payload);
  }

  async updateProductOrder(
    payload: Partial<ProductOrder>
  ): Promise<ProductOrder> {
    const { id, ...rest } = payload;
    return await this.client.patch(`/product-orders/my/${id}`, rest);
  }

  async updateProductOrderBySupplier(
    payload: Partial<ProductOrder>
  ): Promise<ProductOrder> {
    const { id, ...rest } = payload;
    return await this.client.patch(
      `/product-orders/available-for-supplier/${id}`,
      rest
    );
  }

  async deleteProductOrder(id: number) {
    return await this.client.delete(`/product-orders/my/${id}`);
  }

  async publishProductOrder({
    orderId,
    portalId,
  }: {
    orderId: number;
    portalId?: number;
  }) {
    return await this.client.post(`/product-orders/my/${orderId}/publish`, {
      portalId,
    });
  }

  async cancelProductOrder(orderId: number) {
    return await this.client.post(`/product-orders/my/${orderId}/cancel`);
  }

  async hideProductOrder(orderId: number) {
    return await this.client.post(`/product-orders/${orderId}/hide`);
  }

  async getPortalProductOrders(
    params: Record<string, any>
  ): Promise<PaginatedResponse<ProductOrder>> {
    const { proposalsFilters, statusFilters, periodCompletedFilters, ...rest } =
      params;

    let query = getQueryStrFormParams(rest);

    query +=
      getComplexFilters(proposalsFilters, "filter.proposals.status", true) +
      getComplexFilters(statusFilters, "filter.status", true) +
      getComplexFilters(periodCompletedFilters, "filter.completedAt", true);

    return await this.client.get(
      `/product-orders/available-for-supplier/me${query}`
    );
  }

  async getPortalProductOrderById(id: number): Promise<ProductOrder> {
    return await this.client.get(
      `/product-orders/available-for-supplier/${id}`
    );
  }

  async updateProductOrderStatus({
    orderId,
    status,
    payload,
  }: {
    orderId: number;
    status: OrderStatuses;
    payload?: IProductOrderDeliveryData;
  }) {
    return await this.client.post(
      `/product-orders/available-for-supplier/${orderId}/set-status/${status}`,
      payload
    );
  }

  async updateProductOrderStatusByBrand({
    orderId,
    status,
  }: {
    orderId: number;
    status: OrderStatuses;
  }) {
    return await this.client.post(
      `/product-orders/my/${orderId}/set-status/${status}`
    );
  }

  async updateProductOrderShipmentData({
    id,
    payload,
  }: {
    id: number;
    payload: IProductOrderDeliveryData;
  }) {
    return await this.client.post(
      `/product-orders/available-for-supplier/${id}/set-delivery-data`,
      payload
    );
  }

  // proposals
  async offerProductOrderProposal(payload: ICreateProductOrderProposalPayload) {
    const { productOrderId, ...rest } = payload;
    return await this.client.post(
      `/product-orders/${productOrderId}/proposals/offered`,
      { ...rest, orderId: productOrderId }
    );
  }

  async getOfferedProductOrderProposals(
    payload: IPaginateParams & { productOrderId: number }
  ) {
    const { productOrderId, ...params } = payload;
    return await this.client.get(
      `/product-orders/${productOrderId}/proposals/offered`,
      { params }
    );
  }

  async getReceivedProductOrderProposals(
    payload: IPaginateParams & { productOrderId: number }
  ) {
    const { productOrderId, ...params } = payload;
    return await this.client.get(
      `/product-orders/${productOrderId}/proposals/received`,
      { params }
    );
  }

  async updateOfferedProductOrderProposal(
    payload: Partial<ICreateProductOrderProposalPayload> & {
      proposalId: number;
      version: string;
    }
  ) {
    const { productOrderId, proposalId, ...rest } = payload;
    return await this.client.patch(
      `/product-orders/${productOrderId}/proposals/offered/${proposalId}`,
      rest
    );
  }

  async deleteOfferedProductOrderProposal(payload: {
    productOrderId: number;
    proposalId: number;
  }) {
    const { productOrderId, proposalId } = payload;
    return await this.client.delete(
      `/product-orders/${productOrderId}/proposals/offered/${proposalId}`
    );
  }

  async acceptReceivedProductOrderProposal(payload: {
    productOrderId: number;
    proposalId: number;
  }) {
    const { productOrderId, proposalId } = payload;
    return await this.client.post(
      `/product-orders/${productOrderId}/proposals/received/${proposalId}/accept`
    );
  }

  async rejectReceivedProductOrderProposal(
    payload: IRejectProductOrderProposalPayload
  ) {
    const { productOrderId, proposalId, ...rest } = payload;
    return await this.client.post(
      `/product-orders/${productOrderId}/proposals/received/${proposalId}/reject`,
      rest
    );
  }

  async returnReceivedProductOrderProposal(
    payload: IRejectProductOrderProposalPayload
  ) {
    const { productOrderId, proposalId, ...rest } = payload;
    return await this.client.post(
      `/product-orders/${productOrderId}/proposals/received/${proposalId}/return`,
      rest
    );
  }

  // statistics
  async getSuppliersStatistics(params: any): Promise<IProductOrderStatistics> {
    return await this.client.get("/product-orders/statistics/all-suppliers", {
      params,
    });
  }

  async getGroupedSuppliersStatistics(
    params: any
  ): Promise<IProductOrderGroupedStatistics> {
    return await this.client.get(
      "/product-orders/statistics/grouped-by-suppliers",
      { params }
    );
  }

  async getBrandsStatistics(params: any): Promise<IProductOrderStatistics> {
    return await this.client.get("/product-orders/statistics/all-brands", {
      params,
    });
  }

  async getGroupedBrandsStatistics(
    params: any
  ): Promise<IProductOrderGroupedStatistics> {
    return await this.client.get(
      "/product-orders/statistics/grouped-by-brands",
      { params }
    );
  }

  async getFirstSupplierOrder(): Promise<ProductOrder | null> {
    return await this.client.get(
      "/product-orders/statistics/first-supplier-order"
    );
  }

  async getFirstBrandOrder(): Promise<ProductOrder | null> {
    return await this.client.get(
      "/product-orders/statistics/first-brand-order"
    );
  }
}
