import {
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "react-query";
import { useApi } from "../contexts/ApiContext";
import { PaginatedResponse } from "../types";
import Article from "../models/Article";
import { ICreateArticlePayload, IUpdateArticlePayload } from "api/ArticlesApi";
import { getNextPageParamDefault } from "utils/getNextPageParamDefault";

export const useGetArticles = (
  params: any,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useInfiniteQuery<PaginatedResponse<Article>>(
    ["articles", params],
    ({ pageParam = 1 }) =>
      api.articles.getArticles({
        page: pageParam,
        ...params,
      }),
    {
      getNextPageParam: getNextPageParamDefault,
      ...config,
    }
  );
};

export const useGetArticleById = (
  articleId: number,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<Article>(
    ["article", articleId],
    () => api.articles.getArticleById(articleId),
    config
  );
};

export const useCreateArticle = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: ICreateArticlePayload) => api.articles.createArticle(payload),
    config
  );
};

export const useUpdateArticle = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: IUpdateArticlePayload) => api.articles.updateArticle(payload),
    config
  );
};

export const useDeleteArticle = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation((id: number) => api.articles.deleteArticle(id), config);
};

export const useGetPublishedArticles = (
  params: any,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useInfiniteQuery<PaginatedResponse<Article>>(
    ["published-articles", params],
    ({ pageParam = 1 }) =>
      api.articles.getPublishedArticles({
        page: pageParam,
        ...params,
      }),
    {
      getNextPageParam: getNextPageParamDefault,
      ...config,
    }
  );
};

export const useGetPublishedArticleById = (
  articleId: number,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<Article>(
    ["published-article", articleId],
    () => api.articles.getPublishedArticleById(articleId),
    config
  );
};

export const useGetArticlePageAnchors = (
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<
    Array<{
      pageAnchor: string;
      relatedFeature: string;
    }>
  >(
    ["article-page-anchors"],
    () => api.articles.getArticlesPageAnchors(),
    config
  );
};
