import cn from "classnames";

interface FilterPillboxBtnProps {
  label: string;
  active?: boolean;
  onClick?: (e: any) => void;
  className?: string;
}

const FilterPillboxBtn: React.FC<FilterPillboxBtnProps> = ({
  label,
  active,
  onClick,
  className,
}) => {
  return (
    <button
      className={cn(
        "bg-white border-[0.5px] border-border-normal rounded-[100px] py-2 px-3 flex items-center justify-start gap-1 text-xs text-heading leading-none hover:border-gray-950",
        active && "!bg-gray-950 !text-white !border-gray-950",
        className
      )}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default FilterPillboxBtn;
