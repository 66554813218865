import React from "react";
import cn from "classnames";

interface CategoryFilterButtonProps {
  id?: any;
  label: string | any;
  active?: boolean;
  onClick?: () => void;
  className?: string;
}

const CategoryFilterButton: React.FC<CategoryFilterButtonProps> = ({
  id,
  label,
  active,
  onClick,
  className,
}) => {
  return (
    <button
      id={id}
      className={cn(
        "px-3 py-1 text-center border-[0.5px] border-gray-40 bg-white rounded-md text-xs first-letter:uppercase whitespace-nowrap leading-[22px]",
        "hover:bg-gray-30 hover:text-typography-title active:text-typography-title active:bg-gray-10 rounded-[100px] h-[30px]",
        active && "bg-gray-950 text-white",
        className
      )}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default CategoryFilterButton;
