import { Fragment } from "react";
import cn from "classnames";
import { Menu, Transition } from "@headlessui/react";
import ArrowDownIcon from "public/icons/chevron_down_16.svg";
import CheckIcon from "public/icons/check_12.svg";
import Icon from "../Icon";

export type DropdownTextItemType = {
  name: string;
  icon?: any;
  value?: string | number | any;
};

interface DropdownTextProps {
  label?: string;
  displayValue?: string;
  labelIcon?: any;
  items: DropdownTextItemType[];
  selectedItems?: DropdownTextItemType;
  onChange?: Function;
  disabled?: boolean;
  menuSubTitle?: string;
  checkIcon?: boolean;
  labelClassName?: string;
  className?: string;
  menuClassName?: string;
  secondItems?: DropdownTextItemType[];
  secondSelectedItems?: DropdownTextItemType;
  secondMenuSubTitle?: string;
  onChangeSecondMenu?: Function;
  hideLabel?: boolean;
  actionComponent?: any;
  topActionComponent?: any;
}

const DropdownText = ({
  label,
  displayValue,
  labelIcon,
  items,
  disabled,
  selectedItems,
  onChange,
  menuSubTitle,
  checkIcon,
  labelClassName,
  menuClassName,
  className,
  secondItems,
  actionComponent,
  topActionComponent,
  secondSelectedItems,
  secondMenuSubTitle,
  onChangeSecondMenu,
  hideLabel = false,
}: DropdownTextProps) => {
  return (
    <Menu
      as="div"
      className={cn("relative flex items-center text-left", className)}
    >
      <Menu.Button
        disabled={disabled}
        onClick={(e: any) => e.stopPropagation()}
        className={cn(
          "inline-flex justify-center items-center gap-1 w-full text-sm text-typography-body-normal bg-opacity-20",
          "hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 rounded-md px-1 py-0.5",
          disabled && "bg-gray-40 !text-gray-60",
          labelClassName
        )}
      >
        {labelIcon && <span className="flex-none w-fit">{labelIcon}</span>}
        {selectedItems?.icon && (
          <span className="flex-none w-fit">{selectedItems?.icon}</span>
        )}
        <span className="flex-1 w-full truncate">
          {!hideLabel && (displayValue || selectedItems?.name || label)}
        </span>
        <span className="flex-none w-fit">
          <Icon
            component={ArrowDownIcon}
            viewBox="0 0 16 16"
            width={12.5}
            height={12.5}
            className="fill-black flex-none"
          />
        </span>
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={cn(
            "z-10 rounded-md overflow-hidden w-fit m-h-44 overflow-y-auto absolute top-8 ",
            "bg-white divide-gray-100 shadow-lg ring-1 ring-gray-900 ring-opacity-5",
            "focus:outline-none max-h-[200px] p-2",
            menuClassName
          )}
        >
          {topActionComponent && topActionComponent}
          <div className="text-[10px] text-typography-body-subtle px-3 pb-1">
            {menuSubTitle}
          </div>
          {items.map((item, i) => {
            return (
              <Menu.Item
                as={"div"}
                key={i}
                className={cn(
                  "px-2 text-sm flex items-center gap-2.5 hover:bg-border-input-hover cursor-pointer rounded-md p-2 w-full",
                  item.value === selectedItems?.value && "bg-gray-30"
                )}
                onClick={() => onChange && onChange(item)}
              >
                {item?.icon && (
                  <span className="flex-none w-fit">{item?.icon}</span>
                )}
                <span className="flex-1 w-full line-clamp-1">{item.name}</span>
                {item.value === selectedItems?.value && checkIcon && (
                  <span className="flex-none w-fit">
                    <Icon
                      component={CheckIcon}
                      viewBox="0 0 12 12"
                      width={12}
                      height={12}
                    />
                  </span>
                )}
              </Menu.Item>
            );
          })}
          {secondItems && (
            <>
              <div className="my-2 w-full h-[1px] bg-gray-40" />
              <div className="text-[10px] text-typography-body-subtle px-3 pb-1">
                {secondMenuSubTitle}
              </div>
              {secondItems.map((item, i) => {
                return (
                  <Menu.Item
                    as={"div"}
                    key={i}
                    className={cn(
                      "px-2 text-sm flex items-center gap-2.5 hover:bg-border-input-hover cursor-pointer rounded-md p-2 whitespace-nowrap",
                      item.value === secondSelectedItems?.value && "bg-gray-30"
                    )}
                    onClick={() =>
                      onChangeSecondMenu && onChangeSecondMenu(item)
                    }
                  >
                    {item.name}
                    {item.value === secondSelectedItems?.value && checkIcon && (
                      <Icon
                        component={CheckIcon}
                        viewBox="0 0 12 12"
                        width={12}
                        height={12}
                      />
                    )}
                  </Menu.Item>
                );
              })}
            </>
          )}
          {actionComponent && actionComponent}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default DropdownText;
