import React, { useState } from "react";
import cn from "classnames";
import Spinner from "./Spinner";
import Icon from "./Icon";
import DeleteIcon from "public/icons/delete.svg";
import ArrowLeftIcon from "public/icons/arrow_left.svg";
import { theme } from "tailwind.config";

type Variant =
  | "primary"
  | "secondary"
  | "tetriary"
  | "white"
  | "danger"
  | "cta"
  | "social";

type Size = "lg" | "sm" | "sq" | "ro";

const variantsClasses: Record<Variant, string> = {
  primary:
    "bg-gray-900 text-white hover:bg-cta-700 hover:bg-cta-700 active:bg-black",
  secondary:
    "text-gray-900 border-gray-800 text-gray-800 border hover:border-cta-600 hover:text-cta-600 active:border-cta-700 active:text-cta-700",
  tetriary:
    "bg-gray-100 hover:text-white hover:bg-cta-500 active:bg-cta-700 disabled:text-white",

  white: "bg-white shadow hover:bg-cta-500 hover:text-white active:bg-cta-700",
  danger:
    "text-danger-600 bg-white border border-gray-200 disabled:border-gray-200 hover:border-danger-100 hover:bg-danger-100 hover:text-danger-600 active:bg-danger-200 active:text-danger-600",
  cta: "text-white bg-cta-500 hover:bg-cta-600 active:bg-cta-700",
  social:
    "bg-white shadow border border-white hover:border-[#346EF1] hover:text-[#346EF1]",
};

const iconStyles = {
  idle: {
    primary: theme.extend.colors.white,
    secondary: theme.extend.colors.black,
    tetriary: theme.extend.colors.black,
    white: theme.extend.colors.black,
    danger: theme.extend.colors.danger[600],
    cta: theme.extend.colors.white,
  },
  hover: {
    primary: theme.extend.colors.white,
    secondary: theme.extend.colors.indigo[600],
    tetriary: theme.extend.colors.white,
    white: theme.extend.colors.white,
    danger: theme.extend.colors.danger[600],
    cta: theme.extend.colors.white,
  },
  disabled: {
    secondary: theme.extend.colors.gray[600],
    cta: theme.extend.colors.white,
  },
  active: {
    primary: theme.extend.colors.white,
    secondary: theme.extend.colors.black,
    tetriary: theme.extend.colors.white,
    white: theme.extend.colors.white,
    danger: theme.extend.colors.danger[600],
    cta: theme.extend.colors.white,
  },
};

const spinnerClasses: Record<Variant, string> = {
  primary: "",
  secondary: "",
  tetriary: "",
  white: "",
  danger: "bg-gray-900",
  cta: "",
};

const sizeClasses: Record<Size, string> = {
  lg: "py-[15px] px-6 text-sm leading-none rounded-xl uppercase disabled:bg-gray-200",
  sq: "p-[15px] text-sm rounded-xl uppercase disabled:bg-gray-200",
  sm: "px-4 py-2.5 text-xs rounded-full disabled:bg-gray-200 disabled:text-gray-600 disabled:border-none",
  ro: "p-2 text-xs rounded-full disabled:bg-gray-200",
};

interface ButtonInterface {
  children?: React.ReactNode;
  className?: string;
  variant?: Variant;
  size?: Size;
  spinner?: boolean;
  as?: "div" | "button";
  icon?: ({ color }: { color: string; stroke: string }) => any;
  iconRight?: ({ color }: { color: string }) => any;
  hoverIconColor?: string;
  textClassName?: string;
}

export type ButtonType = ButtonInterface &
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >;

const Button = ({
  children,
  className,
  size = "lg",
  variant = "primary",
  spinner,
  onClick,
  as = "button",
  icon,
  iconRight,
  hoverIconColor,
  textClassName,
  ...rest
}: ButtonType) => {
  const Component = as as any;
  const [hovered, setHovered] = useState(false);
  const [active, setActive] = useState(false);

  let iconColor = iconStyles.idle[variant];
  if (hovered) {
    iconColor = hoverIconColor || iconStyles.hover[variant];
  }
  if (active) {
    iconColor = iconStyles.active[variant];
  }
  if (rest.disabled) {
    iconColor = iconStyles.disabled[variant] || "black";
  }

  return (
    <Component
      className={cn(
        "flex items-center justify-center leading-none hover:fill-red ",
        variantsClasses[variant],
        sizeClasses[size],
        className,
        spinner && "pointer-events-none"
      )}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => {
        setHovered(false);
        setActive(false);
      }}
      onMouseDown={() => setActive(true)}
      onMouseUp={() => setActive(false)}
      onClick={(e) => {
        if (!spinner && onClick) onClick(e);
      }}
      type="button"
      {...rest}
    >
      {spinner && (
        <svg
          role="status"
          className="inline w-4 h-4 mr-3 text-white animate-spin"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="#E5E7EB"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentColor"
          />
        </svg>
      )}
      {icon && icon({ color: iconColor, stroke: iconColor })}
      <span
        className={cn(
          "flex items-center !leading-none whitespace-nowrap",
          textClassName
        )}
      >
        {children}
      </span>
      {iconRight && iconRight({ color: iconColor })}
    </Component>
  );
};

export default Button;

export const DeleteButton = ({
  onClick,
  spinner,
}: {
  onClick: any;
  spinner?: boolean;
}) => (
  <Button
    size="ro"
    variant="danger"
    className={cn(
      "rounded-full border border-gray-200 p-[9px] flex justify-center items-center",
      spinner && "opacity-75"
    )}
    onClick={onClick}
    icon={({ color }) => (
      <Icon
        component={DeleteIcon}
        width="12"
        height="12"
        viewBox="0 0 12 12"
        className={cn(
          spinner && "animate-spin-stick-vertical pointer-events-none"
        )}
      />
    )}
  ></Button>
);

export const BackButton = ({
  onClick,
  variant = "tetriary",
  className,
  size = "sq",
}: {
  onClick: any;
  variant?: Variant;
  className?: string;
  size?: Size;
}) => {
  return (
    <Button
      variant={variant}
      size={size}
      className={cn("mr-6", className)}
      onClick={onClick}
      icon={({ color }) => {
        return (
          <Icon
            component={ArrowLeftIcon}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            stroke={color}
          />
        );
      }}
    ></Button>
  );
};

export const RadioButton = ({
  onClick,
  selected,
  className,
  loading,
}: {
  onClick?: any;
  selected?: boolean;
  className?: string;
  loading?: boolean;
}) => {
  return (
    <>
      {loading ? (
        <div className="flex h-[20px] w-[20px] mr-2 items-center">
          <Spinner className="bg-black w-1 h-1 mr-0.5" />
        </div>
      ) : (
        <button
          className={cn(
            "border rounded-full border-gray-900  h-[20px] w-[20px] flex items-center justify-center mr-2",
            loading ? "border-dashed animate-spin" : "",
            className
          )}
          type="button"
          onClick={onClick}
        >
          {selected && (
            <div className="h-[12px] w-[12px] rounded-full bg-cta-700"></div>
          )}
        </button>
      )}
    </>
  );
};
