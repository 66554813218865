import dayjs from "dayjs";

export const formatDate = (date) => {
  if (!date) {
    return "N/A";
  }

  return dayjs(date).format("MMM DD HH:mm");
};

export const convertDate = (date: string | number, format: string) => {
  return dayjs(new Date(date)).format(format);
};

export const findClosestData = (
  targetDate: number,
  dates: { x: number; y: number }[]
): { x: number; y: number } | null => {
  let closestData: { x: number; y: number } | null = null;
  let minDifference = Infinity;

  for (const dateObj of dates) {
    if (dateObj.x > targetDate) {
      const difference = Math.abs(targetDate - dateObj.x);
      if (difference < minDifference) {
        minDifference = difference;
        closestData = dateObj;
      }
    }
  }

  return closestData;
};

export function formatTimestamp(timestamp: number | string): string {
  const now = dayjs();
  const givenDate = dayjs(timestamp);
  const diffInSeconds = now.diff(givenDate, "second");
  const diffInMinutes = now.diff(givenDate, "minute");
  const diffInHours = now.diff(givenDate, "hour");
  const diffInDays = now.diff(givenDate, "day");

  if (diffInSeconds === 0 || diffInSeconds < 0) {
    return "now";
  } else if (diffInSeconds < 60) {
    return `${diffInSeconds} sec${diffInSeconds !== 1 ? "s" : ""} ago`;
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} min${diffInMinutes !== 1 ? "s" : ""} ago`;
  } else if (diffInHours < 24) {
    return `${diffInHours} hr${diffInHours !== 1 ? "s" : ""} ago`;
  } else if (diffInDays <= 3) {
    return `${diffInDays} day${diffInDays !== 1 ? "s" : ""} ago`;
  } else {
    return givenDate.format("MM/DD/YYYY, h A");
  }
}

export const getLastMonth = () => {
  const date = new Date();

  date.setDate(1);

  date.setMonth(date.getMonth() - 1);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");

  return `${year}-${month}`;
};

export const getThisMonth = () => {
  const date = new Date();

  date.setDate(1);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");

  return `${year}${month}`;
};

export const calculateMonthCount = (inputDate: string) => {
  const [startYear, startMonth] = inputDate.split("-").map(Number);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  const totalMonthsCurrent = currentYear * 12 + currentMonth;
  const totalMonthsStart = startYear * 12 + startMonth;

  const monthCount = totalMonthsCurrent - totalMonthsStart;

  return monthCount;
};
