import { useState } from "react";
import { createContext } from "react";
import { useContext } from "react";

interface IInitialState {
  articlePageAnchor: string;
  setArticlePageAnchor: (val: string) => void;
  resetArticlePageAnchor: () => void;
}

const initialState: IInitialState = {
  articlePageAnchor: "",
  setArticlePageAnchor: () => {},
  resetArticlePageAnchor: () => {},
};

const ArticlesContext = createContext(initialState);

export const useArticles = () => useContext(ArticlesContext);

const ArticlesContextProvider: React.FC = ({ children }) => {
  const [articlePageAnchor, setArticlePageAnchor] = useState<string>("");

  const resetArticlePageAnchor = () => setArticlePageAnchor("");

  return (
    <ArticlesContext.Provider
      value={{
        articlePageAnchor,
        setArticlePageAnchor,
        resetArticlePageAnchor,
      }}
    >
      {children}
    </ArticlesContext.Provider>
  );
};

export default ArticlesContextProvider;
