import React from "react";
import cn from "classnames";
import BigSpinner from "./BigSpinner";
import Tooltip from "./Tooltip";
import { Switch } from "antd";

export interface IToggleSwitchProps {
  label?: string;
  rightLabel?: string;
  className?: string;
  checked: boolean;
  onChange: any;
  disabled?: boolean;
  loading?: boolean;
  labelClassName?: string;
  containerClassName?: string;
  innerClassName?: string;
  tip?: string;
  hideDefaultLabel?: boolean;
}

const ToggleSwitch = ({
  className,
  label,
  rightLabel,
  checked,
  onChange,
  disabled = false,
  loading,
  labelClassName,
  containerClassName,
  innerClassName,
  tip,
  hideDefaultLabel = true,
}: IToggleSwitchProps) => {
  const defaultLabel = checked ? "On" : "Off";

  return (
    <Tooltip content={tip || ""} disabled={!tip}>
      <div
        className={cn(
          "flex items-center justify-center align-center group",
          className
        )}
      >
        <div className={cn("w-full max-w-xs mx-auto", containerClassName)}>
          <div className={cn("flex items-center space-x-4", innerClassName)}>
            {label ? (
              <label
                className={cn(
                  "whitespace-nowrap body-100 !text-typography-tag-subtle cursor-pointer",
                  disabled && "cursor-auto",
                  labelClassName
                )}
              >
                {label}
              </label>
            ) : (
              <>
                {!hideDefaultLabel && (
                  <label
                    className={cn(
                      "whitespace-nowrap body-100 !text-typography-tag-subtle cursor-pointer",
                      disabled && "cursor-auto",
                      labelClassName
                    )}
                  >
                    {defaultLabel}
                  </label>
                )}
              </>
            )}
            <Switch
              checked={checked}
              onChange={() => onChange(!checked)}
              loading={loading}
            />
            {!!rightLabel && (
              <label
                className={cn(
                  "whitespace-nowrap body-100 cursor-pointer",
                  disabled && "cursor-auto"
                )}
              >
                {rightLabel}
              </label>
            )}
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

export default ToggleSwitch;
