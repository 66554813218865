import { QueryClient } from "react-query";
import GlobalApi from "./GlobalApi";

const NEXT_PUBLIC_STATISTICS_API_KEY =
  process.env.NEXT_PUBLIC_STATISTICS_API_KEY;
const NEXT_PUBLIC_STATISTICS_API_HOST =
  process.env.NEXT_PUBLIC_STATISTICS_API_HOST;

export type HistoricalDataType = {
  group_id: string;
  ts_type: string;
  dt: string;
  value: number;
};

export type HistoricalDetailType = {
  group_id: string;
  season_name: string;
  trend_type: string;
  year_delta: number;
  pct_change: number;
  position: string;
  upward: string;
  downward: string;
};

export type RetailPriceDataType = {
  bucket100_price_usd: number;
  retailer_count: number;
  brand_count: number;
  color_count: number;
  jbid_count: number;
  all_bucket_price: number;
  ratio: number;
};

export type RetailDistributionType = {
  retailer: string;
  retailer_count: number;
  all_retailer: number;
  ratio: number;
};

export type FeaturedProductType = {
  jbid: string;
  brand: string;
  name: string;
  price: number;
  price_usd: number;
  currency: number;
  first_seen: number;
  retailer: string;
  imgurl: string;
};

export type MaterialRankingDataType = {
  sub_material: string;
  weight: number;
  total_weight: number;
  ratio: number;
};

export type ColorRankingDataType = {
  colormain: string;
  color_count: number;
  all_color: number;
  ratio: number;
};

export type BranRankingDataType = {
  brand: string;
  brand_count: number;
  all_brand: number;
  ratio: number;
};

export default class TrendDashboardApi extends GlobalApi {
  userToken: string;
  teamId: number;
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
    if (userToken) this.userToken = userToken;
    if (teamId) this.teamId = teamId;
  }

  async getForecastingData(params: {
    group_id?: string;
  }): Promise<HistoricalDataType[]> {
    return await this.query(`/trends/styles/timeseries`, params);
  }

  async getHistoricalDetails(params: {
    group_id?: string;
  }): Promise<HistoricalDetailType[]> {
    return await this.query(`/trends/styles/details`, {
      ...params,
      year_delta: 2,
    });
  }

  async getInsightRankingData(
    type: string,
    params: {
      group_id?: string;
      state?: string;
      edate?: string;
    }
  ): Promise<any[]> {
    return await this.query(`/trends/styles/insight/${type}`, params);
  }

  async getBreakdownData(
    type: string,
    params: {
      group_id?: string;
      breakdown: string;
    }
  ): Promise<any[]> {
    const result = this.query(`/trends/styles/distribution/${type}`, {
      group_id: params.group_id,
      [type]: params.breakdown,
    });

    return result;
  }

  async getStyleRankingData(
    rank_type: string,
    params: {
      gender?: string | null;
      catgroup?: string | null;
      basecat?: string | null;
      style_key?: string | null;
      season?: string;
      year?: number;
      sort_order?: "desc" | "asc";
    }
  ) {
    return await this.query(`/trends/styles/ranking/${rank_type}`, params);
  }

  async getBucket100RetailPriceData(
    group_id: string,
    state?: string,
    edate?: string
  ) {
    return await this.query(`/trends/styles/insight/bucket100_price_usd`, {
      group_id,
      state: state || "",
      edate: edate || "",
    });
  }

  async getDataSourceDistributionData(params: {
    group_id: string;
    sdate?: string;
    edate?: string;
    limit?: number;
  }) {
    return await this.query(`/trends/styles/insight/retailer`, params);
  }

  async getTopFeaturedProductData(params: {
    group_id?: string;
    brand?: string;
    region?: string;
    market?: string;
    retailer?: string;
    basecat?: string;
    gender?: string;
    color?: string;
    material?: string;
    limit?: number;
  }): Promise<FeaturedProductType[]> {
    return await this.query(`/trends/styles/featured`, {
      ...params,
      limit: params.limit || 10,
    });
  }

  async getTrendsByBrand(params: { brand: string }): Promise<any> {
    return await this.query(`/trends/styles/cnt_by_brand`, {
      ...params,
      analysis_type: "style",
      p: "000",
    });
  }
}
