import { NextRouter } from "next/router";

export const isAuthorizedRoutes = (router: NextRouter) => {
  return (
    !router.route?.includes("/auth") &&
    !router.route?.includes("/invite") &&
    !router.route?.includes("/team-invite") &&
    !router.route?.includes("/moodboard")
  );
};
