import React from "react";
import cn from "classnames";
import { Colors } from "utils/constants";

interface IColorTile {
  color: string;
  className?: string;
}

const ColorTile: React.FC<IColorTile> = ({ color, className }) => {
  return (
    <div
      className={cn(
        "w-6 h-6 bg-black border border-gray-40 rounded-md",
        className
      )}
      style={{ background: Colors[color?.toLocaleLowerCase()] }}
    />
  );
};

export default ColorTile;
