import React, { useEffect, useState } from "react";
import useTranslation from "next-translate/useTranslation";
import CtaButton from "components/atoms/CtaButton";
const env = process.env.NEXT_PUBLIC_ENV;

const FullScreenSpinner = ({
  text,
  back,
  noSpin,
}: {
  text?: string;
  back?: () => void;
  noSpin?: boolean;
}) => {
  const { t } = useTranslation("common");
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const int = setInterval(() => {
      setCounter((s) => (s >= 3 ? 0 : s + 1));
    }, 500);
    return () => clearInterval(int);
  }, []);

  return (
    <div className="h-screen flex flex-col items-center justify-center w-full">
      <div className="flex items-center justify-center">
        {text ? t(text) : t("loading")}
        {!noSpin &&
          Array.from({ length: 3 }).map((_, i) => (
            <span key={i} className="w-1">
              {" "}
              {i < counter ? "." : " "}{" "}
            </span>
          ))}
      </div>

      {back && (
        <CtaButton variant="secondary" onClick={back}>
          {t("back")}
        </CtaButton>
      )}
    </div>
  );
};

export default FullScreenSpinner;
