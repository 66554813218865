import React from "react";
import Icon from "components/atoms/Icon";
import InfoCircle from "public/icons/info_circle.svg";
import cn from "classnames";
import useTranslation from "next-translate/useTranslation";
import Tooltip from "./Tooltip";

const InfoTooltip = ({
  dataTip,
  className,
  alt,
  circleClassName,
  iconSize,
  tipClassName = "",
}: {
  dataTip: string;
  className?: string;
  alt?: boolean;
  circleClassName?: string;
  iconSize?: number;
  tipClassName?: string;
}) => {
  const { t } = useTranslation("common");

  return (
    <div className={cn("flex", className)}>
      <Tooltip content={t(dataTip || "")} className={tipClassName}>
        {alt ? (
          <div
            className={cn(
              "flex items-center text-[8px] justify-center cursor-pointer hover:text-cta-600 rounded-full w-3 h-3 bg-white",
              circleClassName
            )}
          >
            ?
          </div>
        ) : (
          <Icon
            className="hover:stroke-cta-600 stroke-gray-900"
            component={InfoCircle}
            viewBox="0 0 20 20"
            width={iconSize || 12}
            height={iconSize || 12}
          />
        )}
      </Tooltip>
    </div>
  );
};

export default InfoTooltip;
