import React from "react";
import cn from "classnames";
import Icon from "../Icon";
import GlobalFlagIcon from "public/icons/global_flag.svg";

interface FlagIconProps {
  countryCode?: string;
  className?: string;
}

const FlagIcon: React.FC<FlagIconProps> = ({ countryCode, className }) => {
  if (!countryCode || countryCode === "global") {
    return (
      <Icon
        component={GlobalFlagIcon}
        viewBox="0 0 21 14"
        className={cn("w-[21px] h-[14px]", className)}
      />
    );
  }

  return (
    <img
      src={`https://flagsapi.com/${countryCode?.toUpperCase()}/shiny/64.png`}
      className={cn("h-4 object-contain", className)}
    />
  );
};

export default FlagIcon;
