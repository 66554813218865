import { QueryClient } from "react-query";
import AbstractApi from "./AbstractApi";

export type BenchmarkProjectType = {
  createdat?: string | number | Date;
  id?: number;
  name?: string;
  mainBrand?: string;
  region?: string;
  market?: string;
  retailer?: string;
  category?: string;
  gender?: string;
  color?: string;
  material?: string;
  brandCompetitors?: [string];
  updatedat?: string;
};
export default class BenchmarkApi extends AbstractApi {
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
  }

  getBenchmarkProjects = async (
    teamid: number
  ): Promise<{
    data: BenchmarkProjectType[];
    link: any;
  }> => {
    return this.client.get(`/benchmark-projects/my`, {
      params: {
        teamid,
      },
    });
  };

  getBenchmarkProject = async (
    benchmarkProjectId: number
  ): Promise<BenchmarkProjectType> =>
    this.client.get(`/benchmark-projects/my/${benchmarkProjectId}`);

  saveBenchmarkProject = async (
    data: BenchmarkProjectType
  ): Promise<BenchmarkProjectType> =>
    this.client.post("benchmark-projects/my", {
      ...data,
    });

  updateBenchmarkProject = async (
    benchmarkProjectId: number,
    data: BenchmarkProjectType
  ): Promise<BenchmarkProjectType> =>
    this.client.patch(`benchmark-projects/my/${benchmarkProjectId}`, data);

  removeBenchmarkProject = async (benchmarkProjectId: number): Promise<any> =>
    this.client.delete(`benchmark-projects/my/${benchmarkProjectId}`);

  removeBenchmarkProjectGuest = async ({
    projectId,
    userId,
  }: {
    projectId: number;
    userId: number;
  }) =>
    await this.client.delete(
      `benchmark-projects/${projectId}/invitations/remove-guest/${userId}`
    );
}
