import React from "react";
import cn from "classnames";
import Icon from "components/atoms/Icon";
import SearchIcon from "public/icons/search_24.svg";
import CloseIcon from "public/icons/close_16.svg";

export interface SearchTextBoxProps {
  value: string;
  placeholder?: string;
  onChange: Function;
  className?: string;
  searchIconProps?: any;
  searchIconClassName?: string;
  iconPosition?: "start" | "end";
  onFocus?: Function;
  onBlur?: Function;
  onClear?: Function;
  onKeyDown?: Function;
}

const SearchTextBox = ({
  placeholder,
  value,
  onChange,
  className,
  searchIconProps,
  searchIconClassName,
  iconPosition = "start",
  onClear,
  onKeyDown,
  ...rest
}: SearchTextBoxProps) => {
  const handleInputChange = (e: { target: { value: string } }) => {
    onChange(e.target.value);
  };

  const handleClear = () => {
    onChange("");
    onClear && onClear();
  };

  return (
    <div className={cn("flex flex-col relative ", className)}>
      <div className="relative z-0">
        {iconPosition === "start" && (
          <div
            className={cn(
              "absolute top-1 left-1 flex items-center justify-center w-8 h-8",
              searchIconClassName
            )}
          >
            <Icon
              component={SearchIcon}
              viewBox="0 0 16 16"
              width={16}
              height={16}
              className="fill-gray-70"
              {...searchIconProps}
            />
          </div>
        )}
        <input
          className={cn(
            "block text-sm font-normal text-body hover:bg-gray-10 focus:border-border-input-focus focus-visible:!outline-border-input-focus !h-10 py-2.5 px-12 w-full z-20 rounded-md border",
            iconPosition === "end" && "!pl-4",
            iconPosition === "start" && "!pl-10",
            className
          )}
          placeholder={placeholder}
          value={value}
          onFocus={() => rest.onFocus && rest.onFocus()}
          onBlur={() => rest.onBlur && rest.onBlur()}
          onChange={handleInputChange}
          onKeyDown={(e) => onKeyDown && onKeyDown(e)}
        />
        {value.length > 0 && (
          <div
            className={cn(
              "absolute top-0.5 right-10 cursor-pointer",
              "hover:bg-gray-40 w-[36px] h-[36px] rounded-md",
              "flex justify-center items-center",
              iconPosition === "start" && "!right-0.5"
            )}
            onClick={handleClear}
          >
            <Icon
              component={CloseIcon}
              viewBox="0 0 16 16"
              className={cn("fill-gray-70")}
            />
          </div>
        )}
        {iconPosition === "end" && (
          <div
            className={cn(
              "absolute top-1 right-1 flex items-center justify-center w-8 h-8",
              searchIconClassName
            )}
          >
            <Icon
              component={SearchIcon}
              viewBox="0 0 16 16"
              width={16}
              height={16}
              className="fill-gray-70"
              {...searchIconProps}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchTextBox;
