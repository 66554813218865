import { useApi } from "contexts/ApiContext";
import Team from "models/Team";
import {
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "react-query";
import {
  IPaginateParams,
  PaginatedResponse,
  TeamMember,
  TeamRoleEnum,
} from "types";
import { getNextPageParamDefault } from "utils/getNextPageParamDefault";

export const useGetOwnerMembers = (
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery("owner-members", () => api.teams.getOwnerMembers(), config);
};

export const useDeleteMember = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation((id: number) => api.teams.removeUserFromTeam(id), config);
};

export const useDeleteManyMembers = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (ids: number[]) => api.teams.removeManyUsersFromTeam(ids),
    config
  );
};

export const useChangeTeamOwner = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    ({ teamId, memberId }: { teamId: number; memberId: number }) =>
      api.teams.changeOwner({ teamId, memberId }),
    config
  );
};

export const useGetMembers = (
  teamId: number,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<{ members: TeamMember[]; guests: TeamMember[] }>(
    ["teamMembers", teamId],
    () => api?.teams.getMembers(teamId),
    config
  );
};

export const useSetTeamVisited = (config?: UseMutationOptions<any, any>) => {
  const api = useApi();
  return useMutation(() => api.teams.setVisited(), config);
};

export const useLeaveTeam = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: { teamId: number }) => api.teams.leave(payload),
    config
  );
};

export const useDeleteTeam = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: { teamId: number }) => api.teams.delete(payload),
    config
  );
};

export const useUpdateTeam = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: { teamPart: Partial<Team>; teamId: number }) =>
      api.teams.update(payload),
    config
  );
};

export const useInviteTeamMember = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: Array<{ email: string; role: TeamRoleEnum }>) =>
      api.teams.inviteMembers(payload),
    config
  );
};

export const useGetPaginatedMembers = (
  params: {
    teamId: number;
  } & IPaginateParams,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<PaginatedResponse<TeamMember>>(
    ["paginated-members", params],
    () => api.teams.getPaginatedMembers(params),
    config
  );
};

export const useGetInfiniteMembers = (
  params: {
    teamId: number;
  } & IPaginateParams,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useInfiniteQuery<PaginatedResponse<TeamMember>>(
    ["infinite-members", params],
    ({ pageParam = 1 }) =>
      api.teams.getPaginatedMembers({ ...params, page: pageParam }),
    config
  );
};

export const useGetPaginatedGuests = (
  params: {
    teamId: number;
  } & IPaginateParams,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<PaginatedResponse<TeamMember>>(
    ["paginated-guests", params],
    () => api.teams.getPaginatedGuests(params),
    config
  );
};

export const useGetInfiniteGuests = (
  params: {
    teamId: number;
  } & IPaginateParams,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useInfiniteQuery<PaginatedResponse<TeamMember>>(
    ["infinite-guests", params],
    ({ pageParam = 1 }) =>
      api.teams.getPaginatedGuests({ ...params, page: pageParam }),
    {
      getNextPageParam: getNextPageParamDefault,
      ...config,
    }
  );
};

export const useChangeMemberRole = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: { memberId: number; teamRole: TeamRoleEnum }) =>
      api.teams.changeAccessLevel(payload),
    config
  );
};

export const useSetCurrentTeam = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (teamId: number) => api.teams.setCurrentTeam(teamId),
    config
  );
};

export const useGetTeams = (
  { teamId }: { teamId: number },
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useInfiniteQuery(
    ["teams", teamId],
    ({ pageParam = 1 }) => api.teams.getAll({ page: pageParam }),
    {
      getNextPageParam: getNextPageParamDefault,
      ...config,
      enabled: !!teamId && config?.enabled,
    }
  );
};

export const useCheckTeamInvite = (
  payload: { teamId: number; email: string; invitationCode?: string },
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<{ hasAccount: boolean }>(
    ["team-invitee", payload],
    () => api.teams.checkTeamInvite(payload),
    config
  );
};

export const useResendInvite = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation((email: string) => api.teams.resendInvite(email), config);
};

export const useCreateTeam = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (teamPart: Partial<Team>) => api.teams.create({ teamPart }),
    config
  );
};

export const useGetTeamMembersByTeamId = (
  teamId: number,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useInfiniteQuery<PaginatedResponse<TeamMember>>(
    ["team-members-by-id", teamId],
    () => api.teams.getTeamMembersByTeamId(teamId),
    {
      getNextPageParam: getNextPageParamDefault,
      ...config,
    }
  );
};

export const useGetTeamGuestsByTeamId = (
  teamId: number,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useInfiniteQuery<PaginatedResponse<TeamMember>>(
    ["team-guests-by-id", teamId],
    () => api.teams.getTeamGuestsByTeamId(teamId),
    {
      getNextPageParam: getNextPageParamDefault,
      ...config,
    }
  );
};

export const useJoinToTeam = (config?: UseMutationOptions<any, any, any>) => {
  const api = useApi();
  return useMutation((teamId: number) => api.teams.joinToTeam(teamId), config);
};

export const useGetIsSupportMember = (
  { teamId, userId }: { teamId: number; userId: number },
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<boolean>(
    [`${userId}-is-support`, userId, teamId],
    () => api.teams.getIsSupportMember({ teamId, userId }),
    config
  );
};
