import { isEmpty } from "lodash";

const useTawk = () => {
  const showLiveSupport = () => {
    if (!isEmpty(window?.Tawk_API)) {
      window?.Tawk_API?.showWidget();
    }
  };
  const shutdownLiveSupport = () => {
    if (!isEmpty(window?.Tawk_API)) {
      window?.Tawk_API?.shutdown();
    }
  };

  return {
    showLiveSupport,
    shutdownLiveSupport,
  };
};

export default useTawk;
