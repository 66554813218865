import { QueryClient } from "react-query";
import GlobalApi from "./GlobalApi";

export class ShopifyGlobalApi extends GlobalApi {
  userToken: string;
  teamId: number;
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
    if (userToken) this.userToken = userToken;
    if (teamId) this.teamId = teamId;
  }

  async getShopifyProducts(params: { id: string; date: string }): Promise<any> {
    return await this.query(
      `/connect/shopify/feed/${params.id}/${params.date}`
    );
  }

  async getShopifyAvailalbeFilterDates(id: string): Promise<any> {
    return await this.query(`/connect/shopify/dates/${id}`);
  }
}
