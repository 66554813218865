import axios from "axios";
import { QueryClient } from "react-query";
import { DEFAULT_PORTAL_NAME } from "./constants";

const getInitialRoute = () => {
  if (typeof window !== "undefined") {
    const splittedPath = window.location.pathname.split("/");
    const initialRoute = splittedPath?.[1];
    if (initialRoute === "subscription" || initialRoute === "admin") {
      return DEFAULT_PORTAL_NAME;
    }

    return initialRoute;
  }

  return DEFAULT_PORTAL_NAME;
};

export const refreshToken = async ({
  userToken,
  teamId,
  auth,
  queryClient,
  portalId,
  error,
  onSuccess,
}: {
  userToken: string | null;
  teamId?: number | null;
  auth: any;
  queryClient: QueryClient;
  portalId?: number;
  error?: any;
  onSuccess?: () => void;
}) => {
  const headers: any = {
    Authorization: `Bearer ${userToken}`,
  };

  if (teamId) {
    headers.teamId = teamId;
  }

  if (portalId) {
    headers.portalId = portalId;
  }

  const res = await axios.request({
    method: "POST",
    url: `https://securetoken.googleapis.com/v1/token?key=${process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY}`,
    data: {
      grant_type: "refresh_token",
      refresh_token: auth?.currentUser?.stsTokenManager?.refreshToken,
    },
  });

  if (res?.status === 200) {
    console.log("TOKEN REFRESHED");
    return auth.currentUser
      .getIdToken()
      .then((token: string) => {
        return axios
          .request({
            url: `${process.env.NEXT_PUBLIC_API_HOST}/users/registerUser`,
            headers: { Authorization: `Bearer ${token}` },
            method: "POST",
          })
          .then(() => {
            console.log("USER REGISTERED");
            if (onSuccess) {
              onSuccess();
            }
            return token;
          });
      })
      .then((token: string) => {
        error.config.headers["Authorization"] = "Bearer " + token;
        error.config.baseURL = undefined;
        return axios.request({
          url: process.env.NEXT_PUBLIC_API_HOST,
          headers,
          ...error.config,
          "no-retry": true,
        });
      })
      .catch((err: any) => {
        console.log("REFRESH TOKEN ERROR");
        const route = getInitialRoute();
        window.location = `/${route}/auth/login` as any;
        queryClient.invalidateQueries("me");
        queryClient.invalidateQueries("currentTeam");
        queryClient.invalidateQueries("teams");
        queryClient.invalidateQueries("user-portal");
      });
  }
};
