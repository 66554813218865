import { QueryClient } from "react-query";
import AbstractApi from "./AbstractApi";

export default class WorkersApi extends AbstractApi {
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
  }

  getEnvVariables = () => {
    return this.client.get(`/workers-manager/env-variables`);
  };

  setEnvVariables = (variables: { key: string; value: string }[]) => {
    return this.client.post(`/workers-manager/env-variables`, { variables });
  };

  getWorkersStatus = () => {
    return this.client.get(`/workers-manager/status`);
  };
}
