import Article from "models/Article";
import { QueryClient } from "react-query";
import { ArticleStatuses, IPaginateParams, PaginatedResponse } from "types";
import AbstractApi from "./AbstractApi";
import type { Value } from "@react-page/editor";

export interface ICreateArticlePayload {
  title: string;
  body: Value;
  pageAnchor?: string;
}

export interface IUpdateArticlePayload extends Partial<ICreateArticlePayload> {
  id: number;
  status?: ArticleStatuses;
}

export default class ArticlesApi extends AbstractApi {
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
  }

  async getArticles(
    params: IPaginateParams
  ): Promise<PaginatedResponse<Article>> {
    return await this.client.get("/articles", { params });
  }

  async getArticleById(articleId: number): Promise<Article> {
    return await this.client.get(`/articles/${articleId}`);
  }

  async createArticle(payload: ICreateArticlePayload) {
    return await this.client.post("/articles", payload);
  }

  async updateArticle(payload: IUpdateArticlePayload) {
    const { id, ...rest } = payload;
    return await this.client.patch(`/articles/${id}`, rest);
  }

  async deleteArticle(id: number) {
    return await this.client.delete(`/articles/${id}`);
  }

  async getPublishedArticles(
    params: IPaginateParams
  ): Promise<PaginatedResponse<Article>> {
    return await this.client.get("/articles/published", { params });
  }

  async getPublishedArticleById(articleId: number): Promise<Article> {
    return await this.client.get(`/articles/published/${articleId}`);
  }

  async getArticlesPageAnchors(): Promise<
    Array<{
      pageAnchor: string;
      relatedFeature: string;
    }>
  > {
    return await this.client
      .get("/articles/page-anchors/all")
      .then(({ data }) => data);
  }
}
