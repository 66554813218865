import { useEffect, useMemo, useState, memo } from "react";
import cn from "classnames";
import CustomButton from "components/atoms/CustomButton";
import Input from "components/atoms/Input";
import RangeChart from "components/atoms/TrendChart/RangeChart";
import { debounce } from "lodash";
import useTranslation from "next-translate/useTranslation";

export type PriceRange = {
  min: number;
  max: number;
};

export interface PriceRangeChartProps {
  range: PriceRange;
  onApply: (range: PriceRange) => void;
  chartData: { x: number; y: number }[];
  className?: string;
  hideTitle?: boolean;
  hideActionButtons?: boolean;
  chartWrapperClassName?: string;
  close?: Function;
}

const PriceRangeChart = ({
  range,
  onApply,
  chartData,
  className,
  hideTitle = false,
  hideActionButtons = false,
  chartWrapperClassName,
  close,
}: PriceRangeChartProps) => {
  const [priceRange, setPriceRange] = useState<PriceRange>({ min: 0, max: 0 });
  const [minValue, setMinValue] = useState<number>(0);
  const [maxValue, setMaxValue] = useState<number>(0);
  const { t } = useTranslation("common");

  const { maxPrice, minPrice } = useMemo(() => {
    const prices = chartData.map((item) => item.x);
    return { maxPrice: Math.max(...prices), minPrice: Math.min(...prices) };
  }, [chartData]);

  useEffect(() => {
    const handleChangePriceRange = debounce(() => {
      if (
        hideActionButtons &&
        (range.min !== range.max ||
          priceRange.max !== maxPrice ||
          priceRange.min !== minPrice) &&
        (range.min !== priceRange.min || range.max !== priceRange.max)
      ) {
        onApply(priceRange);
      }
    }, 500);

    handleChangePriceRange();

    return () => {
      handleChangePriceRange.cancel();
    };
  }, [hideActionButtons, priceRange]);

  useEffect(() => {
    if (range.max !== range.min && range.max !== 0) {
      setPriceRange(range);
      setMinValue(range.min);
      setMaxValue(range.max);
    } else {
      setPriceRange({
        min: minPrice,
        max: maxPrice,
      });
      setMinValue(minPrice);
      setMaxValue(maxPrice);
    }
  }, [maxPrice, minPrice]);

  useEffect(() => {
    setPriceRange({
      ...priceRange,
      min:
        minValue < minPrice
          ? minPrice
          : minValue > priceRange.max
          ? priceRange.max
          : minValue,
    });
    const debounceSetValue = debounce((v) => {
      const value =
        v < minPrice ? minPrice : v > priceRange.max ? priceRange.max : v;
      setPriceRange({
        ...priceRange,
        min: value,
      });
      setMinValue(value);
    }, 750);

    if (priceRange.min !== minValue) {
      debounceSetValue(minValue);
    }

    return () => {
      debounceSetValue.cancel();
    };
  }, [minValue]);

  useEffect(() => {
    const debounceSetValue = debounce((v) => {
      const value =
        v > maxPrice ? maxPrice : v < priceRange.min ? priceRange.min : v;
      setPriceRange({
        ...priceRange,
        max: value,
      });
      setMaxValue(value);

      if (range.max === 999999) {
        onApply({
          min: range.min,
          max: value,
        });
      }
    }, 750);

    if (priceRange.max !== maxValue) {
      debounceSetValue(maxValue);
    }

    return () => {
      debounceSetValue.cancel();
    };
  }, [maxValue]);

  return (
    <>
      <div className={cn("p-6 pb-4 flex flex-col gap-4", className)}>
        {!hideTitle && (
          <div className="text-typography-headline text-sm leading-tight font-medium">
            {t("price_range")}
          </div>
        )}
        <div className={cn("w-[400px] h-14", chartWrapperClassName)}>
          <RangeChart
            minValue={minPrice}
            maxValue={maxPrice}
            chartData={chartData}
            range={priceRange}
            onChange={(value: PriceRange) => {
              setPriceRange(value);
              setMinValue(value.min);
              setMaxValue(value.max);
            }}
          />
        </div>
        <div className="flex items-center justify-between">
          <div className="relative !max-w-[80px] w-full">
            <Input
              value={minValue}
              className="!max-w-[80px] !w-full !h-8 pl-4"
              onChange={(e: any) => {
                const numericValue = e.target.value.replace(/[^0-9]/g, "");
                setMinValue(numericValue || 0);
              }}
            />
            <div className="absolute top-2 left-2 text-typography-body-normal text-xs leading-normal">
              $
            </div>
          </div>
          <div className="relative !max-w-[80px] w-full">
            <Input
              value={maxValue}
              className="!max-w-[80px] !w-full !h-8"
              onChange={(e: any) => {
                const numericValue = e.target.value.replace(/[^0-9]/g, "");
                setMaxValue(numericValue || 0);
              }}
            />
            <div className="absolute top-2 left-2 text-typography-body-normal text-xs leading-normal">
              $
            </div>
          </div>
        </div>
      </div>
      {!hideActionButtons && (
        <div className="p-6 pt-2 flex items-center justify-end gap-2">
          <CustomButton
            label={t("Cancel")}
            variant="tertiary"
            onClick={() => {
              onApply({ min: 0, max: 0 });
              close && close();
            }}
          />
          <CustomButton
            label={t("Apply")}
            onClick={() => {
              onApply(priceRange);
              close && close();
            }}
          />
        </div>
      )}
    </>
  );
};

export default memo(PriceRangeChart);
