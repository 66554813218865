import React, { useContext, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { IBrowserFilters, ProductBrowserOpenFunction } from "types";

export interface IBrowserProps {
  title?: string;
  filters?: IBrowserFilters;
  channel?: string;
}

interface IProductBrowserContext {
  isBrowserOpened: boolean;
  browserProps: IBrowserProps;
  hideBrowser: () => void;
  setBrowserProps: ProductBrowserOpenFunction;
}

const initialValues = {
  isBrowserOpened: false,
  browserProps: {},
  hideBrowser: () => {},
  setBrowserProps: () => {},
};

const ProductBrowserContext =
  React.createContext<IProductBrowserContext>(initialValues);

export const useProductBrowser = () => useContext(ProductBrowserContext);

const ProductBrowserProvider: React.FC = ({ children }) => {
  const [browserProps, setBrowserProps] = useState<IBrowserProps>({});

  const hideBrowser = () => {
    setBrowserProps({});
  };

  return (
    <ProductBrowserContext.Provider
      value={{
        isBrowserOpened: !isEmpty(browserProps),
        hideBrowser,
        browserProps,
        setBrowserProps,
      }}
    >
      {children}
    </ProductBrowserContext.Provider>
  );
};

export default ProductBrowserProvider;
