import { useGetCurrentSupplier } from "hooks/supplier";
import { Supplier } from "models/Supplier";
import React from "react";
import { useContext } from "react";
import { SupplierStatuses } from "types";
import { useTeam } from "./TeamContext";
import { useUser } from "./UserContext";
import omit from "lodash/omit";

interface IInitialValues {
  supplier?: Supplier;
  refetchSupplier: () => void;
  isSupplierFetched: boolean;
  isSupplierExists: boolean;
  isSupplierProfileCompleted: boolean;
}

const SupplierContext = React.createContext<IInitialValues>({
  refetchSupplier: () => {},
  isSupplierFetched: false,
  isSupplierExists: false,
  isSupplierProfileCompleted: false,
});

export const useSupplier = () => useContext(SupplierContext);

const SupplierProvider: React.FC = ({ children }) => {
  const { user, isFetched, subscription } = useUser();
  const { currentTeam } = useTeam();

  const {
    data: supplier,
    refetch: refetchSupplier,
    isFetched: isSupplierFetched,
  } = useGetCurrentSupplier(
    { teamId: currentTeam?.id!, subscription },
    {
      enabled: !!user && !!currentTeam && isFetched,
    }
  );

  const isSupplierProfileCompleted =
    !!supplier &&
    Object.values(
      omit(supplier, [
        "certificationFiles",
        "productionNotes",
        "isFinancingAbility",
        "financingAbilityDescription",
        "grossAnnualSalesCurrency",
        "countryNotes",
      ])
    ).every((el) => !!el);

  const isSupplierExists =
    !!supplier &&
    isSupplierFetched &&
    supplier.status === SupplierStatuses.ACTIVE;

  return (
    <SupplierContext.Provider
      value={{
        supplier,
        refetchSupplier,
        isSupplierFetched,
        isSupplierExists,
        isSupplierProfileCompleted,
      }}
    >
      {children}
    </SupplierContext.Provider>
  );
};

export default SupplierProvider;
