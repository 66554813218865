import { useMemo } from "react";

export const usePrepareInfiniteData = <T>(data: any): T[] => {
  return useMemo(() => {
    if (data && data.pages?.length && data.pages.every((el: any) => !!el)) {
      return data.pages.reduce((acc: T[], el: any) => acc.concat(el.data), []);
    }

    return [];
  }, [data]);
};
