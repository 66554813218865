import { omitBy } from "lodash";
import { QueryClient } from "react-query";
import { getQueryVariable } from "utils/getQueryVariable";
import AbstractApi from "./AbstractApi";
const NEXT_PUBLIC_SHOW_DIRECT_API_REQUEST =
  process.env.NEXT_PUBLIC_SHOW_DIRECT_API_REQUEST;
const NEXT_PUBLIC_STATISTICS_API_KEY =
  process.env.NEXT_PUBLIC_STATISTICS_API_KEY;
const NEXT_PUBLIC_STATISTICS_API_HOST =
  process.env.NEXT_PUBLIC_STATISTICS_API_HOST;

export default class GlobalApi extends AbstractApi {
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
  }

  query = (url: string, params: any = {}) => {
    const opts = {
      params: omitBy(params, (el) => !el),
      headers: {},
    };
    if (NEXT_PUBLIC_SHOW_DIRECT_API_REQUEST) {
      opts.headers = {
        "debug-req":
          NEXT_PUBLIC_STATISTICS_API_HOST +
          url +
          "?" +
          new URLSearchParams({
            ...opts.params,
            // apikey: NEXT_PUBLIC_STATISTICS_API_KEY,
          }).toString(),
      };
    }

    const mode = params.mode ? `/${params.mode}` : "";
    if (params.mode === "onetime") {
      opts.params.token = getQueryVariable("token");
    }

    return this.client
      .get(`/global${mode}${url}`, opts)
      .then((res) => res?.data);
  };
}
