import useDebouncedState from "hooks/useDebouncedState";
import { useLocalStorage } from "hooks/useLocalStorage";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Icon from "./Icon";
import Input, { InputPropsType } from "./Input";
import ClockIcon from "public/icons/recent-search.svg";
import SearchIcon from "public/icons/search_16.svg";
import cn from "classnames";
import useTranslation from "next-translate/useTranslation";

interface ISearchInputProps extends InputPropsType {
  namespace: string;
  suggestionsClassName?: string;
  hideSuggestions?: boolean;
}

const SearchInput: React.FC<ISearchInputProps> = ({
  onChange,
  namespace,
  suggestionsClassName,
  hideSuggestions = false,
  ...props
}) => {
  const { t } = useTranslation("common");
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const [_, setSyncSearch, debouncedSearch] = useDebouncedState("", 1500);
  const { setItemToLocalStorage, getItemFromLocalStorage } = useLocalStorage();

  const items = getItemFromLocalStorage(namespace);

  const recentSearch: string[] = items ? JSON.parse(items) : [];

  const onSelectRecent = (search: string) => {
    setSyncSearch(search);
    if (onChange) {
      onChange({ target: { value: search } } as any);
    }
  };

  const onInputChange = (e: any) => {
    setSyncSearch(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  useEffect(() => {
    if (debouncedSearch.trim()) {
      const items = getItemFromLocalStorage(namespace);
      let recentSearch: string[] = items ? JSON.parse(items) : [];

      if (!recentSearch.includes(debouncedSearch)) {
        recentSearch.unshift(debouncedSearch);
      }

      if (recentSearch.length > 5) {
        recentSearch = recentSearch.slice(0, 5);
      }

      setItemToLocalStorage(namespace, JSON.stringify(recentSearch));
    }
  }, [debouncedSearch]);

  return (
    <Input
      icon={SearchIcon}
      iconProps={{
        viewBox: "0 0 16 16",
        width: 16,
        height: 16,
      }}
      onFocus={() => setInputFocused(true)}
      onBlur={() => setTimeout(() => setInputFocused(false), 100)}
      onChange={onInputChange}
      placeholder={t("search") + "..."}
      {...props}
      beforeIconClassName={cn(
        "top-[12px] !fill-gray-70",
        props.beforeIconClassName
      )}
    >
      {inputFocused &&
        !props.value &&
        !!recentSearch.length &&
        !hideSuggestions && (
          <ul
            className={cn(
              "absolute -bottom-[200px] p-2 rounded-md border border-border-normal left-0 h-[204px] bg-white z-30 min-w-full w-fit",
              suggestionsClassName
            )}
          >
            {recentSearch.map((item, index) => (
              <li
                onClick={() => onSelectRecent(item)}
                className="p-2 flex cursor-pointer rounded-md items-center body-100 hover:bg-gray-20 hover:text-gray-950 hover:fill-gray-950 fill-gray-60"
                key={item + index}
              >
                <Icon
                  component={ClockIcon}
                  viewBox="0 0 16 16"
                  className="mr-3 hover:fill-gray-950"
                />
                {item}
              </li>
            ))}
          </ul>
        )}
    </Input>
  );
};

export default SearchInput;
