import Team from "models/Team";
import { QueryClient } from "react-query";
import {
  IPaginateParams,
  PaginatedResponse,
  TeamMember,
  TeamRoleEnum,
} from "types";
import AbstractApi from "./AbstractApi";

export default class TeamsApi extends AbstractApi {
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
  }

  getAll = (params: IPaginateParams): Promise<Team[]> => {
    return this.client.get("/teams/my", { params });
  };

  getMembers = (
    teamId: string | number
  ): Promise<{ members: TeamMember[]; guests: TeamMember[] }> => {
    return this.client.get("/teams/members");
  };

  getPaginatedMembers = async (
    params: IPaginateParams
  ): Promise<PaginatedResponse<TeamMember>> =>
    await this.client.get("/teams/team-members", { params });

  getPaginatedGuests = async (
    params: IPaginateParams
  ): Promise<PaginatedResponse<TeamMember>> =>
    await this.client.get("/teams/guests", { params });

  create = ({ teamPart }: { teamPart: Partial<Team> }): Promise<Team> => {
    return this.client.post("/teams", teamPart);
  };

  update = ({
    teamId,
    teamPart,
  }: {
    teamId: number;
    teamPart: Partial<Team>;
  }) => {
    return this.client.patch("/teams/" + teamId, teamPart);
  };

  leave = ({ teamId }: { teamId: number }) => {
    return this.client.delete("/teams/leave/" + teamId);
  };

  delete = ({ teamId }: { teamId: number }) => {
    return this.client.delete("/teams/" + teamId);
  };

  inviteMembers = (invites: Array<{ email: string; role: TeamRoleEnum }>) => {
    return this.client.post("/teams/invite", { invites });
  };

  changeAccessLevel = ({
    teamRole,
    memberId,
  }: {
    teamRole: TeamRoleEnum;
    memberId: number;
  }) => {
    return this.client.patch("/teams/changeMemberRole/" + memberId, {
      teamRole,
    });
  };

  removeUserFromTeam = (userId: number) => {
    return this.client.delete("/teams/removeMember/" + userId);
  };

  removeManyUsersFromTeam = (ids: number[]) => {
    return Promise.all(
      ids.map((id) => this.client.delete("/teams/removeMember/" + id))
    );
  };

  setVisited = () => {
    return this.client.post("/teams/set-visited");
  };

  getOwnerMembers = () => this.client.get("/teams/owner-members");

  changeOwner = ({ teamId, memberId }: { teamId: number; memberId: number }) =>
    this.client.patch(`/teams/team-owner/${memberId}`, { teamId });

  setCurrentTeam = async (teamId: number) =>
    await this.client.post(`/teams/set-current-team/${teamId}`);

  checkTeamInvite = async ({
    teamId,
    email,
    invitationCode,
  }: {
    teamId: number;
    email: string;
    invitationCode?: string;
  }): Promise<{ hasAccount: boolean }> =>
    await this.client.post(`/teams/${teamId}/check-invite`, {
      email,
      invitationCode,
    });

  resendInvite = async (email: string) =>
    await this.client.post(`/teams/resend-invite`, { email });

  joinToTeam = async (teamId: number) =>
    await this.client.post(`/teams/${teamId}/join-support`);

  getTeamsForAdminByTeamId = async (teamId: number) =>
    await this.client.get("/teams/current/users/owner/portals/my", {
      headers: { teamId },
    });

  getTeamMembersByTeamId = async (
    teamId: number
  ): Promise<PaginatedResponse<TeamMember>> =>
    await this.client.get(`/teams/${teamId}/members`);

  getTeamGuestsByTeamId = async (
    teamId: number
  ): Promise<PaginatedResponse<TeamMember>> =>
    await this.client.get(`/teams/${teamId}/guests`);

  getIsSupportMember = async ({
    userId,
    teamId,
  }: {
    teamId: number;
    userId: number;
  }): Promise<boolean> => {
    return this.client.get(`/teams/${teamId}/is-support-member/${userId}`);
  };
}
