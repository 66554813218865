import AbstractApi from "./AbstractApi";
import Project from "models/Project";
import { PaginatedResponse } from "types";
import { QueryClient } from "react-query";

export default class ProjectsApi extends AbstractApi {
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
  }

  async getProjects({
    search,
    limit,
    page,
  }: {
    search?: string;
    limit?: number;
    page?: number;
  }): Promise<PaginatedResponse<Project>> {
    return await this.client.get("/projects", {
      params: {
        page,
        limit,
        search,
      },
    });
  }

  getAllTeamProjects = async (id: number) =>
    this.client.get(`/projects/team-all/${id}`);

  createProject = async ({ templateId }: { templateId: number }) => {
    return this.client.post(`/projects`, {
      templateId,
    }) as Promise<Project>;
  };

  getProjectById = async (projectId: string) => {
    return this.client.get(`/projects/${projectId}`) as Promise<Project>;
  };

  updateProject = ({
    projectId,
    projectPart,
  }: {
    projectId: number;
    projectPart: Partial<Project>;
  }) => {
    return this.client.patch(`/projects/${projectId}`, projectPart);
  };

  deleteProject = (projectId: any) => {
    return this.client.delete(`/projects/${projectId}`);
  };

  deleteManyProjects = (ids: string) => {
    return this.client.delete(`projects/delete-many/${ids}`);
  };

  inviteGuests = ({
    projectId,
    inviteEmails,
  }: {
    projectId: number;
    inviteEmails: string[];
  }) => {
    return this.client.post(`/projects/${projectId}/invite`, { inviteEmails });
  };

  getMembers = (projectId: number) => {
    return this.client.get(`/projects/${projectId}/members`);
  };

  changeGuestRole = ({
    projectId,
    userId,
    role,
  }: {
    projectId: number;
    userId: number;
    role: number;
  }) => {
    return this.client.patch(
      `/projects/${projectId}/changeGuestRole/${userId}`,
      { role }
    );
  };

  removeGuestFromProject = ({
    projectId,
    userId,
  }: {
    projectId: number;
    userId: number;
  }) => this.client.delete(`/projects/${projectId}/removeGuest/${userId}`);

  checkInvite = (token: string) => this.client.get(`/projects/invite/${token}`);

  checkPortalInvite = (token: string, invitationCode: string) =>
    this.client.get(`/projects/invite/${token}/${invitationCode}`);

  getOwnerProjects = () => this.client.get("/projects/owner-projects");

  resendProjectInvite = async (projectId: number, email: string) =>
    await this.client.post(`/projects/${projectId}/resend-invite`, { email });
}
