import type { CellPlugin, Value } from "@react-page/editor";
import Editor from "@react-page/editor";
import slate from "@react-page/plugins-slate";
import { imagePlugin } from "@react-page/plugins-image";
import spacer from "@react-page/plugins-spacer";
import divider from "@react-page/plugins-divider";
import background, { ModeEnum } from "@react-page/plugins-background";
import video from "@react-page/plugins-video";
import "@react-page/plugins-slate/lib/index.css";
import "@react-page/plugins-image/lib/index.css";
import "@react-page/plugins-video/lib/index.css";
import "@react-page/editor/lib/index.css";
import { useApi } from "contexts/ApiContext";
import { useMemo } from "react";
import cn from "classnames";

type Styling = {
  paddingLeft?: number;
  paddingRight?: number;
  paddingBottom?: number;
  paddingTop?: number;
  width?: number;
  height?: number;
};

interface IArticleViewProps {
  article?: Value | null;
  onChange?: (val: Value) => void;
  readOnly?: boolean;
  className?: string;
}

const ArticleView: React.FC<IArticleViewProps> = ({
  article,
  onChange,
  readOnly,
  className,
}) => {
  const api = useApi();

  const uploadImage = async (file: File) => {
    const data = new FormData();
    data.append("file", file as File);

    return await api.admin
      .uploadFile(data)
      .then(({ data }) => ({ url: data } as any))
      .catch((err) => console.log(err));
  };

  const cellPlugins = useMemo(() => {
    return [
      slate((def) => ({
        ...def,
        plugins: {
          headings: def.plugins.headings,
          emphasize: def.plugins.emphasize,
          paragraphs: {
            ...def.plugins.paragraphs,
            paragraph: def.plugins.paragraphs.paragraph((pDef) => ({
              ...pDef,
              Component: ({ style, children }) => (
                <p style={{ ...style, marginTop: 16, marginBottom: 16 }}>
                  {children}
                </p>
              ),
            })),
          },
          alignment: def.plugins.alignment,
          code: def.plugins.code,
          link: def.plugins.link,
        },
      })),
      imagePlugin({ imageUpload: uploadImage }),
      video,
      spacer,
      divider,
      background({
        enabledModes:
          ModeEnum.COLOR_MODE_FLAG |
          ModeEnum.IMAGE_MODE_FLAG |
          ModeEnum.GRADIENT_MODE_FLAG,
      }),
    ];
  }, []);

  const preparedCellPlagins = useMemo(() => {
    return cellPlugins.map<CellPlugin<Styling>>((p: CellPlugin) => ({
      ...p,
      cellStyle: (data) => ({
        paddingLeft: data.paddingLeft,
        paddingRight: data.paddingRight,
        paddingTop: data.paddingTop,
        paddingBottom: data.paddingBottom,
        width: data.width,
        height: data.height,
      }),
      controls: [
        ...(p.controls
          ? [
              {
                title: "Main",
                controls: p.controls,
              },
            ]
          : []),
        {
          title: "Styling",
          controls: {
            type: "autoform",
            columnCount: 3,
            schema: {
              properties: {
                paddingLeft: {
                  type: "number",
                },
                paddingRight: {
                  type: "number",
                },
                paddingBottom: {
                  type: "number",
                },
                paddingTop: {
                  type: "number",
                },
                width: {
                  type: "number",
                },
                height: {
                  type: "number",
                },
              },
            },
          },
        },
      ],
    }));
  }, [cellPlugins]);

  return (
    <div className={cn("w-full", className)}>
      <Editor
        readOnly={readOnly}
        cellPlugins={preparedCellPlagins}
        value={article}
        onChange={onChange}
      />
    </div>
  );
};

export default ArticleView;
