import { useQuery } from "react-query";
import SearchTextBox from "../SearchTextBox";
import { useCallback, useMemo, useState } from "react";
import { FacetsResponse } from "types";
import { useApi } from "contexts/ApiContext";
import useTranslation from "next-translate/useTranslation";
import { capitalize } from "lodash";

interface TrendSearchBarProps {
  value: string;
  onChange: Function;
  onSelectKeyword: (name: string, value: string, source: string) => void;
  placeholder: string;
  filterSource: { name: string; source: string; value: string }[];
}

const TrendSearchBar = ({
  value,
  onChange,
  placeholder,
  onSelectKeyword,
  filterSource,
}: TrendSearchBarProps) => {
  const api = useApi();
  const [openResult, setOpenResult] = useState<boolean>(false);
  const { t } = useTranslation("common");

  const onOpenSearchResult = useCallback(() => {
    setTimeout(() => {
      setOpenResult(true);
    }, 300);
  }, []);

  const onCloseSearchResult = useCallback(() => {
    setTimeout(() => {
      setOpenResult(false);
    }, 200);
  }, []);

  const onSelect = useCallback(
    (name: string, value: string, source: string) => {
      onSelectKeyword(name, value, source);
    },
    [onSelectKeyword]
  );

  const {
    data: facetsData,
    isLoading,
    isFetched,
  } = useQuery<FacetsResponse<any[]>>(["brands"], () =>
    api.global.query("/facets/bstats/datasources/daily/latest", {})
  );

  const allFilteredSourceData = [
    ...filterSource,
    ...(facetsData?.rows?.map((item: any) => ({
      name: item?.brand,
      value: item?.brand,
      source: "brand",
    })) || []),
  ];

  const filteredData = useMemo(() => {
    return allFilteredSourceData.filter((brand) =>
      brand.name.toLocaleLowerCase().includes(value.toLocaleLowerCase())
    );
  }, [allFilteredSourceData, value]);

  const onClear = () => {
    onSelect("", "", "clear_search");
  };

  return (
    <div className="relative w-full">
      <SearchTextBox
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        iconPosition="end"
        className="w-full"
        searchIconClassName="bg-gray-950 rounded-md"
        searchIconProps={{ className: "fill-white" }}
        onBlur={onCloseSearchResult}
        onFocus={onOpenSearchResult}
        onClear={onClear}
        onKeyDown={(e: any) => {
          if (e.keyCode === 13) {
            e.preventDefault();
            onSelectKeyword(value, value, "Search for trend's name");
          }
        }}
      />
      {value !== "" && value.length > 2 && openResult && (
        <div className="absolute z-50 top-[44px] rounded-md border border-border-normal bg-white shadow overflow-y-scroll max-h-[500px] w-full">
          <ul className="p-2">
            {filteredData.map((item, key) => (
              <li
                key={key}
                className="flex items-center justify-between gap-4 px-3 py-2 cursor-pointer hover:bg-gray-10 active:bg-gray-20 rounded-md select-none"
                onClick={() => onSelect(item.name, item.value, item.source)}
              >
                <div className="text-sm leading-[16px] font-normal text-typography-body-normal">
                  {item.name}
                </div>
                <div className="text-xxs leading-normal font-normal text-typography-body-subtle">
                  {capitalize(t(item.source))}
                </div>
              </li>
            ))}
            {filteredData.length === 0 && (
              <div className="flex items-center justify-center gap-4 px-3 py-2 cursor-pointer hover:bg-gray-10 active:bg-gray-20 rounded-md select-none">
                <div className="text-sm leading-[16px] font-normal text-typography-body-normal">
                  {t("no_matching_search_results")}
                </div>
              </div>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default TrendSearchBar;
