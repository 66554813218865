import { AxiosResponse } from "axios";
import { QueryClient } from "react-query";
import { IPrice, ISelectValue, PaginatedResponse } from "types";
import AbstractApi from "./AbstractApi";
import { ITvGarmentKeyword } from "./TvChannelsApi";

export interface AIDesignAssetType {
  id: number;
  assetUrl: string;
  createdById: number;
  createdat: string;
  updatedat: string;
  name?: string;
  description?: string;
  keywordSet: {
    id: number;
    keywordSet: ITvGarmentKeyword[];
  };
  copies: AIDesignAssetType[];
  copyFromAssetId: number;
  team: any;
  isPortalSample?: boolean;
  prices: IPrice[];
  shopifyId?: string;
}

export interface CreateAssetPayload {
  assetUrl: string;
  prices?: IPrice[];
  keywordSet?: ITvGarmentKeyword[];
  isPortalSample?: boolean;
  shopifyId?: string;
  teamId?: number;
}

export interface ShareAssetPayload {
  teamIds: number[];
}

export interface DeleteAIDesignAssetsPayload {
  id: number;
}

export default class AIDesignAssetsApi extends AbstractApi {
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
  }

  async getAssets(
    params: Record<string, any>
  ): Promise<AxiosResponse<AIDesignAssetType[]>> {
    const { keywordsFilter, ...rest } = params;
    let url = "/assets/my";
    if (keywordsFilter) {
      Object.values(keywordsFilter as ISelectValue)
        .filter((selectValue) => !!selectValue.value)
        .forEach(
          (selectValue, index) =>
            (url += !index
              ? `?filter.keywordSet.keywordSet.value=${selectValue.name}`
              : `&filter.keywordSet.keywordSet.value=${selectValue.name}`)
        );
    }
    return await this.client.get(url, { params: rest });
  }

  async getRemainingAssets(
    params: Record<string, any>
  ): Promise<PaginatedResponse<AIDesignAssetType[]>> {
    const { keywordsFilter, ...rest } = params;
    let url = "/assets/my?filter.assetFolders.assetFolderId=$null";
    if (keywordsFilter) {
      Object.values(keywordsFilter as ISelectValue)
        .filter((selectValue) => !!selectValue?.value)
        .forEach(
          (selectValue, index) =>
            (url += `&filter.keywordSet.keywordSet.value=${selectValue.name}`)
        );
    }

    return await this.client.get(url, {
      params: rest,
    });
  }

  private getHeaders(teamId?: number): object {
    return teamId ? { headers: { teamId } } : {};
  }

  async createAsset(payload: CreateAssetPayload): Promise<AIDesignAssetType> {
    const headers = this.getHeaders(payload.teamId);
    return await this.client.post(`/assets/my`, payload, headers);
  }

  async createManyAssets(
    payload: CreateAssetPayload[]
  ): Promise<AIDesignAssetType[]> {
    return await this.client.post("/assets/my/bulk-create", {
      assets: payload,
    });
  }

  async deleteAsset(
    payload: DeleteAIDesignAssetsPayload
  ): Promise<AxiosResponse<AIDesignAssetType>> {
    return await this.client.delete(`/assets/my/${payload.id}`);
  }

  async editAsset(
    id: number,
    payload: Partial<CreateAssetPayload>
  ): Promise<AIDesignAssetType> {
    return await this.client.patch(`/assets/my/${id}`, payload);
  }

  async shareAsset(
    id: number,
    payload: Partial<ShareAssetPayload>
  ): Promise<any> {
    return await this.client.post(`/assets/my/${id}/share`, payload);
  }

  async undoSharingAsset(
    id: number,
    payload: Partial<ShareAssetPayload>
  ): Promise<any> {
    return await this.client.post(`/assets/my/${id}/undo-sharing`, payload);
  }

  async replaceSharing(
    id: number,
    payload: Partial<ShareAssetPayload>
  ): Promise<any> {
    return await this.client.post(`/assets/my/${id}/replace-sharing`, payload);
  }

  async warmAssetClassifier() {
    return await this.client.post("/image-classifier/warm-up");
  }
}
