import { UserProvider } from "contexts/UserContext";
import { ApiProvider } from "./ApiContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { TeamProvider } from "./TeamContext";
import ForbiddenProvider from "./ForbiddenContext";

import ProductBrowserProvider from "./ProductBrowserContext";
import MultipleProductBrowserProvider from "./MultipleProductBrowserContext";
import PortalProvider from "./PortalContext";
import WebSocketProvider from "./WebSocketContext";
import TawkMessengerProvider from "./TawkMessengerContext";
import BreakpointProvider from "./BreakpointContext";
import ArticlesContextProvider from "./ArticlesContext";
import SupplierProvider from "./SupplierContext";
import ForecastStateProvider from "./ForecastStateContext";
interface AllContextsProps {
  children: React.ReactNode;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      // refetchOnMount: false,
    },
  },
});

export const AllContexts = ({ children }: AllContextsProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      {/* {process.env.NEXT_PUBLIC_ENV === "local" && (
        <ReactQueryDevtools initialIsOpen={false} />
      )} */}

      <UserProvider>
        <PortalProvider>
          <TeamProvider>
            <ApiProvider>
              <SupplierProvider>
                <WebSocketProvider>
                  <ForbiddenProvider>
                    <ProductBrowserProvider>
                      <TawkMessengerProvider>
                        <MultipleProductBrowserProvider>
                          <ArticlesContextProvider>
                            <BreakpointProvider>
                              <ForecastStateProvider>
                                {children}
                              </ForecastStateProvider>
                            </BreakpointProvider>
                          </ArticlesContextProvider>
                        </MultipleProductBrowserProvider>
                      </TawkMessengerProvider>
                    </ProductBrowserProvider>
                  </ForbiddenProvider>
                </WebSocketProvider>
              </SupplierProvider>
            </ApiProvider>
          </TeamProvider>
        </PortalProvider>
      </UserProvider>
    </QueryClientProvider>
  );
};
