import { Comment } from "models/Comment";
import { Message } from "models/Message";
import { Translate } from "next-translate";
import { getParsedMentions } from "./getParsedMentions";
import { info } from "./toast";

export const showNewComment = ({
  comment,
  t,
  onClick,
}: {
  comment: Comment;
  t: Translate;
  onClick: (comment: Comment) => void;
}) => {
  const { parsedString } = getParsedMentions(comment.text);
  info({
    title: t("user_added_comment", {
      name: comment.createdBy.name || comment.createdBy.email || "User",
    }),
    message: parsedString,
    onClick,
  });
};

export const showNewMention = ({
  comment,
  t,
  onClick,
}: {
  comment: Comment;
  t: Translate;
  onClick: (comment: Comment) => void;
}) => {
  const { parsedString } = getParsedMentions(comment.text);
  info({
    title: t("you_was_mentioned"),
    message: parsedString,
    onClick,
  });
};

export const showNewMessage = ({
  message,
  t,
  onClick,
}: {
  message: Message;
  t: Translate;
  onClick: (message: Comment) => void;
}) => {
  const { parsedString } = getParsedMentions(message.text);
  info({
    title: t("new_message", {
      name: message.createdBy.name || message.createdBy.email || "User",
    }),
    message: parsedString,
    onClick,
  });
};
