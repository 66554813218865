import { isEqual, debounce } from "lodash";
import React, { createContext, useContext, useState, useEffect } from "react";
import isUndefined from "lodash/isUndefined";
import { useCallback } from "react";

const breakpoints: {
  [key: string]: number;
} = {
  xl5: 1920,
  xl4: 1680,
  xl3: 1536,
  xl2_5: 1440,
  xl2: 1366,
  xl: 1280,
  lg2: 1180,
  lg: 1024,
  md2: 978,
  md: 768,
  sm: 640,
  xs: 540,
  xxs: 320,
};

interface IBreakpointValues {
  xl5: boolean;
  xl4: boolean;
  xl3: boolean;
  xl2_5: boolean;
  xl2: boolean;
  xl: boolean;
  lg2: boolean;
  lg: boolean;
  md2: boolean;
  md: boolean;
  sm: boolean;
  xs: boolean;
  xxs: boolean;
}

const initialValues: IBreakpointValues = {
  xl5: false,
  xl4: false,
  xl3: false,
  xl2_5: false,
  xl2: false,
  xl: false,
  lg2: false,
  lg: false,
  md2: false,
  md: false,
  sm: false,
  xs: false,
  xxs: false,
};

const BreakpointContext = createContext({ ...initialValues, screenWidth: 0 });

export const useBreakpoint = () => useContext(BreakpointContext);

const BreakpointProvider: React.FC<{
  children: any;
}> = ({ children }) => {
  const [breakpoint, setBreakpoint] =
    useState<IBreakpointValues>(initialValues);
  const [screenWidth, setScreenWidth] = useState<number>(0);

  const getCurrentBreakpoint = () => {
    const windowWidth = window.innerWidth;
    const breakpointValues: any = {};
    for (const key in breakpoints) {
      if (windowWidth < breakpoints[key]) {
        breakpointValues[key] = (initialValues as any)[key];
      } else if (windowWidth >= breakpoints[key]) {
        breakpointValues[key] = true;
        break;
      }
    }

    return { ...initialValues, ...breakpointValues };
  };

  const handleResize = useCallback(
    debounce(() => {
      const newBreakpoint = getCurrentBreakpoint();
      setScreenWidth(window.innerWidth);
      if (!isEqual(newBreakpoint, breakpoint)) {
        setBreakpoint(newBreakpoint);
      }
    }, 500),
    [breakpoint]
  );

  useEffect(() => {
    if (!isUndefined(window)) {
      handleResize();
      window.addEventListener("resize", handleResize);
    }

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <BreakpointContext.Provider value={{ ...breakpoint, screenWidth }}>
      {children}
    </BreakpointContext.Provider>
  );
};

export default BreakpointProvider;
