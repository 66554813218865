import { AxiosResponse } from "axios";
import { QueryClient } from "react-query";
import { FeatureNames } from "types";
import AbstractApi from "./AbstractApi";
import { IGenerationOptionPlatform } from "./AdminApi";

export type UserImageGenerationOptions = Record<
  FeatureNames,
  IGenerationOptionPlatform
>;

export default class ImageGenerationApi extends AbstractApi {
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
  }

  async getProgress(
    imageId: number,
    teamid: number
  ): Promise<AxiosResponse<any[]>> {
    return await this.client.get(
      `image-generation/ai-images/${imageId}/progress`,
      {
        params: {
          teamid,
        },
      }
    );
  }

  async getUserImageGenerationOptions(): Promise<UserImageGenerationOptions> {
    return await this.client.get("/image-generation/generation-parameters");
  }
}
