import { ICreateSupplierPayload } from "api/SupplierApi";
import { useApi } from "contexts/ApiContext";
import { Supplier } from "models/Supplier";
import {
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "react-query";
import Stripe from "stripe";
import { getNextPageParamDefault } from "utils/getNextPageParamDefault";

export const useGetSuppliers = (
  params: Record<string, any>,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useInfiniteQuery(
    ["suppliers", params],
    ({ pageParam = 1 }) =>
      api.supplier.getSuppliers({ page: pageParam, ...params }),
    {
      getNextPageParam: getNextPageParamDefault,
      ...config,
    }
  );
};

export const useGetCurrentSupplier = (
  params: { teamId: number; subscription?: Stripe.Subscription },
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<Supplier>(
    ["current-supplier", params],
    () => api.supplier.getCurrentSupplier(),
    config
  );
};

export const useGetSupplierById = (
  id: number,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<Supplier>(
    ["supplier", id],
    () => api.supplier.getSupplierById(id),
    config
  );
};

export const useCreateSupplier = (
  config?: UseMutationOptions<Supplier, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: ICreateSupplierPayload) => api.supplier.createSupplier(payload),
    config
  );
};

export const useUpdateSupplier = (
  config?: UseMutationOptions<Supplier, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: ICreateSupplierPayload & { id: number }) =>
      api.supplier.updateSupplier(payload),
    config
  );
};

export const useDeleteSupplier = (
  config?: UseMutationOptions<any, any, any>
) => {
  const api = useApi();
  return useMutation((id: number) => api.supplier.deleteSupplier(id), config);
};
