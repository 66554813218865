import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

interface IReactPortalProps {
  children: JSX.Element;
  className?: string;
}

const ReactPortal: React.FC<IReactPortalProps> = ({ children, className }) => {
  const [container] = useState(() => {
    return document?.createElement("div");
  });

  useEffect(() => {
    if (className) {
      container.classList.add(className);
    }
    container.classList.add("relative");
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, []);

  return createPortal(children, container);
};

export default ReactPortal;
