import Tag from "models/Tag";
import { QueryClient } from "react-query";
import AbstractApi from "./AbstractApi";

export default class TagsApi extends AbstractApi {
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
  }

  getTags = async (title?: string, limit?: number) => {
    return this.client.get("/tags", {
      params: { title, limit },
    }) as Promise<Tag[]>;
  };

  // getTeamTags = async (title?: string, limit?: number) => {
  //   return this.client.get("/tags/team", {
  //     params: { title, limit },
  //   }) as Promise<Tag[]>;
  // };
}
