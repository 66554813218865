import cn from "classnames";
import React, { useMemo } from "react";
import MultiRangeSlider from "../MultiRangeSlider";
import { RangeType } from "components/molecules/DateRangeChartPopover";

interface RangeChartProps {
  minValue: number;
  maxValue: number;
  range: RangeType;
  chartData: { x: number; y: number }[];
  onChange: (range: RangeType) => void;
  step?: number;
}

const RangeChart = ({
  minValue,
  maxValue,
  range,
  chartData,
  onChange,
  step,
}: RangeChartProps) => {
  const { max, min } = useMemo(() => {
    const prices = chartData.map((item) => item.y);
    return { max: Math.max(...prices), min: Math.min(...prices) };
  }, [chartData]);

  return (
    <div className="relative w-full h-12">
      <div className="w-full h-full flex items-end justify-start border-b border-gray-50">
        {chartData.map((item, index) => {
          const height = (item.y / (max - min)) * 100;
          return (
            <div
              key={index}
              className="w-[10.75px] px-[1px]"
              style={{
                height: `${height}%`,
              }}
            >
              <div
                className={cn(
                  "rounded-t-[1px] h-full",
                  item.x >= range.min && item.x <= range.max
                    ? "bg-gray-950"
                    : "bg-[#F0F0F0]"
                )}
                style={{
                  borderRadius: height <= 5 ? `0` : "",
                }}
              />
            </div>
          );
        })}
      </div>
      <div className="absolute -bottom-[7.5px] w-full">
        <MultiRangeSlider
          min={minValue}
          max={maxValue}
          rangeValues={range}
          onChange={(value: any) => {
            onChange(value);
          }}
          step={step}
        />
      </div>
    </div>
  );
};

export default React.memo(RangeChart);
