// import { Router } from "next/router";
import React from "react";
// import { withRouter, NextRouter } from "next/router";
import Button from "components/atoms/Button";
import * as Sentry from "@sentry/nextjs";
import { DEFAULT_PORTAL_NAME } from "utils/constants";

const getInitialRoute = () => {
  if (typeof window !== "undefined") {
    const splittedPath = window.location.pathname.split("/");
    const initialRoute = splittedPath?.[1];
    if (initialRoute === "subscription" || initialRoute === "admin") {
      return DEFAULT_PORTAL_NAME;
    }

    return initialRoute;
  }

  return DEFAULT_PORTAL_NAME;
};

interface ErrorBoundaryProps {
  component?: React.FC<any>;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.log({ error, errorInfo });
    Sentry.captureException(error, errorInfo);
  }

  resetErrorState = () => {
    this.setState({ hasError: false });
  };

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      if (this.props.component) {
        return this.props.component({ resetErrorState: this.resetErrorState });
      }

      return (
        <div className="w-screen h-screen flex flex-col items-center justify-center">
          <h2 className="text-2xl mb-4">Oops, there is an error!</h2>

          <Button
            onClick={() => {
              window.location = `/${getInitialRoute()}`;
            }}
          >
            Go back
          </Button>
        </div>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
