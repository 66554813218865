import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { clientInitConfig } from "firebaseAuth.config";

const firebaseApp = initializeApp(clientInitConfig);

export const useAuth = () => {
  const auth = getAuth(firebaseApp);
  return auth;
};
