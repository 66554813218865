import { createContext, useContext } from "react";
import { useRouter } from "next/router";
import axios from "axios";
import { useQuery } from "react-query";
import { useUser } from "./UserContext";
import { DEFAULT_PORTAL_NAME } from "utils/constants";
import Portal from "models/Portal";
import { useErrorHandler } from "hooks/useErrorHandler";
import { error, warning } from "utils/toast";
import useTranslation from "next-translate/useTranslation";
import { isAuthorizedRoutes } from "utils/isAuthorizedRoutes";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { refreshToken } from "utils/refreshToken";
import { useMemo } from "react";
import { isInternalDomain } from "utils/isInternalDomain";
import { IPortalLimits } from "types";

interface IPortalContext {
  userPortal?: Portal;
  ownerPortal?: Portal;
  urlPortal?: Portal;
  isLoading: boolean;
  portalName: string;
  isPortal: boolean;
  isDefaultName: boolean;
  refetchUserPortal: () => void;
  userPortalFetched: boolean;
  urlPortalFetched: boolean;
  [key: string]: any;
}

const initialValues = {
  isLoading: false,
  refetchUserPortal: () => {},
  isDefaultName: true,
  isPortal: false,
  userPortalFetched: false,
  urlPortalFetched: false,
  portalName: DEFAULT_PORTAL_NAME,
};

export const getPortalByPortalUrlSlug = async (
  portalName: string
): Promise<Portal> => {
  try {
    return await axios
      .request({
        method: "GET",
        url: `${process.env.NEXT_PUBLIC_API_HOST}/portals/get-by-domain/${portalName}`,
      })
      .then((res) => res.data);
  } catch (error) {
    console.log("portals error", error);
    return Promise.reject(error);
  }
};

const getUserPortalByDomain = async (
  userToken: string,
  portalName: string
): Promise<Portal> => {
  try {
    return await axios
      .request({
        headers: { Authorization: `Bearer ${userToken}` },
        method: "GET",
        url: `${process.env.NEXT_PUBLIC_API_HOST}/portals/my/get-by-domain/${portalName}`,
      })
      .then((res) => res.data);
  } catch (error) {
    console.log("user portal error", error);
    return Promise.reject(error);
  }
};

const getMyOwnPortal = async (userToken: string): Promise<Portal> => {
  try {
    return await axios.request({
      headers: { Authorization: `Bearer ${userToken}` },
      method: "GET",
      url: `${process.env.NEXT_PUBLIC_API_HOST}/portals/my/own`,
    });
  } catch (error) {
    console.log("own portal error", error);
    return Promise.reject(error);
  }
};

const getPortalLimits = async (
  userToken: string,
  portalId: number
): Promise<IPortalLimits> => {
  try {
    return await axios
      .request({
        headers: { Authorization: `Bearer ${userToken}`, portalId },
        method: "GET",
        url: `${process.env.NEXT_PUBLIC_API_HOST}/portals/current/portal-limits`,
      })
      .then((res) => res.data);
  } catch (error) {
    console.log("portal limits error");
    return Promise.reject(error);
  }
};

const PortalContext = createContext<IPortalContext>(initialValues);

export const usePortal = () => useContext(PortalContext);

const PortalProvider: React.FC = ({ children }) => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const { portalName = DEFAULT_PORTAL_NAME } = router.query;
  const { userToken, user, signOut, hasAbilityToOwnPortal, auth } = useUser();
  const { handleError } = useErrorHandler();
  const queryClient = useQueryClient();

  const isDefaultName = !!portalName && portalName === DEFAULT_PORTAL_NAME;
  const isPortal = !!portalName && portalName !== DEFAULT_PORTAL_NAME;

  const portalQuery = useMemo(() => {
    if (isPortal && !isInternalDomain) {
      return window.location.host;
    }

    return portalName;
  }, [isPortal, portalName]);

  useEffect(() => {
    if (!isPortal) {
      queryClient.removeQueries("user-portal");
    }
  }, [isPortal]);

  const { data: urlPortal, isFetched: urlPortalFetched } = useQuery<Portal>(
    ["url-portal", portalQuery],
    () => getPortalByPortalUrlSlug(portalQuery as string),
    {
      enabled: !!portalQuery && isPortal,
      onError(err: any) {
        if (err?.response?.status === 404) {
          setTimeout(() => {
            warning(
              { message: t("portal_not_existed", { portalName: portalQuery }) },
              { autoClose: false }
            );
          }, 200);
          router.replace(`/${DEFAULT_PORTAL_NAME}`);
        } else {
          handleError({ err });
        }
      },
    }
  );

  const { data: ownerPortal, refetch: refetchOwnerPortal } = useQuery<Portal>(
    ["owner-portal", user, portalQuery],
    () => getMyOwnPortal(userToken as string).then(({ data }: any) => data),
    {
      enabled:
        !!userToken &&
        !!user &&
        hasAbilityToOwnPortal &&
        isAuthorizedRoutes(router),
      refetchOnMount: true,
      async onError(err: any) {
        if (err.response.status === 401 && !err?.response?.data?.noRestore) {
          return await refreshToken({
            userToken,
            queryClient,
            auth,
            error: err,
            onSuccess() {
              refetchOwnerPortal();
            },
          });
        }
      },
    }
  );

  const {
    data: userPortal,
    refetch: refetchUserPortal,
    isFetched: userPortalFetched,
    isLoading,
  } = useQuery<Portal>(
    ["user-portal", portalQuery, user?.id],
    () => getUserPortalByDomain(userToken as string, portalQuery as string),
    {
      enabled:
        !!userToken &&
        !!user &&
        !!portalQuery &&
        !isDefaultName &&
        isAuthorizedRoutes(router),
      refetchOnMount: true,
      async onError(err: any) {
        if (err.response.status === 401) {
          if (err?.response?.data?.noRestore) {
            handleError({ err });
            return signOut();
          }

          return await refreshToken({
            userToken,
            queryClient,
            auth,
            error: err,
            onSuccess() {
              refetchUserPortal();
            },
          });
        }
      },
    }
  );

  useQuery<IPortalLimits>(
    ["portal-limits", userPortal?.id, userToken],
    () => getPortalLimits(userToken as string, userPortal?.id!),
    {
      enabled:
        !!userPortal && !!userToken && !!user && isAuthorizedRoutes(router),
      onSuccess(portalLimits) {
        if (!portalLimits.ownPortal) {
          error({ message: "The workspace doesn't have active subscription" });
          signOut();
        }
      },
    }
  );

  return (
    <PortalContext.Provider
      value={{
        userPortal,
        urlPortal,
        isPortal,
        portalName: portalName as string,
        isDefaultName,
        refetchUserPortal,
        ownerPortal,
        userPortalFetched,
        urlPortalFetched,
        isLoading,
      }}
    >
      {children}
    </PortalContext.Provider>
  );
};

export default PortalProvider;
