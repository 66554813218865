import React from "react";
import ReactModal from "react-modal";
import cn from "classnames";
import Close from "public/icons/close.svg";

export interface IModalProps extends ReactModal.Props {
  onClose?: any;
  contentClassName?: string;
  closeButtonContainerClassName?: string;
}

const Modal = ({
  overlayClassName,
  className,
  onClose,
  children,
  contentClassName,
  closeButtonContainerClassName,
  shouldCloseOnOverlayClick = true,
  ...rest
}: IModalProps) => {
  return (
    <ReactModal
      {...(shouldCloseOnOverlayClick && { onRequestClose: onClose })}
      className={cn(
        "bg-white relative overflow-y-auto max-h-[90dvh] rounded-2xl m-6",
        className
      )}
      overlayClassName={cn(
        "bg-gray-400/80 h-[100dvh] fixed inset-0 z-50 flex items-center justify-center webkit-fill-available",
        overlayClassName
      )}
      {...rest}
    >
      <div
        className={cn(
          "modal-content relative p-8 overflow-x-hidden",
          contentClassName
        )}
      >
        {onClose && (
          <div
            className={cn(
              "absolute top-4 right-4",
              closeButtonContainerClassName
            )}
          >
            <button
              onClick={onClose}
              className="flex items-center justify-center hover:scale-110"
            >
              <Close />
            </button>
          </div>
        )}
        {children}
      </div>
    </ReactModal>
  );
};

export default Modal;
