import { useCallback, useEffect, useRef, useState } from "react";
import debounce from "lodash/debounce";
import { useDidUpdateEffect } from "./useDidUpdateEffect";

const useDebouncedState = (initialValue: any, debounceTime = 200) => {
  const [syncValue, setSyncValue] = useState(initialValue);
  const [debouncedValue, setDebouncedValue] = useState(initialValue);

  const _debouncedSearch = useCallback(
    debounce(setDebouncedValue, debounceTime),
    []
  );

  useDidUpdateEffect(() => {
    _debouncedSearch(syncValue);
  }, [syncValue]);

  const setValueWithNoDebounce = (value: any) => {
    setDebouncedValue(value);
    setSyncValue(value);
  };

  return [syncValue, setSyncValue, debouncedValue, setValueWithNoDebounce];
};

export default useDebouncedState;
