export const materials = [
  "polyester",
  "leather",
  "wool",
  "elastane",
  "rubber",
  "spandex",
  "jersey",
  "nylon",
  "silk",
  "viscose",
  "polyamide",
  "down",
  "denim",
  "cashmere",
  "fleece",
  "suede",
  "linen",
  "acetate",
  "rayon",
  "canvas",
  "metallic",
  "cotton-blend",
  "synthetic",
  "glass",
  "crepe",
  "cupro",
  "merino wool",
  "calfskin",
  "ash",
  "metal",
  "vamp",
  "velvet",
  "brass",
  "diamond",
  "ethylene vinyl acetate",
  "shearling",
  "lambskin",
  "fur",
  "lyocell",
  "mohair wool",
  "tulle",
  "stone",
  "pvc",
  "alpaca",
  "flannel",
  "abs",
  "lycra",
  "gore-tex",
  "faux fur",
  "textile",
  "resin",
  "thermoplastic polyurethane",
  "eva",
  "taffeta",
  "felt",
  "lurex",
  "silicone",
  "plastic",
  "memory foam",
  "stainless steel",
  "velour",
  "neoprene",
  "nubuck leather",
  "sheepskin",
  "hemp",
  "wood",
  "enamel",
  "hardwood",
  "snakeskin",
  "tricot",
  "thermoplastic rubber",
  "bamboo",
  "jute",
  "heatgear® fabric",
  "ceramic",
  "flax",
  "econyl",
  "paper",
  "faux suede",
  "pine",
  "faille",
  "polyvinyl chloride",
  "oak",
  "molded rubber",
  "chrome",
  "goatskin",
  "bovine leather",
  "polybutylene terephthalate",
  "porcelain",
  "full grain leather",
  "mink",
  "marble",
  "modacrylic",
  "crêpe",
  "thermoplastic",
  "lamb fur",
  "shirting",
  "ripstop nylon",
  "acrylonitrile butadiene styrene",
  "angora wool",
  "tps",
  "blends",
  "terry cotton",
  "tpu",
  "other",
  "elasterell",
  "thermoplastic copolyesters",
  "heatseeker",
  "birch",
  "concrete",
  "rattan",
  "cotton mix",
  "vulcanised rubber",
  "tpr",
  "arctic-tech",
  "climacool",
  "climalite",
  "merino",
  "pashmina",
  "wool blend",
  "airflux",
  "polyolefin",
  "modal fabric",
  "tpo",
  "crape",
  "tpe",
  "cordovan",
  "acrylic fabric",
  "teak",
  "beech",
  "melamine",
  "styrene",
  "solid metal",
  "thermaflex",
  "climawarm",
  "sorbtek",
  "cast iron",
  "karung",
  "liquids",
  "nubuck",
  "copper",
  "magnetic metal",
  "marceline",
  "solid wood",
  "walnut wood",
  "recycled",
  "fustian",
  "memory eva",
  "satin",
  "acacia wood",
  "mohair",
  "glass fibre",
  "lace",
  "barkcloth",
  "agion",
  "buckskin",
  "other natural fibre",
  "hi-traction rubber",
  "polypropylene fabric",
  "woolskin",
  "mango wood",
  "organic cotton",
  "percale cotton",
  "funnel",
  "italian fabric",
  "wellsoft",
  "white bone china",
  "woolblend",
  "polo neck",
  "pbt",
  "cashmere blend",
];
