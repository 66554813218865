import React from "react";
import { usePopperTooltip } from "react-popper-tooltip";
import cn from "classnames";
import ReactPortal from "./ReactPortal";

interface ITooltipProps {
  delay?: number;
  content: any;
  direction?: "top" | "bottom" | "left" | "right";
  className?: string;
  oneline?: boolean;
  disabled?: boolean;
  folowCursor?: boolean;
  offset?: [number, number];
  arrowHidden?: boolean;
}

const Tooltip: React.FC<ITooltipProps> = ({
  folowCursor = true,
  offset = [0, 20],
  arrowHidden,
  ...props
}) => {
  const {
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
    getArrowProps,
  } = usePopperTooltip({
    placement: props.direction,
    followCursor: folowCursor,
    offset,
  });

  return (
    <>
      <div ref={setTriggerRef}>{props.children}</div>

      {visible && !props.disabled && !!props.content && (
        <ReactPortal className="z-50">
          <div
            ref={setTooltipRef}
            {...getTooltipProps({
              className: cn(
                `tooltip-container text-xs font-normal normal-case !py-1 !px-2`,
                {
                  "whitespace-nowrap":
                    props.oneline === undefined
                      ? props.content?.length < 20
                      : props.oneline,
                  [`w-[300px]`]: !props.oneline && props.content?.length > 20,
                },
                props.className
              ),
            })}
          >
            {!arrowHidden && (
              <div {...getArrowProps({ className: "tooltip-arrow" })} />
            )}
            {props.content}
          </div>
        </ReactPortal>
      )}
    </>
  );
};

export default Tooltip;
