import { useEffect, useMemo, useState } from "react";
import useTranslation from "next-translate/useTranslation";
import cn from "classnames";
// hooks
import { genderOptions } from "hooks/useCategoryOptions";
// components
import CustomButton from "../CustomButton";
import FilterPillboxBtn from "./FilterPillboxBtn";
import { BasecatOptions } from "components/molecules/AIDesign/Benchmark/BenchmarkFilter";
import Icon from "../Icon";
import CheckBox from "../CheckBox";
//utils
import { ExploreTrendsGender, TrendTypes } from "utils/constants";
// types
import { SelectOption } from "types";
// lodush
import { capitalize } from "lodash";
// icons
import ChevronDownIcon from "public/icons/chevron_down_16.svg";
import CloseIcon from "public/icons/close_16.svg";
import SearchIcon from "public/icons/search_16.svg";
import InfoIcon from "public/icons/info_circle.svg";
import { beautifyBasecat } from "utils/beautify";
import { compareString } from "utils/compareString";

export const GenderOptions = Object.entries(ExploreTrendsGender).map(
  ([key, value]: string[]) => ({
    name: value,
    value: key,
  })
);

export const SeasonOptions = [
  {
    name: "All",
    value: "",
  },
  ...TrendTypes.map((item) => ({
    name: item.value,
    value: item.value,
  })),
];

export const categories = [
  "coats_jackets",
  "activewear",
  "bottoms",
  "denim",
  "dresses",
  "jumpsuits",
  "knitwear",
  "nightwear",
  "outdoor",
  "sets",
  "shirts",
  "skirts",
  "sweatshirts_hoodies",
  "swimwear",
  "tops",
  "underwear",
];

export const CategoryOptions = categories
  .sort((a, b) => compareString(a, b))
  .map((value) => ({
    name: capitalize(beautifyBasecat(value)),
    value,
  }));

export const ReachOptions = [
  {
    name: "Major",
    value: "major",
  },
  {
    name: "Medium",
    value: "medium",
  },
  {
    name: "Minor",
    value: "minor",
  },
];

export const TypeOptions = [
  {
    name: "All",
    value: "",
  },
  {
    name: "Upward",
    value: "upward",
  },
  {
    name: "Downward",
    value: "downward",
  },
];

interface FilterPanelProps {
  type?: SelectOption;
  reach?: SelectOption[];
  gender?: SelectOption;
  season?: SelectOption;
  category?: SelectOption;
  onReset?: Function;
  onApply: Function;
}

const FilterPanel: React.FC<FilterPanelProps> = ({
  type,
  reach,
  gender,
  season,
  onApply,
  onReset,
  category,
}) => {
  const { t } = useTranslation("common");

  const defaultCategoryValue = BasecatOptions[2];

  const [tempGender, setTempGender] = useState<SelectOption>(GenderOptions[0]);
  const [tempCategory, setTempCategory] =
    useState<SelectOption>(defaultCategoryValue);
  const [tempSeason, setTempSeason] = useState<SelectOption>();
  const [tempReach, setTempReach] = useState<SelectOption[]>([ReachOptions[0]]);
  const [tempType, setTempType] = useState<SelectOption>();

  useEffect(() => {
    if (gender && gender.value !== tempGender?.value) {
      setTempGender(gender);
    }
  }, [gender]);

  useEffect(() => {
    if (category && category.value !== tempCategory?.value) {
      setTempCategory(category);
    }
  }, [category]);

  useEffect(() => {
    if (season && season.value !== tempSeason?.value) {
      setTempSeason(season);
    }
  }, [season]);

  useEffect(() => {
    const reachStr = reach?.map((item) => item.value).join("/");
    const tempReachStr = tempReach?.map((item) => item.value).join("/");
    if (reach && reachStr !== tempReachStr) {
      setTempReach(reach);
    }
  }, [reach]);

  useEffect(() => {
    if (type && type.value !== tempType?.value) {
      setTempType(type);
    }
  }, [type]);

  const onSelectReach = (selectedItem: SelectOption) => {
    if (selectedItem.value === "") {
      setTempReach([]);
    } else {
      if (!!tempReach?.find((item) => item.value === selectedItem.value)) {
        setTempReach(
          tempReach?.filter((item) => item.value !== selectedItem.value)
        );
      } else {
        setTempReach([...tempReach, selectedItem]);
      }
    }
  };

  const handleSearch = (value?: string, source?: string) => {
    const data =
      value && source
        ? {
            [source.toLowerCase()]: value,
          }
        : {};

    onApply({
      gender: tempGender?.value,
      basecat: tempCategory?.value,
      season: tempSeason?.value,
      reach: tempReach.map((item) => item.value).join("/"),
      type: tempType?.value,
      ...data,
    });
  };

  const handleReset = () => {
    onReset &&
      onReset({
        gender: GenderOptions[0]?.value,
        basecat: defaultCategoryValue.value,
        reach: [ReachOptions[0].value],
      });
  };

  return (
    <div className="w-full rounded-[20px] border border-border-normal bg-white z-50">
      <div className="flex flex-col gap-4 w-full px-6 pb-6 pt-4">
        <FilterSection
          name={t("simple_gender")}
          selectedItem={tempGender?.value}
          options={GenderOptions}
          onClick={(value) => setTempGender(value)}
        />
        <FilterSection
          name={capitalize(t("category"))}
          selectedItem={tempCategory?.value}
          options={CategoryOptions}
          onClick={(value) => setTempCategory(value)}
        />
        <FilterSection
          name={t("season")}
          options={SeasonOptions}
          selectedItem={tempSeason?.value}
          onClick={(value) => setTempSeason(value)}
        />
        <FilterSection
          name={t("type")}
          options={TypeOptions}
          selectedItem={tempType?.value}
          onClick={(value) => setTempType(value)}
        />
        <div className="w-full flex flex-col gap-2">
          <div className="flex items-center justify-start gap-2">
            <div className="text-sm leading-tight font-medium text-heading first-letter:uppercase">
              {t("reach")}
            </div>
            <div className="relative group">
              <Icon
                component={InfoIcon}
                viewBox="0 0 20 20"
                width={16}
                height={16}
                className="stroke-gray-50"
              />
              <div className="absolute hidden group-hover:block top-full translate-x-1/2 right-1/2 rounded-[4px] text-white bg-gray-950 w-[240px] z-50 px-2 py-0.5 text-xs">
                {t("reach_description")}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-start flex-wrap gap-10">
            {ReachOptions.map((item) => (
              <CheckBox
                checked={tempReach
                  .map((item) => item.value)
                  .includes(item.value)}
                label={item.name}
                onClick={() => onSelectReach(item)}
                width={16}
                height={16}
                color={"#1F1F1F"}
                labelClassName="!text-xs"
              />
            ))}
          </div>
        </div>
        <div className="flex items-center justify-end gap-2">
          <CustomButton
            label={t("reset")}
            variant="tertiary"
            onClick={handleReset}
            size="small"
          />
          <CustomButton
            label={capitalize(t("apply"))}
            variant="primary"
            onClick={handleSearch}
            size="small"
          />
        </div>
      </div>
    </div>
  );
};

export default FilterPanel;

interface FilterSectionProps {
  name: string;
  options: SelectOption[];
  selectedItem?: string;
  onClick: (value: SelectOption) => void;
  isInclude?: boolean;
}

const FilterSection = ({
  name,
  options,
  onClick,
  selectedItem,
  isInclude = false,
}: FilterSectionProps) => {
  return (
    <div className="w-full flex flex-col gap-2">
      <div className="text-sm leading-tight font-medium text-heading first-letter:uppercase">
        {name}
      </div>
      <div className="flex items-center justify-start flex-wrap gap-2">
        {options.map((item) => (
          <FilterPillboxBtn
            key={item.value}
            label={item.name}
            active={
              (item.value === "" && !selectedItem) ||
              (item.value !== "" && isInclude
                ? selectedItem?.includes(item.value)
                : selectedItem === item.value)
            }
            onClick={() => onClick(item)}
          />
        ))}
      </div>
    </div>
  );
};
