import AbstractApi from "./AbstractApi";
import View from "models/View";
import { QueryClient } from "react-query";

export default class ViewsApi extends AbstractApi {
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
  }

  createView = async (projectId: number) => {
    return this.client.post(`/views`, {
      projectId,
    }) as Promise<View>;
  };
}
