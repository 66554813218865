import axios from "axios";

const BASE_URL = process.env.NEXT_PUBLIC_API_HOST;

export const getIpLocation = () => {
  try {
    return axios
      .request({
        method: "GET",
        baseURL: `${BASE_URL}/ip-locations/lookup`,
      })
      .then((res) => res.data);
  } catch (error) {
    console.log("ip-location failed", error);
  }
};
