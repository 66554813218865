import create from "zustand";
import { persist } from "zustand/middleware";

export type TutorialState = {
  tutorialOpen: boolean;
  setTutorialOpen: (open: boolean) => void;
  handleOpenTutorial: () => void;
  handleCloseTutorial: () => void;
};

const persistOpts = {
  name: "tutorial-state-store",
};

export const useTutorialStateStore = create(
  persist<TutorialState>(
    (set) => ({
      tutorialOpen: false,
      setTutorialOpen: (open) => set(() => ({ tutorialOpen: open })),
      handleOpenTutorial: () => set(() => ({ tutorialOpen: true })),
      handleCloseTutorial: () => set(() => ({ tutorialOpen: false })),
    }),
    persistOpts
  )
);
