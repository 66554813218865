import isEmpty from "lodash/isEmpty";

export const getComplexFilters = (
  filters: string[],
  filterName: string,
  notFirst?: boolean
) => {
  let query = "";

  if (filters?.length) {
    filters.forEach((filter, i) => {
      const str = `${filterName}=${filter}`;

      if (notFirst || i) {
        query += `&${str}`;
      } else {
        query += `?${str}`;
      }
    });
  }

  return query;
};

export const getQueryStrFormParams = (params: Record<string, any>) => {
  let query = "";

  if (!isEmpty(params)) {
    Object.entries(params).forEach(([key, val], index) => {
      const el = `${key}=${val}`;
      if (index) {
        query += `&${el}`;
      } else {
        query += `?${el}`;
      }
    });
  }

  return query;
};
