import { AxiosResponse } from "axios";
import AbstractApi from "./AbstractApi";
import { TVGarment } from "models/TvGarment";
import { TvChannel } from "models/TvChannel";
import { QueryClient } from "react-query";

export interface AIDesignProjectMoodboardType {
  id: number;
  tvGarmentId: number;
  channelId: number;
  tvProjectId: number;
  createdat: string;
  updatedat: string;
  tvGarment: TVGarment;
}

export interface AIDesignProjectType {
  id: number;
  name: string;
  createdById: number;
  createdat: string;
  projectDataUpdatedAt: string;
  moodboardImagesCount?: number;
  previewUrls?: string[];
  teamId: number;
  channels?: TvChannel[];
}

export interface CreateAIDesignProjectPayload {
  teamId: number;
  name: string;
}

export interface AIDesignProjectPayload {
  teamId: number;
  tvProjectId: number;
}

export interface DeleteAIDesignProjectPayload {
  teamId: number;
  tvProjectId: number;
}

export interface UpdateAIDesignProjectPayload {
  teamId: number;
  tvProjectId: number;
  name: string;
}

export interface InvitationPayload {
  teamId: number;
  inviteEmails: string[];
}

export default class AIDesignProjectApi extends AbstractApi {
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
  }

  async createProject(
    payload: CreateAIDesignProjectPayload
  ): Promise<AxiosResponse<any>> {
    return await this.client.post(`/tv-projects/my`, payload);
  }

  async InviteMember(
    id: number,
    payload: InvitationPayload
  ): Promise<AxiosResponse<AIDesignProjectType[]>> {
    return await this.client.post(`/tv-projects/${id}/invite`, payload);
  }

  async getProjects(
    teamid: number
  ): Promise<AxiosResponse<AIDesignProjectType[]>> {
    return await this.client.get(`/tv-projects/my`, {
      params: {
        teamid,
      },
    });
  }

  async getProjectMoodboard(
    teamid: number,
    tvProjectId: number
  ): Promise<AxiosResponse<AIDesignProjectMoodboardType[]>> {
    return await this.client.get(`/tv-projects/my/${tvProjectId}/moodboard`, {
      params: {
        teamid,
      },
    });
  }

  async updateProjectName(
    payload: UpdateAIDesignProjectPayload
  ): Promise<AxiosResponse<AIDesignProjectType[]>> {
    return await this.client.patch(`/tv-projects/my/${payload.tvProjectId}`, {
      name: payload.name,
    });
  }

  async deleteProject(
    payload: DeleteAIDesignProjectPayload
  ): Promise<AxiosResponse<AIDesignProjectType[]>> {
    return await this.client.delete(`/tv-projects/my/${payload.tvProjectId}`, {
      params: {
        teamId: payload.teamId,
      },
    });
  }

  async checkInvite(token: string): Promise<AIDesignProjectType> {
    return await this.client.get(`/tv-projects/invite/${token}`);
  }

  async checkPortalInvite(
    token: string,
    invitationCode: string
  ): Promise<AIDesignProjectType> {
    return await this.client.get(
      `/tv-projects/invite/${token}/${invitationCode}`
    );
  }

  async removeGuestFromTvProject({
    projectId,
    userId,
  }: {
    projectId: number;
    userId: number;
  }) {
    return await this.client.delete(
      `/tv-projects/${projectId}/removeGuest/${userId}`
    );
  }

  async resendTvProjectInvite(projectId: number, email: string) {
    return await this.client.post(`/tv-projects/${projectId}/resend-invite`, {
      email,
    });
  }

  async getTvProjectById(id: number): Promise<AIDesignProjectType> {
    return await this.client.get(`/tv-projects/${id}`);
  }

  async getMoodboards() {
    return await this.client
      .get("/tv-projects/my/moodboards")
      .then((res) => res?.data);
  }

  async getSharedMoodboards(params: { teamId: number; projectIds?: string }) {
    return await this.client.get(`/tv-projects/shared/moodboards`, { params });
  }
}
