import React from "react";

export interface IconProps {
  component: any;
  viewBox?: string;
  width?: number | string;
  height?: number | string;
  className?: string;
  stroke?: any;
  fill?: any;
  onClick?: any;
  preserveAspectRatio?: string;
  style?: any;
}

const Icon = ({ component: Component, ...rest }: IconProps) => {
  if (!Component) {
    return null;
  }

  return <Component viewBox="0 0 24 24" {...rest} />;
};

export default Icon;
