import Project, { Monitor } from "models/Project";
import { QueryClient } from "react-query";
import { MonitoringFrequency, PaginatedResponse } from "types";
import AbstractApi from "./AbstractApi";

export interface ICreateMonitorPayload {
  projectId: number;
  businessComponentId?: number;
  userId?: number;
  frequency?: MonitoringFrequency;
}

export interface IMonitorFrequencyByUsersPayload {
  userIds: number[];
  projectId: number;
  businessComponentId?: number;
  frequency: MonitoringFrequency;
}

export interface ICreateManyMonitorsPayload {
  ids: number[];
  projectId: number;
  businessComponentId?: number;
  monitorFrequency: MonitoringFrequency;
}

export default class MonitorApi extends AbstractApi {
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
  }

  getAll = (params: {
    page?: number;
    limit?: number;
    search?: string;
  }): Promise<PaginatedResponse<Project>> => {
    return this.client.get("/monitor", { params });
  };

  getProjectMonitor = (params: {
    projectId: number;
    businessComponentId: number;
    userId?: number;
  }) => {
    const { projectId, businessComponentId, userId } = params;
    return this.client.get(
      `/monitor/project?projectId=${projectId}&businessComponentId=${businessComponentId}&userId=${userId}`
    );
  };

  getAllProjectMonitors = (params: {
    projectId: number;
  }): Promise<Monitor[]> => {
    const { projectId } = params;
    return this.client.get(`/monitor/project-all?projectId=${projectId}`);
  };

  create = (body: ICreateMonitorPayload) => {
    return this.client.post("/monitor/create", body);
  };

  createMany = (payload: ICreateManyMonitorsPayload) => {
    return this.client.post("/monitor/create-many", payload);
  };

  remove = (body: ICreateMonitorPayload) => {
    return this.client.post("/monitor/remove", body);
  };

  createProjectAllMonitors = (body: any) => {
    return this.client.post("/monitor/createProjectAllMonitors", body);
  };

  removeProjectManyMonitors = (body: {
    projectId: number;
    userIds?: number[];
  }) => {
    return this.client.post("/monitor/remove-project-many-monitors", body);
  };

  removeAllMonitors = (body: any) => {
    return this.client.post("/monitor/removeAllMonitors", body);
  };

  changeMonitorFrequencyByUserIds = (
    payload: IMonitorFrequencyByUsersPayload
  ) => this.client.patch("/monitor/users-monitor-frequency", payload);

  changeMonitorFrequency = ({
    projectId,
    monitorFrequency,
    userId,
  }: {
    projectId: number;
    monitorFrequency: string;
    userId?: number;
  }) =>
    this.client.patch(`/monitor/monitor-frequency`, {
      monitorFrequency,
      projectId,
      userId,
    });

  sendSample = (payload: {
    frequency: MonitoringFrequency;
    projectId: number;
    ids: number[];
  }) => this.client.post(`/monitor/send`, payload);
}
