import React, { useCallback, useEffect, useRef } from "react";
import cn from "classnames";
import { PriceRange } from "components/molecules/PriceRangeChartPopover";

interface MultiRangeSliderProps {
  min: number;
  max: number;
  rangeValues: PriceRange;
  onChange: (value: PriceRange) => void;
  step?: number;
}

const MultiRangeSlider = ({
  min,
  max,
  rangeValues,
  onChange,
  step,
}: MultiRangeSliderProps) => {
  const minValRef = useRef<HTMLInputElement | null>(null);
  const maxValRef = useRef<HTMLInputElement | null>(null);
  const range = useRef<HTMLDivElement | null>(null);

  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(rangeValues.min);
      const maxPercent = getPercent(+maxValRef.current.value);

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [rangeValues.min, getPercent]);

  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(rangeValues.max);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [rangeValues.max, getPercent]);

  return (
    <div className="relative w-full h-4 flex items-center justify-center">
      <input
        type="range"
        min={min}
        max={max}
        value={rangeValues.min}
        ref={minValRef}
        onChange={(event) => {
          const value = Math.min(+event.target.value, rangeValues.max - 1);
          onChange({ min: value, max: rangeValues.max });
          event.target.value = value.toString();
        }}
        className={cn("thumb thumb--zindex-3", {
          "thumb--zindex-5": rangeValues.min > max - 100,
        })}
        step={step}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={rangeValues.max}
        ref={maxValRef}
        onChange={(event) => {
          const value = Math.max(+event.target.value, rangeValues.min + 1);
          onChange({ min: rangeValues.min, max: value });
          event.target.value = value.toString();
        }}
        className="thumb thumb--zindex-4"
        step={step}
      />

      <div className="slider">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
      </div>
    </div>
  );
};

export default MultiRangeSlider;
