import { Supplier } from "models/Supplier";
import { QueryClient } from "react-query";
import {
  IPaginateParams,
  ITimezone,
  KeywordType,
  PaginatedResponse,
} from "types";
import AbstractApi from "./AbstractApi";

const API_ROUTE = "/suppliers";

export interface ICreateSupplierPayload {
  name: string;
  description?: string;
  countryNotes?: string;
  workingHoursStart?: string | null;
  workingHoursEnd?: string | null;
  timeZone?: ITimezone | null;
  isFinancingAbility?: boolean;
  financingAbilityDescription?: string;
  keywordSet?: KeywordType[];
}

export default class SupplierApi extends AbstractApi {
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
  }

  async getSuppliers(
    params: IPaginateParams
  ): Promise<PaginatedResponse<Supplier>> {
    return await this.client.get(`${API_ROUTE}`, { params });
  }

  async getCurrentSupplier(): Promise<Supplier> {
    return await this.client.get(`${API_ROUTE}/current`);
  }

  async getSupplierById(id: number): Promise<Supplier> {
    return await this.client.get(`${API_ROUTE}/${id}`);
  }

  async createSupplier(payload: ICreateSupplierPayload): Promise<Supplier> {
    return await this.client.post(`${API_ROUTE}`, payload);
  }

  async updateSupplier(
    payload: ICreateSupplierPayload & { id: number }
  ): Promise<Supplier> {
    const { id, ...rest } = payload;
    return await this.client.patch(`${API_ROUTE}/${id}`, rest);
  }

  async deleteSupplier(id: number) {
    return await this.client.delete(`${API_ROUTE}/${id}`);
  }
}
