import Template from "models/Template";
import { QueryClient } from "react-query";
import AbstractApi from "./AbstractApi";

export interface GetTemplatesDTO {
  data: Template[];
  hasNextPage?: boolean;
}
export default class TemplatesApi extends AbstractApi {
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
  }

  getTemplates = async ({
    name,
    description,
    tags = [],
    limit,
    page,
    orderBy = "name",
    orderDirection,
    published,
  }: {
    name?: string;
    description?: string;
    tags?: string[];
    limit?: number;
    page?: number;
    orderBy?: string;
    orderDirection?: string;
    published?: boolean;
  }) => {
    const templatesRes = await this.client.get("/templates", {
      params: {
        page,
        limit,
        orderBy,
        orderDirection,
        tags,
        name,
        description,
        published,
      },
    });

    return templatesRes as unknown as Promise<GetTemplatesDTO>;
  };

  getTemplateById = async (templateId: string) => {
    return this.client.get(`/templates/${templateId}`) as Promise<Template>;
  };

  createTemplate = async (template: Template) => {
    return this.client.post("/templates", template);
  };

  updateTemplate = async ({
    templateId,
    templatePart,
  }: {
    templateId: number;
    templatePart: Partial<Template>;
  }) => {
    return this.client.patch(`/templates/${templateId}`, templatePart);
  };

  deleteTemplate = async (templateId: number) => {
    return this.client.delete(`/templates/${templateId}`);
  };
}
