import React from "react";
import cn from "classnames";

interface SkeletonLoadingProps {
  height?: string | number;
  width?: string | number;
  className?: string;
}

const SkeletonLoading = ({
  className,
  height,
  width,
}: SkeletonLoadingProps) => {
  return (
    <div
      className={cn("loading rounded-md", className)}
      style={{ height, width }}
    ></div>
  );
};

export default SkeletonLoading;
