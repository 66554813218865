export const isDev = () => {
  return (
    process.env.NEXT_PUBLIC_ENV === "local" ||
    process.env.NEXT_PUBLIC_ENV === "development"
  );
};

export const isStaging = () => {
  return process.env.NEXT_PUBLIC_ENV === "staging";
};

export const isProduction = () => {
  return process.env.NEXT_PUBLIC_ENV === "production";
};
