import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Auth,
  User as FirebaseUser,
  getAuth,
  signOut,
  onIdTokenChanged,
} from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";

import FullScreenSpinner from "components/organisms/FullScreenSpinner/FullScreenSpinner";
import { useQuery } from "react-query";
import { useRouter } from "next/router";
import { useAuth } from "hooks/useAuth";
import Team from "models/Team";
import useTranslation from "next-translate/useTranslation";
import { AppRoleEnum, CreationFlow, PlanNames } from "types";
import {
  CommentsEmailNotificationOption,
  DEFAULT_PORTAL_NAME,
  LANGUAGES,
} from "utils/constants";
import setLanguage from "next-translate/setLanguage";
import { Stripe } from "stripe";
import axios from "axios";
import isUndefined from "lodash/isUndefined";
import { isAuthorizedRoutes } from "utils/isAuthorizedRoutes";
import { isPlan } from "utils/isPlan";
import { useQueryClient } from "react-query";
import { refreshToken } from "utils/refreshToken";

export interface IUserInfo {
  email: string;
  avatarColor: string;
  name: string | null;
  location: string | null;
  locale: string | null;
  jobPosition: string | null;
  organizationType: string | null;
  organizationName: string | null;
  team: Team;
  id: number;
  appRole: number;
  limitsExtension: Record<string, any>;
  stripeSubscriptionId: string | null;
  stripeSubscriptionStatus: string;
  creationFlow: CreationFlow;
  stripePlanName: PlanNames;
  eligibleForTrial: boolean;
  allowMonitoringEmailNotifications: boolean;
  allowGeneralEmailNotifications: boolean;
  currentTeam: number;
  registrationSource: string | null;
  ipLocation: string;
  photoUrl?: string;
  commentsEmailNotification: CommentsEmailNotificationOption;
  allowMessengerNotifications: boolean;
  isMarketAnalyticsEnabled: boolean;
  registeredAt: string;
  isOrdersEnabled: boolean;
  isAIMaskingEnabled: boolean;
  isAIInPaintingEnabled: boolean;
  isStyleDocsEnabled: boolean;
  allowSupportToJoinTeams: boolean;
  isAIPatternEnabled: boolean;
  isEligibleSupplier: boolean;
}

export type UserModel = (IUserInfo & FirebaseUser) | null;

export type UserContext = {
  auth: any;
  user: UserModel;
  userToken: string | null;
  isLoading: boolean;
  getAuth: () => Auth;
  signOut: (s?: string) => Promise<void>;
  refetch: () => void;
  refetchSubscription: () => void;
  loginInProgress: boolean;
  setLoginInProgress: (loginInProgress: boolean) => void;
  userFetched: boolean;
  subscriptionFetched: boolean;
  isAdmin: boolean;
  subscription?: Stripe.Subscription;
  isFetched: boolean;
  hasAbilityToOwnPortal: boolean;
  plan?: Stripe.SubscriptionItem;
  fbUser?: FirebaseUser | null;
  isAnalyticsEnabled: boolean;
};

const UserContext = createContext<UserContext | undefined>(undefined);

interface UserProviderProps {
  children: React.ReactNode;
}

const clarityIdentify = (id: number | string) => {
  if (!isUndefined(window) && window?.clarity) {
    window?.clarity("set", "userId", id.toString());
  }
};

export const UserProvider = ({ children }: UserProviderProps) => {
  const auth = useAuth();
  const router = useRouter();
  const { t } = useTranslation("common");

  const [fbUser, isLoading, error] = useAuthState(auth);
  const [loginInProgress, setLoginInProgress] = useState(false);
  const [userToken, setUserToken] = useState<string | null>(
    fbUser?.accessToken
  );

  const queryClient = useQueryClient();

  const { portalName = DEFAULT_PORTAL_NAME } = router.query;

  const {
    data: userAdditionalData,
    isLoading: additionalDataIsLoading,
    isFetched,
    isSuccess: userFetched,
    refetch,
  } = useQuery(
    ["me", userToken],
    () =>
      axios
        .request({
          method: "GET",
          url: `${process.env.NEXT_PUBLIC_API_HOST}/users/me`,
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then((res) => {
          return res.data;
        }),
    {
      enabled: !!userToken && isAuthorizedRoutes(router),
      refetchOnMount: true,
      keepPreviousData: true,
      onError: async (err: any) => {
        if (err?.response?.status === 401) {
          if (err?.response?.data?.noRestore) {
            return router.replace(`/${portalName}/auth/login`);
          }

          await refreshToken({
            userToken,
            auth,
            queryClient,
            error: err,
            onSuccess: refetch,
          });
        }
      },
    }
  );

  const {
    data: subscription,
    refetch: refetchSubscription,
    isFetched: subscriptionFetched,
  } = useQuery(
    ["subscription", userAdditionalData],
    () =>
      axios
        .request({
          method: "GET",
          url: `${process.env.NEXT_PUBLIC_API_HOST}/billing/subscription`,
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then((res) => res.data),
    {
      enabled:
        !!userToken &&
        !router.route?.includes("/auth") &&
        !router.route?.includes("/invite") &&
        !router.route?.includes("/team-invite"),
      onError: async (err: any) => {
        if (err?.response?.status === 401) {
          if (err?.response?.data?.noRestore) {
            return router.replace(`/${portalName}/auth/login`);
          }

          await refreshToken({
            userToken,
            auth,
            queryClient,
            error: err,
            onSuccess: refetchSubscription,
          });
        }
      },
    }
  );

  const plan = useMemo(() => {
    if (subscription) {
      return subscription.items.data.find((el: any) => isPlan(el));
    }
  }, [subscription]);

  const hasAbilityToOwnPortal = useMemo(() => {
    return (
      !!plan && (plan?.price?.product as any)?.metadata?.ownPortal === "true"
    );
  }, [plan]);

  useEffect(() => {
    return onIdTokenChanged(auth, async (user) => {
      // console.log("user token changed", new Date(), user);
      setUserToken(user?.accessToken);
    });
  }, [auth, setUserToken]);

  useEffect(() => {
    const switchLanguage = async (user: IUserInfo) => {
      if (
        user.locale &&
        user.locale !== router.locale &&
        LANGUAGES.includes(user.locale as string)
      ) {
        await setLanguage(user.locale);
      }
    };

    if (userAdditionalData) {
      switchLanguage(userAdditionalData);
    }
  }, [userAdditionalData, router.locale]);

  useEffect(() => {
    if (userAdditionalData) {
      clarityIdentify(userAdditionalData?.id);
    } else {
      clarityIdentify("anonymous");
    }
  }, [userAdditionalData, router?.asPath]);

  const user = useMemo(() => {
    if (fbUser && userAdditionalData && fbUser.emailVerified) {
      return { ...fbUser, ...userAdditionalData };
    }

    return null;
  }, [fbUser, userAdditionalData]);

  const isAdmin = userAdditionalData?.appRole === AppRoleEnum.admin;

  const isAnalyticsEnabled = user?.isMarketAnalyticsEnabled;

  const userContext: UserContext = {
    auth,
    subscription,
    subscriptionFetched,
    user,
    fbUser,
    isFetched: isLoading ? false : fbUser ? isFetched : true,
    isLoading: additionalDataIsLoading || isLoading,
    isAnalyticsEnabled,
    userToken,
    isAdmin: userAdditionalData?.appRole === AppRoleEnum.admin,
    hasAbilityToOwnPortal,
    plan,
    getAuth,
    signOut: async (initialRoute?: string) => {
      router.replace(`/${initialRoute || portalName}/auth/login`);
      await signOut(auth);
      setUserToken(null);
      queryClient.invalidateQueries();
      window?.Tawk_API?.hideWidget && window?.Tawk_API?.hideWidget();
    },
    refetch,
    refetchSubscription,
    loginInProgress,
    setLoginInProgress,
    userFetched,
  };

  if (userContext.isLoading && router.pathname !== "/") {
    return <FullScreenSpinner text={t("loading_profile")} />;
  }

  return (
    <UserContext.Provider value={userContext}>{children}</UserContext.Provider>
  );
};

export function useUser(): UserContext {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserState must be used inside an appropriate Provider");
  }

  return context;
}
