import useTranslation from "next-translate/useTranslation";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { error } from "utils/toast";

export const useNetwork = () => {
  const { t } = useTranslation("common");

  const onOnline = () => toast.dismiss();

  const onOffline = () =>
    error({ message: t("check_connection") }, { autoClose: false });

  useEffect(() => {
    window.addEventListener("online", onOnline);
    window.addEventListener("offline", onOffline);

    return () => {
      window.removeEventListener("online", onOnline);
      window.removeEventListener("offline", onOffline);
    };
  });
};
