import axios, { AxiosInstance } from "axios";
import { QueryClient } from "react-query";
import { DEFAULT_PORTAL_NAME } from "utils/constants";

const getInitialRoute = () => {
  if (typeof window !== "undefined") {
    const splittedPath = window.location.pathname.split("/");
    const initialRoute = splittedPath?.[1];
    if (
      !initialRoute ||
      initialRoute === "subscription" ||
      initialRoute === "admin"
    ) {
      return DEFAULT_PORTAL_NAME;
    }

    return initialRoute;
  }

  return DEFAULT_PORTAL_NAME;
};

export default abstract class BaseApi {
  client: AxiosInstance;

  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    const headers: any = {
      Authorization: `Bearer ${userToken}`,
    };

    if (teamId) {
      headers.teamId = teamId;
    }

    if (portalId) {
      headers.portalId = portalId;
    }

    const client = axios.create({
      baseURL: process.env.NEXT_PUBLIC_API_HOST,
      headers,
    });
    this.client = client;

    this.client.interceptors.response.use(
      function (response) {
        getInitialRoute();
        return response?.data;
      },
      async function (error) {
        console.warn(
          "error request ===>>>",
          error?.response?.request?.responseURL
        );
        console.warn("error status ===>>>", error?.response?.status);
        console.warn("error message ===>>>", error?.response?.data?.message);
        if (error?.config["no-retry"] || error?.response?.data?.noRestore) {
          const route = getInitialRoute();
          queryClient.invalidateQueries("me");
          window.location = `/${route}/auth/login` as any;
          return;
        }

        if (error?.response?.status === 403) {
          if (error?.response?.data?.errorCode === "auth_wrong_team") {
            queryClient.invalidateQueries("me");
            queryClient.invalidateQueries("user-portal");
          }
          queryClient.invalidateQueries("currentTeam");
        }

        if (error?.response?.status === 401) {
          const res = await fetch(
            `https://securetoken.googleapis.com/v1/token?key=${process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY}`,
            {
              method: "POST",
              body: JSON.stringify({
                grant_type: "refresh_token",
                refresh_token: auth?.currentUser?.stsTokenManager?.refreshToken,
              }),
            }
          );

          if (res?.status === 200) {
            console.log("ABSTRACT API - TOKEN REFRESHED");
            return auth.currentUser
              .getIdToken()
              .then((token: string) => {
                return fetch(
                  `${process.env.NEXT_PUBLIC_API_HOST}/users/registerUser`,
                  {
                    headers: { Authorization: `Bearer ${token}` },
                    method: "POST",
                  }
                )
                  .then((e) => e.json())
                  .then(() => {
                    console.log("ABSTRACT API - USER REGISTERED");
                    return token;
                  });
              })
              .then((token: string) => {
                error.config.headers["Authorization"] = "Bearer " + token;
                error.config.baseURL = undefined;
                return client.request({
                  ...error.config,
                  "no-retry": true,
                });
              })
              .catch((err: any) => {
                console.log("ABSTRACT API - REFRESH TOKEN ERROR");
                const route = getInitialRoute();
                queryClient.invalidateQueries("me");
                window.location = `/${route}/auth/login` as any;
              });
          }
        }

        return Promise.reject(error);
      }
    );
  }
}
