import { useUser } from "contexts/UserContext";
import { useRouter } from "next/router";
import { useEffect } from "react";

export const useProtectAdminRoute = () => {
  const { user, isAdmin, isFetched } = useUser();
  const router = useRouter();

  useEffect(() => {
    if (!!user && isFetched && !isAdmin && router.pathname.includes("/admin")) {
      router.push("/");
    }
  }, [user, isAdmin]);
};
