const _ = require("lodash");

module.exports = {
  locales: ["en", "ko"],
  defaultLocale: "en",
  localeDetection: false,
  pages: {
    "*": [
      "common",
      "systemFeedback",
      "tooltip",
      "tutorial",
      "capsuleCollection",
    ],
  },
  loadLocaleFrom: async (lang, ns) => {
    const meta = require.context(
      "./businessComponents",
      true,
      /.*?\/locales\/.*.yml/
    );
    const bcNames = _.uniq(meta.keys().map((key) => key.split("/")[1]));

    if (bcNames.includes(ns)) {
      const locale = await import(
        `./businessComponents/${ns}/locales/${lang}.yml`
      );
      return locale.default;
    }

    return import(`./locales/${lang}/${ns}.yml`).then((y) => y.default);
  },
  logger: () => {},
};
