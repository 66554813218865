import { QueryClient } from "react-query";
import AbstractApi from "./AbstractApi";

export interface IUpdateDomainPayload {
  id: number;
  domain: string;
}

export default class DomainApi extends AbstractApi {
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
  }

  async createDomain(domain: string) {
    return await this.client.post("/domains", { domain });
  }

  async updateDomain({ id, domain }: IUpdateDomainPayload) {
    return await this.client.patch(`/domains/${id}`, { domain });
  }
}
