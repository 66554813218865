import { QueryClient } from "react-query";
import GlobalApi from "./GlobalApi";

export interface IProductDetailData {
  imgurl?: string;
  jbid: string;
  retailer: string;
  brand: string;
  name: string;
  gender: string;
  catgroup: string;
  basecat: string;
  subcat: string;
  ptype: string;
  colormain: string;
  product_url: string;
  price_og: number;
  price_og_usd: number;
  price: number;
  price_usd: number;
  currency: string;
  updated: string;
  restocks_2w: string;
  restocks_4w: string;
  restocks_6w: string;
  restocks_8w: string;
  restocks_mtd: string;
  historical: boolean;
  geosource: string;
  material: {
    mat: [
      {
        name: string;
        comp: {
          [key: string]: {
            v: number;
            t: any[];
          };
        };
        l: string[];
      }
    ];
  };
  first_seen: string;
}

export default class ProductApi extends GlobalApi {
  userToken: string;
  teamId: number;
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
    if (userToken) this.userToken = userToken;
    if (teamId) this.teamId = teamId;
  }

  async getProductDetails(params: any): Promise<IProductDetailData[]> {
    return await this.query(`/catalog/daily/latest/`, params);
  }
}
