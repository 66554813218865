import { convertStringToTimestamp } from "./convertDate";

export function normalizeProductsPriceData(
  productList: any
): { x: number; y: number }[] {
  if (!productList) return [];

  let minPrice = 1;
  let maxPrice = 0;

  for (const product of productList) {
    if (product.price_usd < minPrice) {
      minPrice = product.price_usd;
    }
    if (product.price_usd > maxPrice) {
      maxPrice = product.price_usd;
    }
  }

  const numIntervals = 36;
  const interval = Math.ceil((maxPrice - minPrice) / numIntervals);

  const counts: number[] = new Array(36).fill(0);

  for (const product of productList) {
    const index = Math.floor((product.price_usd - minPrice) / interval);
    counts[index]++;
  }

  const chartData: { x: number; y: number }[] = [];

  for (let i = 0; i < numIntervals; i++) {
    const x = minPrice + i * interval;
    const y = counts[i];
    chartData.push({ x, y });
  }

  return chartData;
}

export function normalizeProductsDateData(productList: any) {
  if (!productList) return [];

  const timestamp = convertStringToTimestamp(
    productList[0]?.first_seen.toString() || ""
  );

  let minDate = timestamp;
  let maxDate = 0;

  for (const product of productList) {
    const dateString = product.first_seen.toString();

    const timestamp = convertStringToTimestamp(dateString);
    if (timestamp < minDate) {
      minDate = timestamp;
    }
    if (timestamp > maxDate) {
      maxDate = timestamp;
    }
  }

  const numDateIntervals = 36;
  const dateInterval = Math.ceil((maxDate - minDate) / numDateIntervals);

  const counts: number[] = new Array(numDateIntervals).fill(0);

  for (const product of productList) {
    const dateString = product.first_seen.toString();
    const year = parseInt(dateString.substring(0, 4));
    const month = parseInt(dateString.substring(4, 6)) - 1;
    const day = parseInt(dateString.substring(6, 8));

    const dateIndex = Math.floor(
      (Date.UTC(year, month, day) - minDate) / dateInterval
    );
    counts[dateIndex]++;
  }

  const chartData: { x: number; y: number }[] = [];

  for (let i = 0; i < numDateIntervals; i++) {
    const x = minDate + i * dateInterval;
    const y = counts[i];
    chartData.push({ x, y });
  }

  return chartData;
}
