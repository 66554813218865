import React, { useEffect } from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { useUser } from "./UserContext";
import isEmpty from "lodash/isEmpty";
import { usePortal } from "./PortalContext";

export const TawkMessengerContext = React.createContext({});

const TawkMessengerProvider: React.FC = ({ children }) => {
  const { userToken } = useUser();
  const { userPortal, isPortal } = usePortal();

  useEffect(() => {
    if (!isEmpty(window?.Tawk_API)) {
      if (isPortal) {
        if (userPortal && userToken && userPortal.isLiveHelpEnabled) {
          window?.Tawk_API?.showWidget();
        } else {
          window?.Tawk_API?.shutdown();
        }
      } else {
        if (userToken) {
          window?.Tawk_API?.showWidget();
        }
      }
    }
  }, [userToken, userPortal, isPortal]);

  return (
    <TawkMessengerContext.Provider value={{}}>
      {children}
      <TawkMessengerReact
        propertyId="637d1c76b0d6371309d08635"
        widgetId="1gigbebnl"
      />
    </TawkMessengerContext.Provider>
  );
};

export default TawkMessengerProvider;
