import { useRouter } from "next/router";
import React, { useContext, useEffect } from "react";
import { useUser } from "./UserContext";
import { io, Socket } from "socket.io-client";
import { usePortal } from "./PortalContext";
import { isAuthorizedRoutes } from "utils/isAuthorizedRoutes";
import { DomainStatuses, WEB_SOCKETS_EVENTS } from "utils/constants";
import { Message } from "models/Message";
import {
  showNewComment,
  showNewMention,
  showNewMessage,
} from "utils/showCommentMessage";
import useTranslation from "next-translate/useTranslation";
import { useQueryClient } from "react-query";
import { isDev } from "utils/checkEnv";
import { useTeam } from "./TeamContext";
import { useSetCurrentTeam } from "hooks/teams";
import { Comment } from "models/Comment";
import { GLOBAL_COMMENT } from "hooks/comments";
import { TVGarment } from "models/TvGarment";
import { info } from "utils/toast";
import { openUrl } from "utils/openUrl";
import { json } from "stream/consumers";

const MESSENGER_SOCKETS_HOST = process.env.NEXT_PUBLIC_WEB_SOCKETS_HOST;

const initialValue: {
  socket?: Socket;
} = {};

const WebSocketContext = React.createContext(initialValue);

export const useWebSocketContext = () => useContext(WebSocketContext);

const WebSocketProvider: React.FC = ({ children }) => {
  const { t } = useTranslation("common");
  const { userToken, user } = useUser();
  const { userPortal, portalName } = usePortal();
  const router = useRouter();
  const { currentTeam, refetchTeam } = useTeam();

  const queryClient = useQueryClient();

  const isAuthorizedRoute = isAuthorizedRoutes(router);

  const socket = io(MESSENGER_SOCKETS_HOST as string, {
    reconnectionAttempts: Infinity,
    autoConnect: false,
    extraHeaders: {
      Authorization: `Bearer ${userToken}`,
      portalId: (userPortal?.id as number)?.toString(),
      teamId: (currentTeam?.id as number)?.toString(),
    },
  });

  // comments logic
  const { mutate: setCurrentTeam } = useSetCurrentTeam();

  const moveToPath = (path: string) => {
    if (path !== router.asPath) {
      router.push(path);
    }
  };

  const onCommentClick = (comment: Comment) => {
    const path = `/${portalName + comment.resourcePath}`;
    queryClient.setQueryData(GLOBAL_COMMENT, comment);
    if (comment.teamId !== currentTeam?.id) {
      setCurrentTeam(comment.teamId, {
        onSuccess() {
          refetchTeam();
          moveToPath(path);
        },
      });
    } else {
      moveToPath(path);
    }
  };

  const updateComments = () => {
    queryClient.invalidateQueries("comments");
    queryClient.invalidateQueries("comments-view-status");
  };

  // rooms logic
  const updateRooms = () => {
    queryClient.invalidateQueries("rooms");
    queryClient.invalidateQueries("messages-view-status");
    queryClient.invalidateQueries("room");
    queryClient.invalidateQueries("room-messages");
  };

  // garments logic
  const updateGarments = (tvGarment: TVGarment) => {
    queryClient.invalidateQueries("tvGarments");
    if (tvGarment.upscaledImage) {
      queryClient.invalidateQueries("tv-garment");
    }
  };

  // order-requests logic
  const updateRequests = () => {
    queryClient.invalidateQueries("order-request");
    queryClient.invalidateQueries("order-requirements");
    queryClient.invalidateQueries("order-requests");
  };

  // orders logic
  const updateOrders = () => {
    queryClient.invalidateQueries("order");
    queryClient.invalidateQueries("order-requirements");
    queryClient.invalidateQueries("orders");
  };

  const updateOrderProposals = () => {
    queryClient.invalidateQueries("order-proposals");
  };

  useEffect(() => {
    if (
      userToken &&
      user &&
      isAuthorizedRoute &&
      currentTeam &&
      typeof window !== "undefined"
    ) {
      socket.connect();

      socket.on(WEB_SOCKETS_EVENTS.CONNECT, () => {
        console.log("ws connected");
      });

      socket.on(WEB_SOCKETS_EVENTS.CONNECT_ERROR, (err) => {
        if (err.description === 403) {
          socket.disconnect();
        }
      });

      // orders events
      socket.on(WEB_SOCKETS_EVENTS.ORDER_CREATED, () => {
        if (isDev()) {
          console.log("ORDER CREATED");
        }
        updateOrders();
      });

      socket.on(WEB_SOCKETS_EVENTS.ORDER_UPDATED, () => {
        if (isDev()) {
          console.log("ORDER UPDATED");
        }
        updateOrders();
      });

      socket.on(WEB_SOCKETS_EVENTS.ORDER_REMOVED, () => {
        if (isDev()) {
          console.log("ORDER REMOVED");
        }
        updateOrders();
      });

      socket.on(WEB_SOCKETS_EVENTS.ORDER_PROPOSAL_CREATED, () => {
        if (isDev()) {
          console.log("ORDER PROPOSAL CREATED");
        }
        updateOrders();
        updateOrderProposals();
      });

      socket.on(WEB_SOCKETS_EVENTS.ORDER_PROPOSAL_UPDATED, () => {
        if (isDev()) {
          console.log("ORDER PROPOSAL UPDATED");
        }
        updateOrders();
        updateOrderProposals();
      });

      socket.on(WEB_SOCKETS_EVENTS.ORDER_PROPOSAL_REMOVED, () => {
        if (isDev()) {
          console.log("ORDER PROPOSAL REMOVED");
        }
        updateOrders();
        updateOrderProposals();
      });

      // order-requests events
      socket.on(WEB_SOCKETS_EVENTS.ORDER_REQUEST_CREATED, () => {
        if (isDev()) {
          console.log("ORDER REQUEST CREATED");
        }
        updateRequests();
      });

      socket.on(WEB_SOCKETS_EVENTS.ORDER_REQUEST_UPDATED, () => {
        if (isDev()) {
          console.log("ORDER REQUEST UPDATED");
        }
        updateRequests();
      });

      // garments events
      socket.on(WEB_SOCKETS_EVENTS.GARMENT_CREATED, (tvGarment: TVGarment) => {
        if (isDev()) {
          console.log("GARMENT CREATED", tvGarment);
        }
      });

      socket.on(WEB_SOCKETS_EVENTS.GARMENT_UPDATED, (tvGarment: TVGarment) => {
        if (isDev()) {
          console.log("GARMENT UPDATED", tvGarment);
        }
        updateGarments(tvGarment);
      });

      // comments events
      socket.on(WEB_SOCKETS_EVENTS.NEW_COMMENT, (comment: Comment) => {
        updateComments();
        showNewComment({
          comment,
          t,
          onClick: () => onCommentClick(comment),
        });
      });

      socket.on(WEB_SOCKETS_EVENTS.NEW_MENTION, (comment: Comment) => {
        updateComments();
        showNewMention({
          comment,
          t,
          onClick: () => onCommentClick(comment),
        });
      });

      // rooms events
      socket.on(WEB_SOCKETS_EVENTS.NEW_MESSAGE, (message: Message) => {
        if (isDev()) {
          console.log("NEW MESSAGE");
        }
        updateRooms();
        if (user?.allowMessengerNotifications) {
          showNewMessage({
            message,
            t,
            onClick: () =>
              router.push(`/${portalName}/messenger/${message.roomId}`),
          });
        }
      });

      socket.on(WEB_SOCKETS_EVENTS.REMOVED_MESSAGE, () => {
        if (isDev()) {
          console.log("MESSAGE REMOVED");
        }
        updateRooms();
      });

      socket.on(WEB_SOCKETS_EVENTS.UPDATED_MESSAGE, () => {
        if (isDev()) {
          console.log("MESSAGE UPDATED");
        }
        updateRooms();
      });

      socket.on(WEB_SOCKETS_EVENTS.ROOM_CREATED, () => {
        if (isDev()) {
          console.log("ROOM CREATED");
        }
        queryClient.invalidateQueries("rooms");
      });

      socket.on(WEB_SOCKETS_EVENTS.ROOM_UPDATED, () => {
        if (isDev()) {
          console.log("ROOM UPDATED");
        }
        queryClient.invalidateQueries("order");
        queryClient.invalidateQueries("rooms");
        queryClient.invalidateQueries("room");
      });

      socket.on(WEB_SOCKETS_EVENTS.ROOM_REMOVED, () => {
        if (isDev()) {
          console.log("ROOM REMOVED");
        }
        queryClient.invalidateQueries("rooms");
        queryClient.invalidateQueries("room");
      });

      socket.on(WEB_SOCKETS_EVENTS.ROOM_USERS_UPDATED, () => {
        if (isDev()) {
          console.log("ROOM USERS UPDATED");
        }
        queryClient.invalidateQueries("room-users");
      });

      socket.on(WEB_SOCKETS_EVENTS.DISCONNECT, (reason) => {
        console.log("ws disconneted", reason);
      });

      // ai providers events
      socket.on(WEB_SOCKETS_EVENTS.REPLICATE_STATUS_CHANGED, () => {
        if (isDev()) {
          console.log("AI PROVIDERS STATUS CHANGED");
        }
        queryClient.invalidateQueries("ai-providers-status");
      });

      // domain events
      socket.on(WEB_SOCKETS_EVENTS.DOMAIN_UPDATED, ({ oldData, newData }) => {
        const updatePortal = () => {
          setTimeout(() => {
            queryClient.invalidateQueries("user-portal");
            queryClient.invalidateQueries("owner-portal");
            queryClient.invalidateQueries("portal-details");
          }, 3000);
        };

        if (!oldData?.ipAddress && newData?.ipAddress) {
          updatePortal();
          info(
            {
              title: t("ip_is_ready", { ipAddress: newData?.ipAddress }),
              message: t("you_can_find_ip"),
            },
            {
              autoClose: false,
            }
          );
        }
        if (
          oldData?.status !== DomainStatuses.ACTIVE &&
          newData?.status === DomainStatuses.ACTIVE
        ) {
          if (isDev()) {
            console.log("DOMAIN_ACTIVE");
          }
          if (newData?.domain) {
            updatePortal();
            info({
              title: t("domain_is_active", { domain: newData?.domain }),
              message: "",
              onClick: () => openUrl(`https://${newData?.domain}`),
            });
            queryClient.setQueryData("domain-active", newData);
          }
        }
      });
    }

    return () => {
      socket.disconnect();
    };
  }, [user, userToken, isAuthorizedRoute, currentTeam]);

  return (
    <WebSocketContext.Provider value={{ socket }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketProvider;
